import { useState } from "react";

import firebase from "utils/Firebase";

export const usePhoneNumber = () => {
  const [code, setCode] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const onSubmit = () => {
    setLoading(true);
    if (!captchaVerified) {
      const appVerifier = window.recaptchaVerifier
        ? window.recaptchaVerifier
        : (window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
              size: "invisible",
              callback: function () {
                setCaptchaVerified(true);
              },
            },
          ));

      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmCode) => {
          setLoading(false);
          setConfirmCode(confirmCode);
        })
        .catch((error) => {
          setError(error.message);
        });
    } else {
      confirmCode
        .confirm(code)
        .then(async () => {
          setLoading(false);
        })
        .catch(() => {
          setError(error.message);
        });
    }
  };

  return {
    confirmCode,
    phoneNumber,
    code,
    setCode,
    onSubmit,
    loading,
    error,
    setPhoneNumber,
  };
};
