const Modal = ({ show, onClose, children }) => {
  return (
    <>
      {show ? (
        <div id="myModal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={onClose}>
              &times;
            </span>
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
