import { useState, useEffect } from "react";
import { useMapEvents, Polyline, Popup } from "react-leaflet";

import * as MapUtils from "utils/MapUtils";

export const ColorLine = ({
  focusTripData,
  secondData,
  onPolylineClick,
  playback,
  handleZoom,
  onSliceHover,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(15);
  const [index, setIndex] = useState(0);

  const handlePolylineClick = (tripId, path) =>
    onPolylineClick(tripId, path.id);

  const map = useMapEvents({
    zoomend: () => {
      setZoomLevel(map.getZoom());
    },
    zoomstart: handleZoom,
    movestart: handleZoom,
  });

  useEffect(() => {
    let interval = null;
    if (playback) {
      interval = setInterval(() => {
        setIndex((current) => current + 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setIndex(0);
    }

    return () => clearInterval(interval);
  }, [playback, index]);

  return (
    <>
      {Object.entries(focusTripData).map(([key, data]) => {
        data = data.filter((x) => x.avg_sog > 0);
        data = data.map((x) => {
          if (x.id in secondData && zoomLevel > 15) return secondData[x.id];
          else return x;
        });

        data = data.flat();

        if (playback === key) {
          if (index > data.length) setIndex(0);
          else {
            data = data.slice(0, index);
            if (
              data[data.length - 1]?.latitudes &&
              data[data.length - 1].longitudes
            ) {
              let position = {
                lat: data[data.length - 1]?.latitudes[5],
                lng: data[data.length - 1]?.longitudes[5],
              };
              let bounds = map.getBounds();
              if (!bounds.contains(position)) {
                map.setView(position, map.getZoom(), {
                  animate: true,
                });
              }
            }
          }
        }

        return MapUtils.getColoredMapLine(
          data,
          MapUtils.coloringValues.IMPACT,
        ).map((pathColors) => {
          if (pathColors.positions.length === 0) return;
          return (
            <>
              <Polyline
                positions={pathColors.positions}
                pathOptions={{ color: pathColors.color }}
              ></Polyline>
              <Polyline
                positions={pathColors.positions}
                pathOptions={{
                  color: pathColors.color,
                  weight: "15",
                  opacity: "0.0",
                }}
                eventHandlers={{
                  click: () => {
                    if (pathColors.type === "minute")
                      handlePolylineClick(key, pathColors);
                  },
                  mouseover: (e) => {
                    setPopupOpen(false);
                    e.target.openPopup(e.latlng);
                    if (pathColors.type === "minute")
                      onSliceHover(key, pathColors.id);

                    const layer = e.target;
                    layer.setStyle({ color: "white", opacity: "0.08" });
                  },
                  mouseout: (e) => {
                    if (!popupOpen) e.target.closePopup();

                    const layer = e.target;
                    layer.setStyle({ color: pathColors.color, opacity: "0.0" });
                  },
                }}
              >
                <Popup className="request-popup">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                      <p>Time: {pathColors.time}</p>
                      <p>
                        {pathColors.type === "minute" ? "Avg ROG:" : "ROG:"}{" "}
                        {pathColors.rog} kn
                      </p>
                      <p>
                        {pathColors.type === "minute" ? "Avg SOG:" : "SOG:"}{" "}
                        {pathColors.sog} kn
                      </p>
                      <p>
                        {pathColors.type === "minute"
                          ? "Peak Impact:"
                          : "Impact:"}{" "}
                        {pathColors.impact} g
                      </p>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <p>Sea State: {pathColors.sea_state}</p>
                      <p>Temperature: {pathColors.temperature} °C</p>
                      <p>Wind Direction: {pathColors.wind_direction}</p>
                      <p>Wind Speed: {pathColors.wind_speed} m/s</p>
                    </div>
                  </div>
                </Popup>
              </Polyline>
            </>
          );
        });
      })}
    </>
  );
};
