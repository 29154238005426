import { db } from "../../utils/Firebase";
import firebase from "firebase";

const VESSELS_COLLECTION = db.collection("vessels");

export const createVessel = async (values) => {
  const vesselRef = VESSELS_COLLECTION.doc();

  await vesselRef.set({
    created_at: firebase.firestore.Timestamp.now(),
    visible: true,
    id: vesselRef.id,
    ...values,
  });
};

export const editVessel = async (id, values) => {
  const vesselRef = VESSELS_COLLECTION.doc(id);
  await vesselRef.set(values, { merge: true });
};

export const getVessels = async (companyId) => {
  const vesselsSnap = await VESSELS_COLLECTION.where(
    "company_id",
    "==",
    companyId,
  )
    .where("visible", "==", true)
    .get();
  return vesselsSnap.docs.map((v) => {
    let data = v.data();
    data.id = v.id;
    return data;
  });
};

export const getAllVessels = async () => {
  const vesselsSnap = await VESSELS_COLLECTION.where(
    "visible",
    "==",
    true,
  ).get();
  return vesselsSnap.docs.map((v) => {
    let data = v.data();
    data.id = v.id;
    return data;
  });
};

export const getVessel = async (id) => {
  const vesselsSnap = await VESSELS_COLLECTION.doc(id).get();
  const vessel = vesselsSnap.data();
  if (!vessel) return {};
  vessel.id = vesselsSnap.id;
  return vessel;
};

export const getVesselByParam = async (id, param) => {
  const vesselSnap = await VESSELS_COLLECTION.where(param, "==", id).get();
  if (!vesselSnap) return undefined;
  return vesselSnap?.data();
};

export const getVesselsSubscription = async (companyId, callback) => {
  return VESSELS_COLLECTION.where("company_id", "==", companyId)
    .where("visible", "==", true)
    .onSnapshot((vesselsSnap) => {
      callback(
        vesselsSnap.docs.map((v) => {
          let vessel = v.data();
          vessel.id = v.id;
          return vessel;
        }),
      );
    });
};

export const getAllVesselsSubscription = async (callback) => {
  return VESSELS_COLLECTION.where("visible", "==", true).onSnapshot(
    (vesselsSnap) => {
      callback(
        vesselsSnap.docs.map((v) => {
          let vessel = v.data();
          vessel.id = v.id;
          return vessel;
        }),
      );
    },
  );
};

export const deleteVessel = (id) => {
  VESSELS_COLLECTION.doc(id).update({ visible: false });
};
