import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { DataWidget } from "components/Widgets/DataWidget";
import { MapWidget } from "components/Widgets/MapWidget";
import { WeatherWidget } from "components/Widgets/WeatherWidget";
import { AlertWidget } from "components/Widgets/AlertWidget";
import { RadarChart } from "components/RadarChart";
import { Chart } from "components/Chart";
import { Logs } from "components/Logs";

import * as DataAdapters from "utils/DataAdapters";
import * as ChartUtils from "utils/chartUtils/ConvertData";
import * as Color from "utils/Theme";
import { useCaptains } from "hooks/useCaptains";
import { useVessel, useVessels } from "hooks/useVessels";
import { useTripsWithConsole } from "hooks/useTripsWithConsole";
import { useTrips } from "hooks/useTrips";

const forecast = [
  {
    icon: "sun",
    color: Color.yellow,
    time: "Now",
    wind_speed: "4 m/s",
  },
  {
    icon: "snowflake",
    color: "white",
    time: "3 hours",
    wind_speed: "10 m/s",
  },
  {
    icon: "umbrella",
    color: Color.blue,
    time: "6 hours",
    wind_speed: "15 m/s",
  },
];

const alerts = [
  {
    icon: "warning",
    color: Color.red,
    message: "2 red impacts last week",
  },
  {
    icon: "sticky note",
    color: Color.yellow,
    message: "3 issues logged last week",
  },
];

export const VesselDescriptionPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const vessel = useVessel(id);
  const captains = useCaptains();
  const vessels = useVessels();
  const trips = useTrips();
  const { onPinAndFocusTrip } = useTripsWithConsole(vessel, () =>
    history.push("/"),
  );

  return (
    <div className="description">
      <Link to="/vessels">
        <Icon name="arrow left" style={{ color: "white", padding: "20px" }} />
      </Link>
      <div className="description__header">
        <DataWidget
          data={DataAdapters.mapVesselToDetails(vessel, captains, vessels)}
          label="Details"
          className="w-60"
        />

        <DataWidget
          data={DataAdapters.mapDataToStatistics(vessel?.statistics)}
          label="Statistics"
          className="w-60"
        />
        <Logs data={vessel} param="vessel_id" className="description-logs" />
      </div>
      <div className="description__charts">
        <div className="description__charts-map" onClick={onPinAndFocusTrip}>
          <MapWidget
            bounds={[
              [0, 0],
              [0, 0],
            ]}
          />
        </div>
        {vessel && (
          <div className="description__charts-spider">
            <RadarChart data={vessel} drawLabels />
          </div>
        )}
        <div className="description__charts-additional">
          <WeatherWidget forecast={forecast} />
          <AlertWidget alerts={alerts} />
        </div>
      </div>
      <div className="description__trip-chart">
        {trips?.length && vessel && (
          <Chart
            data={ChartUtils.convertTripsToChartData(
              trips.filter((trip) => trip?.vessel_id === vessel?.id),
            )}
            drawAxes
            drawLegend
            aspectRatio={8}
          />
        )}
      </div>
    </div>
  );
};
