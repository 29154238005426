import { MapContainer, TileLayer } from "react-leaflet";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { mapboxAccessToken } from "utils/Constants";

export const MapWidget = ({ link = "", bounds }) => {
  const interactions = {
    zoomControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    zoomSnap: false,
    zoomDelta: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false,
  };

  return (
    <div className="map-widget">
      <span>
        <h3>Active vessels</h3>
        {link && (
          <Link to={link}>
            <Icon name="arrow right" style={{ color: "white" }} />
          </Link>
        )}
      </span>

      <MapContainer
        {...interactions}
        bounds={window.L.latLngBounds(bounds)}
        style={{ height: "90%", width: "100wh", marginTop: "10px" }}
      >
        <TileLayer
          key="2"
          attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
          url={
            "https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=" +
            mapboxAccessToken
          }
        />
      </MapContainer>
    </div>
  );
};
