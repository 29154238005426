import moment from "moment";
import * as Color from "../Theme";
import * as Data from "./Data";

const mockEvents = false;

export const convertTripData = (trip, vessel, seconds) => {
  if (!trip || trip?.length === 0) return null;

  const colorOptions = [Color.chartPink, Color.chartPurple, Color.chartYellow];

  const seriesNotToShow = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "sea_state",
    "id",
    "seconds",
    "nanoseconds",
    "wind_direction",
    "button_1",
    "button_2",
    "button_3",
    "latitude",
    "longitude",
    "g",
    "r",
    "y",
  ];

  // factors for chart visualizaiton
  const show = {};
  const data = {};
  const range = {};
  const color = {};
  const units = {};
  const legend = {};
  const groups = {};

  const prev = {
    time:0,
    impact:0.0
  }

  trip.map((t, index) => {

    //Compare to last value, if it's lower, we skip
    if (t.time.seconds === prev.time){
      if (t.imu_1.impact < prev.impact){
        return;
      }
    }

    //compare to next value, if it's lower, we skip
    if (trip[index+1] && trip[index+1].time.seconds === t.time.seconds){
      if (t.imu_1.impact < trip[index+1].time.seconds){
        return;
      }
    }

    for (const [key, value] of Object.entries(t)) {
      // first level
      if (
        !(value instanceof Object) &&
        value &&
        !seriesNotToShow.includes(key)
      ) {
        if (!show[key]) show[key] = false;
        if (!color[key]) {
          const randomIndex = Math.floor(Math.random() * colorOptions.length);
          color[key] = colorOptions[randomIndex];
        }
        if (!units[key]) units[key] = "";
        if (!data[key]) data[key] = [];
        if (!legend[key]) legend[key] = Data.formatLegend(key);

        data[key].push(value);
      }
      // second level (object of first level key)
      if (value instanceof Object && value) {
        const secondLevelObj = value;
        const secondLevelKey = key;

        for (const [key, value] of Object.entries(secondLevelObj)) {
          if (
            !(value instanceof Object) &&
            value &&
            !seriesNotToShow.includes(key)
          ) {
            if (secondLevelKey === "imu_2") {
              if (!show[`${key}_2`]) show[`${key}_2`] = false;
              if (!color[`${key}_2`]) {
                const randomIndex = Math.floor(
                  Math.random() * colorOptions.length,
                );
                color[`${key}_2`] = colorOptions[randomIndex];
              }
              if (!units[`${key}_2`]) units[`${key}_2`] = "";
              if (!data[`${key}_2`]) data[`${key}_2`] = [];
              if (!legend[`${key}_2`])
                legend[`${key}_2`] = Data.formatLegend(`${key}_2`);

              data[`${key}_2`].push(value);
            } else {
              if (!show[key]) show[key] = false;
              if (!color[key]) {
                const randomIndex = Math.floor(
                  Math.random() * colorOptions.length,
                );
                color[key] = colorOptions[randomIndex];
              }
              if (!units[key]) units[key] = "";
              if (!data[key]) data[key] = [];
              if (!legend[key]) legend[key] = Data.formatLegend(key);
              data[key].push(value);
            }
          }

          // third level (object of second level key)
          if (value instanceof Object && value) {
            const thirdLevelObj = value;

            for (const [key, value] of Object.entries(thirdLevelObj)) {
              if (
                !(value instanceof Object) &&
                value &&
                !seriesNotToShow.includes(key)
              ) {
                if (!show[key]) show[key] = false;
                if (!color[key]) {
                  color[key] = colorOptions[randomIndex];
                  const randomIndex = Math.floor(
                    Math.random() * colorOptions.length,
                  );
                }
                if (!units[key]) units[key] = "";
                if (!data[key]) data[key] = [];
                if (!legend[key]) legend[key] = Data.formatLegend(key);

                data[key].push(value);
              }
            }
          }
        }
      }
    }
    prev.time = t.time.seconds;
    prev.impact = t.imu_1.impact;
  });

  // add range to series
  for (const [key, value] of Object.entries(data)) {
    let max = 0;
    if (key.includes("impact")) max = 3;
    if (key.includes("rog") || key.includes("sog")) max = 50;
    range[key] = [
      value.reduce((a, b) => Math.min(a, b), 0),
      value.reduce((a, b) => Math.max(a, b), max) + 1,
    ];
  }

  // add time (x-axis)
  const timeFormat = seconds ? "HH:mm:ss" : "HH:mm";
  const time = trip.map((d) => d.time.toDate().toString());
  const formattedTime = time.map((d) =>
    moment(d).format(timeFormat).toString(),
  );
  data["x"] = formattedTime;
  show["x"] = true;

  // add events
  const events = [];
  let showComfort = false;
  if (trip[trip.length-1].button_1 || mockEvents) {
    const comfort1 = !mockEvents
      ? convertComfortData(trip, 1, seconds)
      : Data.getMockComfort(time.length);
    events.push({
      type: "comfort",
      box:
        vessel.button_1_name && vessel.button_1_name !== ""
          ? vessel.button_1_name
          : "Box 1",
      data: comfort1,
    });
    showComfort = true;
  }
  if (trip[trip.length-1].button_2 || mockEvents) {
    const comfort2 = !mockEvents
      ? convertComfortData(trip, 2, seconds)
      : Data.getMockComfort(time.length);
    events.push({
      type: "comfort",
      box:
        vessel.button_2_name && vessel.button_2_name !== ""
          ? vessel.button_2_name
          : "Box 2",
      data: comfort2,
    });
    showComfort = true;
  }
  if (trip[trip.length-1].button_3 || mockEvents) {
    const comfort3 = !mockEvents
      ? convertComfortData(trip, 3, seconds)
      : Data.getMockComfort(time.length);
    events.push({
      type: "comfort",
      box:
        vessel.button_3_name && vessel.button_3_name !== ""
          ? vessel.button_3_name
          : "Box 3",
      data: comfort3,
    });
    showComfort = true;
  }

  show["comfort"] = showComfort;
  data["events"] = events;

  // create groups
  groups["Speed"] = [];
  for (const [key, value] of Object.entries(trip[0])) {
    if (!(value instanceof Object) && value && !seriesNotToShow.includes(key)) {
      groups["Speed"].push(key);
    }
    // second level (object of first level key)
    if (value instanceof Object && value) {
      const secondLevelObj = value;
      const secondLevelKey = Data.formatLegend(key);
      for (const [key, value] of Object.entries(secondLevelObj)) {
        if (
          !(value instanceof Object) &&
          value &&
          !seriesNotToShow.includes(key)
        ) {
          if (!groups[secondLevelKey]) groups[secondLevelKey] = [];
          if (secondLevelKey === "Imu 2") {
            groups[secondLevelKey].push(`${key}_2`);
          } else {
            groups[secondLevelKey].push(key);
          }
        }
        // third level (object of second level key)
        if (value instanceof Object && value) {
          const thirdLevelObj = value;
          const thirdLevelKey = Data.formatLegend(key);
          for (const [key, value] of Object.entries(thirdLevelObj)) {
            if (
              !(value instanceof Object) &&
              value &&
              !seriesNotToShow.includes(key)
            ) {
              if (!groups[thirdLevelKey]) groups[thirdLevelKey] = [];
              groups[thirdLevelKey].push(key);
            }
          }
        }
      }
    }
  }

  if (showComfort) groups["Events"] = ["comfort"];
  legend["comfort"] = "Comfort";

  // give main series a specific color
  if (color.max_impact) color.max_impact = Color.chartGreen;
  if (color.impact) color.impact = Color.chartGreen;
  if (color.avg_impact) color.avg_impact = Color.chartDarkBlue;
  if (color.avg_rog) color.avg_rog = Color.chartRed;
  if (color.rog) color.rog = Color.chartRed;
  if (color.avg_sog) color.avg_sog = Color.chartBlue;
  if (color.sog) color.sog = Color.chartBlue;

  // make main series visible by default
  show.max_impact = true;
  show.impact = true;
  show.avg_rog = true;
  show.rog = true;
  show.avg_sog = true;
  show.sog = true;

  // give main series a unit
  if (units.max_impact === "") units.max_impact = "g";
  if (units.impact === "") units.impact = "g";
  if (units.avg_impact === "") units.avg_impact = "g";
  if (units.avg_rog === "") units.avg_rog = "kn";
  if (units.rog === "") units.rog = "kn";
  if (units.avg_sog === "") units.avg_sog = "kn";
  if (units.sog === "") units.sog = "kn";

  return {
    show,
    data,
    range,
    color,
    units,
    legend,
    groups,
  };
};

export const convertTripDataMinutesToChartData = (data, vessel) => {
  if (!data || data?.length === 0) return null;
  const trip = convertTripData(data, vessel, false);
  return trip;
};

export const convertTripDataSecondsToChartData = (data, vessel) => {
  if (!data || data?.length === 0) return null;
  const trip = convertTripData(data, vessel, true);
  return trip;
};

export const convertTripsToChartData = (data) => {
  if (!data || data?.length === 0) return null;

  let time = data.map((d) => d.start.toDate().toString());
  time = time.map((d) => moment(d).format("DD/MM/YY HH:mm").toString());
  let endTime = data.map((d) =>
    isNaN(d.end) ? d.end : d.end.toDate().toString(),
  );
  let rog = data.map((d) => (isNaN(d.avg_rog) ? 0.0 : d.avg_rog));
  let sog = data.map((d) => (isNaN(d.avg_sog) ? 0.0 : d.avg_sog));
  let impact = data.map((d) => d.peak_impact ?? 0.0);
  let rogsog = data.map((d) =>
    isNaN(d.avg_rog_over_sog) ? 0.0 : d.avg_rog_over_sog * 100.0,
  );

  return {
    show: {
      x: false,
      rog: data.find((d) => d.avg_rog) ? true : false,
      sog: data.find((d) => d.avg_sog) ? true : false,
      impact: data.find((d) => d.peak_impact) ? true : false,
      rogsog: data.find((d) => d.avg_rog_over_sog) ? true : false,
    },
    data: {
      x: time.reverse(),
      endTime: endTime.reverse(),
      rog: rog.reverse(),
      sog: sog.reverse(),
      impact: impact.reverse(),
      rogsog: rogsog.reverse(),
    },
    range: {
      rog: [
        0,
        Math.max(
          rog.reduce((a, b) => Math.max(a, b), 50),
          sog.reduce((a, b) => Math.max(a, b), 50),
        ),
      ],
      sog: [
        0,
        Math.max(
          rog.reduce((a, b) => Math.max(a, b), 50),
          sog.reduce((a, b) => Math.max(a, b), 50),
        ),
      ],
      impact: [0, impact.reduce((a, b) => Math.max(a, b), 3.0) + 1],
      rogsog: [0, 100],
    },
    legend: {
      rog: "Avg. ROG",
      sog: "Avg. SOG",
      impact: "Peak Impact (1)",
      rogsog: "ROG/SOG",
    },
    units: {
      rog: "kn",
      sog: "kn",
      impact: "g",
      rogsog: "%",
    },
    color: {
      rog: Color.chartRed,
      sog: Color.chartGreen,
      impact: Color.chartBlue,
      rogsog: Color.chartPurple,
    },
    groups: {
      Speed: ["rog", "sog", "rogsog"],
      Sensors: ["impact"],
    },
  };
};

const convertComfortData = (data, buttonIndex, sec) => {
  const colors = [Color.chartRed, Color.chartYellow, Color.chartGreen];

  // determine button box
  const comfort = data.map((x) => {
    if (buttonIndex === 1) return x.button_1;
    if (buttonIndex === 2) return x.button_2;
    if (buttonIndex === 3) return x.button_3;
  });

  // convert data from cumulative to discrete
  const discreteComfort = comfort.map((currentValue, i) => {
    if (i === 0 && sec) return { g: 0, y: 0, r: 0 };
    const newValue = {};
    let prevValue = comfort[i - 1];

    if (!currentValue) return { g: 0, y: 0, r: 0 };
    if (!prevValue) prevValue = { g: 0, y: 0, r: 0 };

    // calculate difference to get discrete values
    newValue.g =
      currentValue.g > prevValue.g ? currentValue.g - prevValue.g : 0;
    newValue.y =
      currentValue.y > prevValue.y ? currentValue.y - prevValue.y : 0;
    newValue.r =
      currentValue.r > prevValue.r ? currentValue.r - prevValue.r : 0;

    // get max value to determine color
    const arr = [newValue.r, newValue.y, newValue.g];
    const max = Math.max(...arr);
    const index = arr.indexOf(max);
    newValue.color = colors[index];

    return newValue;
  });

  return discreteComfort;
};

export const convertToCalendarData = (trips) => {
  let dates = trips.map((trip) => {
    return moment(trip.start.toDate().toString()).format("DD/MM/YY");
  });

  dates = dates.reduce((a, b) => {
    return a[b] ? ++a[b] : (a[b] = 1), a;
  }, {});

  const calendarData = [];
  for (const [key, value] of Object.entries(dates)) {
    calendarData.push({ date: key, trips: value });
  }

  return calendarData;
};
