import React from "react";
import { Route } from "react-router-dom";

import { TripsScreen } from "./Trips";
import { TripsEditDialog } from "./modals/TripsEditDialog";
import { TripDeleteDialog } from "./modals/TripDeleteDialog";

export default function TripsPage() {
  return (
    <div>
      <Route path="/trips/:id/edit">
        {({ history, match }) => {
          return (
            <TripsEditDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/trips");
              }}
            />
          );
        }}
      </Route>
      <Route path="/trips/:id/delete">
        {({ history, match }) => (
          <TripDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/trips");
            }}
          />
        )}
      </Route>
      <TripsScreen />
    </div>
  );
}
