import { useState, useEffect, useContext } from "react";

import {
  getTripDataSubscription,
  getTripData,
  getTripDataSecond,
} from "data/trips";
import { useVessels } from "hooks/useVessels";
import { useTrips } from "hooks/useTrips";
import { AuthContext } from "utils/AuthContext";
import { DataContext } from "utils/DataContext";
import * as MapUtils from "utils/MapUtils";
import * as DateUtils from "utils/DateUtils";

export const useLiveConsole = () => {
  const { company, user } = useContext(AuthContext);
  const {
    todaysTrips,
    setTodaysTrips,
    pinnedTrips,
    setPinnedTrips,
    ongoingTrips,
    setOngoingTrips,
    focusTrips,
    setFocusTrips,
    focusTripData,
    setFocusTripData,
    secondData,
    setSecondData,
    minute,
    setMinute,
    playback,
    setPlayback,
  } = useContext(DataContext);
  const vessels = useVessels();
  const trips = useTrips();

  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(false);
  const [showFullTrip, setShowFullTrip] = useState(true);
  const [chartData, setChartData] = useState({});
  const [mapRef, setMapRef] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [isTrackingOngoingTrips, setIsTrackingOngoingTrips] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [vessel, setVessel] = useState({});

  let unsubscribeToData = [];

  useEffect(() => {
    (async () => {
      if (company && user) {
        await getData();
        if (loading) setLoading(false);
      }
    })();
    return () => {
      unsubscribeToData.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [company, user, trips]);

  useEffect(() => {
    fitMapToFocusTripBounds(focusTripData);
  }, [focusTripData]);

  useEffect(() => {
    (async () => {
      if (company && user) {
        let count = 0;
        focusTrips.forEach(async (element) => {
          if (!(element.id in focusTripData)) {
            if (ongoingTrips.find((x) => x === element)) {
              let unsubscribe = await getTripDataSubscription(
                element.id,
                (d) => {
                  setFocusTripData({ ...focusTripData, [element.id]: d });
                },
              );

              unsubscribeToData.push(unsubscribe);
            } else {
              let data = await getTripData(element.id);
              setFocusTripData({ ...focusTripData, [element.id]: data });
            }
            count += 1;
          }
        });
        if (count === 0) fitMapToFocusTripBounds(focusTripData);
      }
    })();
  }, [focusTrips, focusTripData]);

  const getData = async () => {
    const tT = trips.filter((t) => DateUtils.isDateTodayUTC(t.end?.toDate()));
    setTodaysTrips(tT);
    const oT = trips.filter((t) =>
      DateUtils.isDateLastTenMinutesUTC(t.end?.toDate().toString()),
    );
    setOngoingTrips(oT);

    if (!userPosition) getUserPosition(setUserPosition);
  };

  const setBoundsForOngoingTrips = () => {
    if (ongoingTrips.length === 0) {
      const bounds = MapUtils.getBoundsFromCoordinatePoints([userPosition]);
      mapRef.fitBounds(bounds);
    } else {
      const points = ongoingTrips.map((x) => x.end_location);
      const bounds = MapUtils.getBoundsFromCoordinatePoints(points);
      mapRef.fitBounds(bounds);
    }
  };

  const toggleTrackingOngoingTrips = () =>
    setIsTrackingOngoingTrips(!isTrackingOngoingTrips);

  useEffect(() => {
    if (isTrackingOngoingTrips) {
      setFocusTrips([]);
      setBoundsForOngoingTrips();
      setTimeout(() => setIsTrackingOngoingTrips(true), 250);
    }
  }, [isTrackingOngoingTrips]);

  const handleZoom = () => {
    setIsTrackingOngoingTrips(false);
  };

  useEffect(() => {
    if (focusTrips.length) {
      setIsTrackingOngoingTrips(false);
    }
  }, [focusTrips]);

  const handlePolylineClick = async (tripId, minuteId) => {
    setChartData({ tripId: tripId, minuteId: minuteId });

    let secs = await getTripDataSecond(tripId, minuteId);

    let points = secs.map((x) => ({ lat: x.latitude, lon: x.longitude }));
    let bounds = MapUtils.getBoundsFromCoordinatePoints(points, 0.001);
    mapRef.fitBounds(bounds);

    setSecondData({ ...secondData, [minuteId]: secs });
    setMinute(secs);
    const trip = trips.find((t) => t.id === tripId);
    setVessel(vessels.find((v) => v.id === trip.vessel_id));
    setShowChart(true);
    setShowFullTrip(false);
  };

  const filterFocusTripData = (data) => {
    const filtered = Object.keys(data)
      .filter((key) => focusTrips.map((x) => x.id).includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
    return filtered;
  };

  const fitMapToFocusTripBounds = (focusData) => {
    let data = filterFocusTripData(focusData);

    let points = Object.values(data).map((x) => {
      let path = [];

      x.forEach((minute) => {
        if (minute.avg_rog) {
          for (let i = 0; i < minute.latitudes.length; i++) {
            if (
              MapUtils.locationIsValid(
                minute.latitudes[i],
                minute.longitudes[i],
              )
            ) {
              path.push([minute.latitudes[i], minute.longitudes[i]]);
            }
          }
        }
      });

      return path.map((a) => ({ lat: a[0], lon: a[1] }));
    });

    if (points.length > 0) {
      let bounds = MapUtils.getBoundsFromCoordinatePoints(points.flat(), 0.001);
      mapRef?.fitBounds(bounds);
    }
  };

  const getUserPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserPosition({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        () => {
          setUserPosition({ lat: 0, lon: 0 });
        },
      );
    } else {
      setUserPosition({ lat: 0, lon: 0 });
    }
  };

  const handleChartClick = (direction) => () => {
    if (!showFullTrip) {
      let tripId = chartData.tripId;
      let minuteId = chartData.minuteId;
      let tripMinutes = focusTripData[tripId];
      let index = tripMinutes.findIndex((m) => m.id === minuteId);
      if (index !== 0 && direction === "left") {
        let previousMinuteId = tripMinutes[index - 1].id;
        handlePolylineClick(tripId, previousMinuteId);
      }
      if (index !== tripMinutes.length - 1 && direction === "right") {
        let nextMinuteId = tripMinutes[index + 1].id;
        handlePolylineClick(tripId, nextMinuteId);
      }
    }
  };

  const toggleShowChart = () => setShowChart(!showChart);

  const resetFocusTrips = (trip) => () => {
    if (focusTrips.find((t) => t.id === trip.id)) {
      setFocusTrips((current) => current.filter((t) => t.id !== trip.id));
      setFocusTripData((current) => {
        if (trip.id in current) {
          delete current[trip.id];
          return current;
        }
        return current;
      });
    } else setFocusTrips((current) => [...current, trip]);
  };

  const handlePlayback = (trip) => () => {
    if (!focusTrips.find((t) => t.id === trip.id)) {
      setFocusTrips((current) => [...current, trip]);
      setPlayback(trip.id);
    } else {
      if (playback === trip.id) setPlayback(null);
      else setPlayback(trip.id);
    }
  };

  const handleDelete = (trip) => () => {
    setPinnedTrips((current) => current.filter((t) => t.id !== trip.id));
    setShowChart(false);
    if (focusTrips.find((t) => t?.id === trip?.id)) {
      setFocusTrips((current) => current.filter((t) => t?.id !== trip?.id));
      setFocusTripData((current) => {
        if (trip.id in current) {
          delete current[trip.id];
          return current;
        }
        return current;
      });
    }
  };

  const displayChart = (trip) => () => {
    if (focusTrips.find((t) => t.id === trip.id)) {
      setMinute(focusTripData[trip.id]);
      setVessel(vessels.find((v) => v.id === trip.vessel_id));
      setShowFullTrip(true);
      toggleShowChart();
    }
  };

  const toggleShowDashboard = (trip) => () => {
    setShowDashboard(!showDashboard);
    setDashboardData(trip);
  };

  const resetHoveredTripSlice = async () => {
    // TODO rework this logic again to satisfy needs.
  };

  return {
    todaysTrips,
    pinnedTrips,
    minute,
    playback,
    showChart,
    showFullTrip,
    vessels,
    setMapRef,
    handlePolylineClick,
    company,
    user,
    ongoingTrips,
    focusTrips,
    focusTripData,
    setFocusTripData,
    loading,
    secondData,
    filterFocusTripData,
    handleChartClick,
    toggleShowChart,
    resetFocusTrips,
    handlePlayback,
    handleDelete,
    displayChart,
    handleZoom,
    isTrackingOngoingTrips,
    toggleTrackingOngoingTrips,
    showDashboard,
    toggleShowDashboard,
    dashboardData,
    vessel,
    resetHoveredTripSlice,
  };
};
