import { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { createUser, editUser } from "data/users";
import { useUser } from "hooks/useUsers";
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
  companyId: yup.string().nullable(),
  phoneNumber: yup.string().required("Phone number is required"),
  group: yup.string().nullable(),
  organizationRoles: yup.array().required("Organization roles are required"),
  admin: yup.boolean(),
});

export const useUserEditFormHandler = (id, onDone) => {
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const currentUser = useUser(id);

  useEffect(() => {
    reset({
      ...currentUser,
      organizationRoles: currentUser?.org_roles ?? [],
      phoneNumber: currentUser?.phone_number ?? "",
      admin:
        currentUser?.auth_role === "admin" ||
        currentUser?.auth_role === "super" ||
        false,
    });
  }, [id, currentUser]);

  const onSubmit = async (values) => {
    if (id) {
      await editUser(id, values);
    } else {
      await createUser(values);
    }
    reset({
      name: null,
      company_id: null,
      phoneNumber: null,
      group: null,
      organizationRoles: [],
      admin: false,
    });
    onDone && onDone();
  };

  return { currentUser, reset, handleSubmit, register, errors, onSubmit };
};
