import { Button, Form, Message } from "semantic-ui-react";
import { usePhoneNumber } from "./usePhoneNumber";

export const SignInPhone = () => {
  const {
    confirmCode,
    phoneNumber,
    setPhoneNumber,
    setCode,
    onSubmit,
    loading,
    code,
    error,
  } = usePhoneNumber();

  return (
    <>
      <Form>
        <Form.Field>
          <label>Phone number</label>
          <input
            placeholder="Phone number"
            type="tel"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </Form.Field>
        {confirmCode && (
          <Form.Field>
            <label>Verification Code</label>
            <input
              placeholder="Code"
              type="number"
              maxLength="6"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Form.Field>
        )}
        <Button
          primary
          fluid
          onClick={onSubmit}
          loading={loading}
          disabled={loading}
        >
          Sign in
        </Button>
      </Form>
      {!!error && (
        <Message
          negative
          header="An error occurred"
          content="Check email and password"
        />
      )}
      <div id="recaptcha-container" style={{}} />
    </>
  );
};
