import React, { useState } from "react";

export const ChartContext = React.createContext();

export const ChartProvider = (props) => {
  const [scroll, setScroll] = useState(0);
  const [brushPosition, setBrushPosition] = useState([
    window.innerWidth / 9,
    (3 * window.innerWidth) / 4,
  ]);
  const [showState, setShowState] = useState({});

  return (
    <ChartContext.Provider
      value={{
        scroll,
        brushPosition,
        showState,
        setScroll,
        setBrushPosition,
        setShowState,
      }}
      {...props}
    />
  );
};
