import { Table } from "components/Table";
import { Heatmap } from "components/Heatmap";
import { useTrips } from "hooks/useTrips";
import { useTripsData } from "./hooks/useTripsData";
import { TripsExpandedRowData } from "./TripsExpandedRowData";
import { Loader } from "components/Loader";
import { convertToCalendarData } from "utils/chartUtils/ConvertData";
import logo from "../../assets/axopar.png";

export const TripsScreen = () => {
  const trips = useTrips();
  const { headings, tripsData, expandedRowData, loading } = useTripsData();

  if (loading) return <Loader />;

  return (
    <div className="container">
      <img src={logo} width={200} style={{float:"right"}}/>
      <h1 className="text white">Trips</h1>
      <div className="calendar-container">
        {trips.length > 0 && (
          <Heatmap data={convertToCalendarData(trips)} aspectRatio={8} />
        )}
      </div>
      <Table
        headings={headings}
        data={tripsData}
        expandableData={expandedRowData}
        ExpandedRowContent={TripsExpandedRowData}
        expandable
      />
    </div>
  );
};
