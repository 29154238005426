import { forwardRef } from "react";

const InputRef = (
  { label = "", id, error, disabled = false, className = "", ...props },
  ref,
) => {
  return (
    <div className={`input ${className}`}>
      {label && (
        <label htmlFor={id} className="input__label">
          {label}
        </label>
      )}
      <input
        ref={ref}
        id={id}
        className={`input__field input__field-${disabled && "disabled"}`}
        disabled={disabled}
        {...props}
      />
      {error && <span className="input__error">{error?.message}</span>}
    </div>
  );
};

export const Input = forwardRef(InputRef);
