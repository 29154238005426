import { useEffect, createContext, useState, useMemo } from "react";

import { getProps, tabsContent } from "./";

export const TabsContext = createContext({
  displayedTab: null,
  setDisplayedTab: () => {},
});

export const Tabs = ({ children, contentClassName = "" }) => {
  const props = useMemo(() => getProps(children), [children]);
  const [displayedTab, setDisplayedTab] = useState(props[0].label);
  const [content, setContent] = useState(null);

  useEffect(() => {
    props.forEach(({ label, children }) =>
      tabsContent.setContent(label, children),
    );
  }, [props]);

  useEffect(() => {
    setContent(tabsContent[displayedTab]);
  }, [tabsContent, displayedTab]);

  return (
    <TabsContext.Provider
      value={{
        displayedTab,
        setDisplayedTab,
      }}
    >
      <div className="tabs">{children}</div>
      <div className={`tabs__content ${contentClassName}`}>{content}</div>
    </TabsContext.Provider>
  );
};
