import * as d3 from "d3";
import * as Color from "../Theme";
import * as Data from "./Data";
import moment from "moment";

export const chart = (ref, width, height, margin) => {
  const chart = d3
    .select(ref.current)
    .append("svg")
    .attr("preserveAspectRatio", "xMinYMin meet")
    .attr(
      "viewBox",
      `0 0
      ${width + margin.left + margin.right} 
      ${height + margin.top + margin.bottom}`,
    );

  return chart;
};

export const xAxis = (chart, xScale, height, margin, showTicks) => {
  const xAxis = d3.axisBottom(xScale).tickSize(0);

  const tickValues =
    xScale.domain().length <= 12
      ? xScale.domain()
      : xScale
          .domain()
          .filter((_, i) => i % Math.floor(xScale.domain().length / 10) == 0);

  xAxis.tickValues(tickValues);

  chart.selectAll(".x-axis").remove();

  if (showTicks) {
    chart
      .append("g")
      .attr(
        "transform",
        `translate(${margin.left}, ${height + margin.top + 10})`,
      )
      .attr("class", "x-axis")
      .attr("color", "white")
      .call(xAxis)
      .call((g) => g.select(".domain").remove());
  }
};

export const gridLines = (chart, width, height) => {
  const lines = [];

  lines.push(
    d3.line()([
      [0, 0],
      [width, 0],
    ]),
  );
  lines.push(
    d3.line()([
      [0, height / 2],
      [width, height / 2],
    ]),
  );
  lines.push(
    d3.line()([
      [0, height / 4],
      [width, height / 4],
    ]),
  );
  lines.push(
    d3.line()([
      [0, (3 * height) / 4],
      [width, (3 * height) / 4],
    ]),
  );

  lines.map((line) => {
    chart
      .append("path")
      .attr("d", line)
      .attr("stroke", "white")
      .attr("stroke-width", 1)
      .attr("opacity", 0.05);
  });
};

export const clip = (ref, chart, width, height, margin) => {
  d3.select(ref.current)
    .append("defs")
    .append("svg:clipPath")
    .attr("id", "clip")
    .append("svg:rect")
    .attr("width", width)
    .attr("height", height)
    .attr("x", 0)
    .attr("y", 0);

  const clip = chart
    .append("g")
    .attr("class", "focus")
    .attr("transform", `translate(${margin.left}, ${margin.top})`)
    .attr("clip-path", "url(#clip)");

  return clip;
};

export const overlay = (chart, width, height, margin) => {
  return chart
    .append("rect")
    .attr("fill", "none")
    .attr("pointer-events", "all")
    .attr("class", "zoom")
    .attr("width", width)
    .attr("height", height)
    .attr("transform", `translate(${margin.left}, ${margin.top + 4})`);
};

export const line = (chart, y, width, height, color, range) => {
  const xScale = d3
    .scaleLinear()
    .domain([0, y.length - 1])
    .range([0, width]);

  const yScale = d3.scaleLinear().domain(range).range([height, 0]);

  const line = d3
    .line()
    .x((_, i) => xScale(i))
    .y((d) => yScale(d))
    .curve(d3.curveLinear);

  chart
    .append("path")
    .datum(y)
    .attr("d", line)
    .attr("fill", "none")
    .attr("class", "line")
    .attr("stroke", color)
    .attr("stroke-width", 2);
};

export const bars = (
  chart,
  x,
  y,
  xScale,
  width,
  height,
  color,
  range,
  numberOfBars,
  barOffset,
) => {
  const yScale = d3.scaleLinear().domain(range).range([height, 0]);
  let barWidth = (width / y.length - 5) / numberOfBars;
  if (barWidth <= 1) barWidth = 1;
  const barPosition =
    numberOfBars > 1 && barOffset === 0
      ? -barWidth / 2
      : (barOffset * barWidth) / 2;

  chart
    .selectAll(".bar")
    .data(y)
    .enter()
    .append("rect")
    .attr("class", "verticalBar")
    .attr("x", (_, i) => xScale(x[i]) - barWidth / 2 + barPosition)
    .attr("y", (d) => yScale(d))
    .attr("width", barWidth)
    .attr("height", (d) => height - yScale(d))
    .attr("rx", 2)
    .attr("fill", (d) => {
      return d <= 1.0
        ? Color.chartGreen
        : d > 1.0 && d <= 3.0
        ? Color.chartYellow
        : Color.red;
    });
};

export const drawLine = (svg, data, color, xScale, yScale, margin) => {
  const line = d3
    .line()
    .x((_, i) => xScale(i))
    .y((d) => yScale(d))
    .curve(d3.curveLinear);

  svg
    .append("path")
    .datum(data)
    .attr("d", line)
    .attr("fill", "none")
    .attr("stroke", color)
    .attr("stroke-width", 2.0)
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
};

export const drawLineChartGradient = (
  svg,
  data,
  color,
  height,
  xScale,
  yScale,
) => {
  let gradientId = color === Color.chartRed ? "gradientRed" : "gradientGreen";

  let lg = svg
    .append("defs")
    .attr("class", "gradient")
    .append("linearGradient")
    .attr("id", gradientId)
    .attr("x1", "0%")
    .attr("x2", "0%")
    .attr("y1", "0%")
    .attr("y2", "100%");

  lg.append("stop")
    .attr("offset", "0%")
    .style("stop-opacity", 0.3)
    .style("stop-color", color);

  lg.append("stop")
    .attr("offset", "100%")
    .style("stop-opacity", 0.0)
    .style("stop-color", color);

  svg
    .append("path")
    .attr("class", "gradient")
    .datum(data)
    .attr(
      "d",
      d3
        .area()
        .x((_, i) => xScale(i))
        .y0(height)
        .y1((d) => yScale(d)),
    )
    .attr("fill", "url(#" + gradientId + ")")
    .attr("stroke", "none");
};

export const legend = (svg, text, color, x, y) => {
  if (text === "Comfort") return;

  svg
    .append("rect")
    .style("width", "14px")
    .style("height", "4px")
    .style("fill", color)
    .attr("transform", `translate(${x}, ${y})`);

  svg
    .append("text")
    .style("font-size", "10px")
    .style("fill", "white")
    .attr("transform", `translate(${x + 20}, ${y + 5})`)
    .text(text);
};

export const tooltip = (ref, className) => {
  return d3
    .select(ref.current)
    .append("div")
    .attr("class", className)
    .style("opacity", 0);
};

export const tooltipContent = (
  tooltip,
  data,
  nearestDateIndex,
  closestDate,
  showState,
) => {
  // reset tooltip
  tooltip.selectAll(".tooltip-header").remove();
  tooltip.selectAll(".tooltip-row").remove();
  tooltip.selectAll(".tooltip-text").remove();

  // append header to tooltip
  const header = tooltip.append("div").attr("class", "tooltip-header");

  if (data.data?.endTime) {
    let endDate = data.data?.endTime[nearestDateIndex];
    endDate = moment(endDate).format("DD/MM/YY HH:mm").toString();

    const firstRow = header.append("div").attr("class", "tooltip-row");

    const secondRow = header.append("div").attr("class", "tooltip-row");

    firstRow
      .append("p")
      .attr("class", "tooltip-text")
      .style("margin-right", "12px")
      .text("Start");

    firstRow
      .append("p")
      .attr("class", "tooltip-text")
      .style("font-weight", "bold")
      .text(`${closestDate}`);

    secondRow
      .append("p")
      .attr("class", "tooltip-text")
      .style("margin-right", "12px")
      .text("End");

    secondRow
      .append("p")
      .attr("class", "tooltip-text")
      .style("font-weight", "bold")
      .text(`${endDate}`);
  } else {
    header
      .append("p")
      .attr("class", "tooltip-text")
      .style("font-weight", "bold")
      .text(`${closestDate}`);
  }

  // append labels and values to tooltip
  const series = Object.assign({}, data?.data);
  delete series.x;
  delete series.events;

  for (const [key, value] of Object.entries(series)) {
    if (showState[key]) {
      const label = data.legend[key];
      const yValue = value[nearestDateIndex];
      const unit = data.units[key];

      if (yValue && label) {
        const row = tooltip.append("div").attr("class", "tooltip-row");

        const labelContainer = row
          .append("div")
          .style("display", "flex")
          .style("align-items", "center");

        // Colored square
        labelContainer
          .append("div")
          .attr("class", "tooltip-square")
          .style("background-color", data.color[key]);

        // label text
        labelContainer
          .append("p")
          .attr("class", "tooltip-text-line")
          .text(`${label}`);

        // value + unit
        row
          .append("p")
          .attr("class", "tooltip-text-line")
          .text(`${yValue.toFixed(1)} ${unit}`);
      }
    }
  }
};

export const hoverLine = (chart) => {
  return chart
    .append("path")
    .attr("stroke", "white")
    .attr("stroke-width", 0.5)
    .attr("opacity", "0");
};

export const brush = (chart, width, height, margin) => {
  const brush = d3.brushX().extent([
    [0, -1],
    [width, height + margin.bottom + margin.top],
  ]);

  const contextBrush = chart
    .append("g")
    .attr("class", "brush")
    .attr("transform", `translate(${margin.left}, ${0})`)
    .call(brush);

  return [brush, contextBrush];
};

export const event = (chart, x, xScale, height, shapeWidth, color) => {
  chart
    .append("path")
    .attr("class", "event")
    .attr("d", `M ${xScale(x)} 0 V ${height}`)
    .attr("stroke", "white")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "3, 5")
    .style("opacity", 0.5);

  return chart
    .append("rect")
    .attr("transform", `rotate(45, ${xScale(x)}, ${0})`)
    .attr("class", "event")
    .attr("x", xScale(x) - shapeWidth / 2)
    .attr("y", -shapeWidth / 2)
    .style("width", `${shapeWidth}px`)
    .style("height", `${shapeWidth}px`)
    .style("fill", color)
    .style("cursor", "pointer");
};

export const yLabelsHeatmap = (chart, cellSize, margin, color) => {
  chart
    .append("g")
    .attr("fill", color)
    .selectAll("text")
    .data(d3.range(0, Data.weekdays.length))
    .join("text")
    .attr(
      "transform",
      (_, i) => `translate(${0}, ${i * cellSize + margin.top + cellSize / 2})`,
    )
    .text((_, i) => Data.weekdays[i].substring(0, 3));
};

export const xLabelsHeatmap = (chart, cellSize, margin, color, year) => {
  let offset = 0;

  chart
    .append("g")
    .attr("fill", color)
    .selectAll("text")
    .data(d3.range(0, Data.months.length))
    .join("text")
    .attr("transform", (_, i) => {
      if (i !== 0)
        offset +=
          d3.timeWeeks(new Date(1, i - 1, year), new Date(1, i, year)).length *
          cellSize;
      return `translate(${offset + margin.left - 8}, ${12})`;
    })
    .text((_, i) => Data.months[i].substring(0, 3));
};

export const cells = (chart, data, color, cellSize, margin, tooltip) => {
  const countDay = (i) => (i + 6) % 7;

  chart
    .append("g")
    .attr("fill", "none")
    .selectAll("rect")
    .data((d) => d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1)))
    .enter()
    .append("rect")
    .attr("margin", 2)
    .attr("rx", cellSize / 8)
    .attr("ry", cellSize / 8)
    .attr("width", cellSize - cellSize / 4)
    .attr("height", cellSize - cellSize / 4)
    .attr(
      "x",
      (d) =>
        d3.timeMonday.count(d3.timeYear(d), d) * cellSize +
        margin.left -
        margin.right,
    )
    .attr("y", (d) => countDay(d.getDay()) * cellSize + margin.top)
    .datum(d3.timeFormat("%d/%m/%y"))
    .attr("fill", (d) => {
      const dataPoint = data.find((dd) => dd.date === d);
      if (dataPoint) return color(dataPoint.trips);
      return Color.heatmapColor;
    })
    .on("mouseover", function (e, d) {
      const dataPoint = data.find((dd) => dd.date === d);
      const numberOfTrips = dataPoint?.trips ?? 0;

      d3.select(this)
        .transition()
        .duration(50)
        .style("filter", "brightness(200%)");

      tooltip.selectAll(".heatmap-tooltip-text").remove();

      tooltip
        .style("left", `${this.getBoundingClientRect().left - 70}px`)
        .style(
          "top",
          `${this.getBoundingClientRect().top - 40 + window.scrollY}px`,
        )
        .transition()
        .duration(50)
        .style("opacity", "1");

      tooltip
        .append("p")
        .attr("class", "heatmap-tooltip-text")
        .text(
          `${numberOfTrips} ${numberOfTrips === 1 ? "trip" : "trips"} on ${d}`,
        );
    })
    .on("mouseout", function () {
      d3.select(this)
        .transition()
        .duration(50)
        .style("filter", "brightness(100%)");

      tooltip.transition().duration(50).style("opacity", "0");
    });
};

export const legendHeatmap = (chart, cellSize, margin, height) => {
  const colors = [
    Color.heatmapColor,
    Color.heatmap[0],
    Color.heatmap[4],
    Color.heatmap[6],
  ];

  chart
    .append("text")
    .attr("width", cellSize - cellSize / 4)
    .attr("height", cellSize - cellSize / 4)
    .attr("fill", "white")
    .attr("x", 0)
    .attr("y", height + margin.top + cellSize / 2)
    .style("font-size", cellSize - cellSize / 4)
    .text("Less");

  chart
    .append("text")
    .attr("width", cellSize - cellSize / 4)
    .attr("height", cellSize - cellSize / 4)
    .attr("fill", "white")
    .attr("x", cellSize * 6)
    .attr("y", height + margin.top + cellSize / 2)
    .style("font-size", cellSize - cellSize / 4)
    .text("More");

  chart
    .append("g")
    .attr("fill", "none")
    .selectAll("rect")
    .data([0, 1, 2, 3])
    .enter()
    .append("rect")
    .attr("margin", 2)
    .attr("rx", cellSize / 8)
    .attr("ry", cellSize / 8)
    .attr("width", cellSize - cellSize / 4)
    .attr("height", cellSize - cellSize / 4)
    .attr("x", (d) => (d + 1) * cellSize + 12)
    .attr("y", height + margin.top)
    .attr("fill", (d) => colors[d]);
};
