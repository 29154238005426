import { db } from "../../utils/Firebase";
import firebase from "firebase";

const TRIPS_COLLECTION = db.collection("trips");

export const createTrip = async (name) => {
  const tripRef = TRIPS_COLLECTION.doc();
  await tripRef.set({
    name,
    id: tripRef.id,
    created_at: firebase.firestore.Timestamp.now(),
    visible: true,
  });
};

export const updateTrip = async (id, data) => {
  const tripRef = TRIPS_COLLECTION.doc(id);
  await tripRef.set(data, { merge: true });
};

export const getTrip = async (id) => {
  const tripSnap = await TRIPS_COLLECTION.doc(id).get();
  return tripSnap?.data();
};

export const addLog = async (id, data) => {
  const tripSnap = TRIPS_COLLECTION.doc(id);
  await tripSnap?.update({ log: data });
};

export const getTripData = async (tripId) => {
  const dataSnap = await TRIPS_COLLECTION.doc(tripId)
    .collection("minutes")
    .orderBy("time", "asc")
    .get();
  return dataSnap.docs?.map((x) => {
    let data = x.data();
    data.id = x.id;
    return data;
  });
};

export const getTripByParam = async (id, param) => {
  const tripSnap = TRIPS_COLLECTION.where(param, "==", id).get();
  return tripSnap.data();
};

export const getTripDataSubscription = async (tripId, callback) => {
  return TRIPS_COLLECTION.doc(tripId)
    .collection("minutes")
    .orderBy("time", "asc")
    .onSnapshot((dataSnap) => {
      callback(dataSnap.docs.map((d) => d.data()));
    });
};

export const getTripDataSecond = async (tripId, minuteId) => {
  const dataSnap = await db
    .collection("trips/" + tripId + "/minutes")
    .doc(minuteId)
    .collection("seconds")
    .orderBy("time", "asc")
    .get();
  return dataSnap.docs?.map((x) => {
    let data = x.data();
    data.id = x.id;
    return data;
  });
};

export const getTrips = async (companyId) => {
  const tripsSnap = await TRIPS_COLLECTION.where(
    "company_id",
    "==",
    companyId,
  ).get();
  return tripsSnap.docs.map((v) => {
    let data = v.data();
    data.id = v.id;
    return data;
  });
};

export const getAllTrips = async () => {
  const tripsSnap = await TRIPS_COLLECTION.where("visible", "==", true).get();
  return tripsSnap.docs.map((v) => {
    let data = v.data();
    data.id = v.id;
    return data;
  });
};

export const getTripsForVessel = async (vesselId) => {
  const tripsSnap = await TRIPS_COLLECTION.where("vessel_id", "==", vesselId)
    .where("visible", "==", true)
    .get();
  return tripsSnap.docs.map((v) => {
    let data = v.data();
    data.id = v.id;
    return data;
  });
};

export const getTripsSubscription = async (companyId, callback) => {
  return TRIPS_COLLECTION.orderBy("start", "asc")
    .where("company_id", "==", companyId)
    .where("visible", "==", true)
    .onSnapshot((tripsSnap) => {
      callback(
        tripsSnap.docs.reverse().map((v) => {
          let trip = v.data();
          trip.id = v.id;
          return trip;
        }),
      );
    });
};

export const getAllTripsSubscription = async (callback) => {
  return TRIPS_COLLECTION.orderBy("start", "asc")
    .where("visible", "==", true)
    .onSnapshot((tripsSnap) => {
      callback(
        tripsSnap.docs.reverse().map((v) => {
          let trip = v.data();
          trip.id = v.id;
          return trip;
        }),
      );
    });
};

export const deleteTrip = (id) => {
  TRIPS_COLLECTION.doc(id).update({ visible: false });
};
