import { Button, Form } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import * as Color from "utils/Theme";

const PhoneSignUpFormSchema = Yup.object().shape({
  phoneNumber: Yup.string().required(),
});

export const PhoneSignUpForm = ({ onSubmitExternal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(PhoneSignUpFormSchema),
  });

  const onSubmit = (values) => {
    onSubmitExternal(values);
    reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Field>
        <label>Phone Number</label>
        <input
          placeholder="Phone number"
          type="tel"
          name="phoneNumber"
          {...register("phoneNumber")}
        />
        <p style={{ color: Color.red }}>{errors.phoneNumber?.message}</p>
      </Form.Field>
      <Button fluid primary type="submit">
        Sign up
      </Button>
    </Form>
  );
};
