import { useEffect, useState } from "react";

import { getAllIssuesSubscription } from "data/issues";

export const useIssues = (id, param = "trip_id") => {
  const [issue, setIssue] = useState([]);

  let unsubscribeToIssue;

  useEffect(() => {
    if (id) {
      (async () => {
        await getData();
      })();

      return () => {
        if (unsubscribeToIssue) unsubscribeToIssue();
      };
    }
  }, [id]);

  const getData = async () => {
    unsubscribeToIssue = await getAllIssuesSubscription(id, param, setIssue);
  };

  return issue;
};
