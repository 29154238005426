// import { Link } from "react-router-dom";

import {
  getTodaysAvgRog,
  getTodaysAvgSog,
  getTodaysPeakImpact,
  getTodaysRedImpacts,
  getTodaysTrips,
} from "./LiveConsole.utils";

export const LiveConsoleTodaysTrips = ({ todaysTrips }) => {
  // const peakImpact = getTodaysPeakImpact(todaysTrips);
  // const peakImpactTrip = todaysTrips.find(
  //   (trip) => trip.peak_impact.toFixed(1) === peakImpact,
  // );

  return (
    <div className="console">
      <div className="console__general">
        <h3>{getTodaysTrips(todaysTrips)}</h3>
        <p>Today&apos;s Trips</p>
      </div>
      <div className="console__general">
        <h3>{getTodaysRedImpacts(todaysTrips)}</h3>
        <p>Today&apos;s Red Impacts</p>
      </div>
      <div className="console__general">
        <h3>{getTodaysAvgRog(todaysTrips)}</h3>
        <p>Avg ROG</p>
      </div>
      <div className="console__general">
        <h3>{getTodaysAvgSog(todaysTrips)}</h3>
        <p>AVG SOG</p>
      </div>
      <div className="console__general">
        <h3>{getTodaysPeakImpact(todaysTrips)} g</h3>
        {/* <Link to={`/trips/description/${peakImpactTrip?.id}`} className="link">
          Peak Impact
        </Link> */}
        <p>Peak Impact</p>
      </div>
    </div>
  );
};
