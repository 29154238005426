import { useState, useEffect, useContext } from "react";

import { AuthContext } from "utils/AuthContext";
import { getGroupsSubscription, getGroup } from "data/groups";

export const useGroups = () => {
  const [groups, setGroups] = useState([]);
  const { company } = useContext(AuthContext);
  let unsubscribeToGroups;

  useEffect(() => {
    (async () => {
      await getData();
    })();

    return () => {
      if (unsubscribeToGroups) unsubscribeToGroups();
    };
  }, []);

  const getData = async () => {
    unsubscribeToGroups = await getGroupsSubscription(company.id, (groups) => {
      setGroups(groups);
    });
  };

  return groups;
};

export const useGroup = (id) => {
  const [group, setGroup] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        await getData();
      })();
    }
  }, [id]);

  const getData = async () => {
    const group = await getGroup(id);
    setGroup(group);
  };

  return group;
};
