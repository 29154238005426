export const getTodaysTrips = (trips) => {
  return trips.length;
};

export const getTodaysRedImpacts = (trips) => {
  return trips.reduce((a, b) => a + b.red_impacts, 0).toFixed(0);
};

export const getTodaysAvgRog = (trips) => {
  if (trips.length === 0) return 0;

  return (
    (
      trips
        .filter((x) => !isNaN(x.avg_rog))
        .reduce((a, b) => a + b.avg_rog, 0) / trips.length
    ).toFixed(1) + " kn"
  );
};

export const getTodaysAvgSog = (trips) => {
  if (trips.length === 0) return 0;

  return (
    (
      trips
        .filter((x) => !isNaN(x.avg_sog))
        .reduce((a, b) => a + b.avg_sog, 0) / trips.length
    ).toFixed(1) + " kn"
  );
};

export const getTodaysPeakImpact = (trips) => {
  return trips.reduce((a, b) => Math.max(a, b.peak_impact), 0).toFixed(1);
};
