import L from "leaflet";
import circle from "../../assets/markers/circle.png";
import boat from "../../assets/markers/boat.png";

export const impactMarker = new L.Icon({
  iconUrl: circle,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  className: "impact-marker",
});

export const sogMarker = new L.Icon({
  iconUrl: circle,
  iconSize: [14, 14],
  iconAnchor: [7, 7],
  className: "sog-marker",
});

export const boatMarker = new L.Icon({
  iconUrl: boat,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  className: "boat-marker",
});
