import * as yup from "yup";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { createGroup } from "data/groups";
import { AuthContext } from "utils/AuthContext";

const validationSchema = yup.object().shape({
  name: yup.string("Name should be a string").required(),
});

export const useCreateGroup = () => {
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { company } = useContext(AuthContext);

  const onSubmit = async (values) => {
    await createGroup({ ...values, company_id: company.id });
    reset({
      name: null,
    });
  };

  return { reset, handleSubmit, register, onSubmit, errors };
};
