import { useState, useEffect, useRef } from "react";
import { Icon } from "semantic-ui-react";

const Dropdown = ({ data, showState, onSeriesSelect, scroll, setScroll }) => {
  const listRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (listRef.current) listRef.current.scrollTop = scroll;
  }, [data, onSeriesSelect]);

  return (
    <div className="dropdown">
      <Icon
        className="dropdown-icon"
        size="large"
        name="chart line"
        onClick={toggling}
      />
      {isOpen && (
        <div
          ref={listRef}
          className="dropdown-list"
          onScroll={(e) => {
            setScroll(e.target.scrollTop);
          }}
        >
          {Object.entries(data.groups).map((group) => {
            const listItem = [];
            const groupName = group[0];
            const series = group[1];
            listItem.push(
              <div className="dropdown-list-group" key={groupName}>
                <label htmlFor={groupName}>{groupName}</label>
              </div>,
            );
            series.map((currentSeries, seriesIndex) => {
              listItem.push(
                <div
                  className="dropdown-list-item"
                  key={`${groupName}${seriesIndex}`}
                >
                  <input
                    className="toggle"
                    name={currentSeries}
                    id={currentSeries}
                    type={"checkbox"}
                    onChange={() => {
                      onSeriesSelect(currentSeries);
                    }}
                    checked={showState[currentSeries]}
                  />
                  <label htmlFor={currentSeries}>
                    {data.legend?.[currentSeries]}
                  </label>
                </div>,
              );
            });
            return listItem;
          })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
