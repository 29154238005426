import { Button, Icon } from "semantic-ui-react";

import { Input } from "components/Inputs";
import { Loader } from "components/Loader";

import { deleteGroup } from "data/groups";
import { useCreateGroup } from "./hooks/useCreateGroup";
import { useGroups } from "hooks/useGroups";
import { useLoading } from "hooks/useLoading";

export const CreateGroup = () => {
  const { handleSubmit, register, onSubmit, errors } = useCreateGroup();
  const groups = useGroups();
  const loading = useLoading(groups);

  if (loading) return <Loader />;

  return (
    <>
      <h3>Create Group</h3>
      <div className="groups">
        <form onSubmit={handleSubmit(onSubmit)} className="groups__field">
          <Input
            label="Group name"
            id="name"
            name="name"
            className="groups__field-input"
            error={errors?.name}
            {...register("name")}
          />
          <Button type="submit" primary>
            Add
          </Button>
        </form>
        <div className="groups__data">
          {groups?.length > 0 &&
            groups.map((group, ndx) => (
              <div key={ndx} className="groups__data-element">
                <span className="groups__data-element-name">{group.name}</span>
                <Icon name="delete" onClick={() => deleteGroup(group?.id)} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
