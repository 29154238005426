// Primary colors
export const backgroundColor = "#22282e";

// Semantic colors
export const red = "#d51635";
export const green = "#00d628";
export const blue = "#0b2ed6";
export const yellow = "#d6ac2b";

// Neutral colors
export const black = "#151515";
export const white = "#f5f5f5";
export const gray = "#60666e";

// Radar chart colors
export const good = [
  "#34BB34",
  "#2EAB2E",
  "#34BB34",
  "#3CCB3C",
  "#47DA47",
  "#3CCB3C",
];
export const caution = [
  "#B6AF16",
  "#AAA412",
  "#B6AF16",
  "#D5CD1B",
  "#E6DE23",
  "#D5CD1B",
];
export const warning = [
  "#B61111",
  "#A60E0E",
  "#B61111",
  "#C81A1A",
  "#D82929",
  "#C81A1A",
];
export const backgroundRadar = [
  "#7C7C7C",
  "#727272",
  "#7C7C7C",
  "#888888",
  "#909090",
  "#888888",
];

// Chart colors
export const chartRed = "#d51635";
export const chartPink = "#ed0ca2";
export const chartGreen = "#00d628";
export const chartBlue = "#0b2ed6";
export const chartDarkBlue = "#041257";
export const chartPurple = "#cf0be3";
export const chartYellow = "#d6ac2b";

// Heatmap colors
export const heatmapColor = "#e0e0e0";
export const heatmap = [
  "#ced0d0",
  "#bcbfc0",
  "#a9afb0",
  "#979fa0",
  "#858e91",
  "#536265",
  "#3c4d51",
];

// 91a4a9
// 6e7e82
// 61787d
// 3c4d51