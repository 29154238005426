import { useContext } from "react";

import { DataContext } from "utils/DataContext";

import { useVessels } from "hooks/useVessels";
import { useCaptains } from "hooks/useCaptains";
import { useTrips } from "hooks/useTrips";
import { useLoading } from "hooks/useLoading";
import { Trips } from "utils/PagesContent";

export const useTripsData = () => {
  const { pinnedTrips, setPinnedTrips, focusTrips, setFocusTrips } =
    useContext(DataContext);
  const vessels = useVessels();
  const captains = useCaptains();
  const trips = useTrips();

  const loading = useLoading(vessels, captains, trips);

  const headings = Trips.headings();

  const onPinTrip = (trip) => () => {
    if (pinnedTrips.find((t) => t.id === trip.id)) {
      setPinnedTrips((current) => current.filter((t) => t.id !== trip.id));
      if (focusTrips.find((t) => t.id === trip.id)) {
        setFocusTrips((current) => current.filter((t) => t.id !== trip.id));
      }
    } else {
      setPinnedTrips((current) => [...current, trip]);
    }
  };

  const tripsData = Trips.data(
    trips,
    vessels,
    captains,
    pinnedTrips,
    onPinTrip,
  );

  const expandedRowData = trips;

  return { headings, tripsData, expandedRowData, loading };
};
