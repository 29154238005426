export const useVesselMap = (focusTripData) => {
  const focusTripDetails = Object.values(focusTripData)[0];

  const focusTripMaxImpact = focusTripDetails?.reduce(
    (a, b) => Math.max(a, b.imu_1.max_impact),
    0,
  );

  const focusTripMaxSog = focusTripDetails?.reduce(
    (a, b) => Math.max(a, b.avg_sog),
    0,
  );

  const focusTripMaxImpactObj = focusTripDetails?.find(
    (trip) => trip.imu_1.max_impact === focusTripMaxImpact,
  );

  const focusTripMaxSogObj = focusTripDetails?.find(
    (trip) => trip.avg_sog === focusTripMaxSog,
  );

  return { focusTripMaxImpactObj, focusTripMaxSogObj };
};
