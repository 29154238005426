import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { PublicTemplate } from "templates";
import { CompanyProfile } from "./CompanyProfile";
import { SignUpForms } from "./SignUpForms";
import { UserProfile } from "./UserProfile";

export const SignUp = () => {
  const [userInfo, setUserInfo] = useState({});

  return (
    <PublicTemplate title="Sign up to Hefring Cloud">
      <Switch>
        <Route exact path="/signup/account">
          <UserProfile setUserInfo={setUserInfo} userInfo={userInfo} />
        </Route>
        <Route exact path="/signup/company">
          <CompanyProfile userInfo={userInfo} />
        </Route>
        <Route exact path="/signup">
          <SignUpForms setUserInfo={setUserInfo} />
        </Route>
      </Switch>
    </PublicTemplate>
  );
};
