import * as Color from "../Theme";

export const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getMockComfort = (length) => {
  const comfort = [];
  const colors = [Color.chartRed, Color.chartYellow, Color.chartGreen];
  for (let i = 0; i < length; i++) {
    const red = Math.random() < 0.1 ? 4 : 0;
    const yellow = Math.random() < 0.005 ? 1 : 0;
    const green = Math.random() < 0.005 ? 1 : 0;

    const max = Math.max(red, yellow, green);
    const index = [red, yellow, green].indexOf(max);

    comfort.push({ g: green, y: yellow, r: red, color: colors[index] });
  }

  return comfort;
};

export const getMockHeatmapData = () => {
  const mockData = [];
  for (let i = 0; i < 366; i++) {
    const month = Math.floor(Math.random() * 12) + 1;
    const day = Math.floor(Math.random() * 31) + 1;
    const monthStr = month < 10 ? `0${month}` : month;
    const dayStr = day < 10 ? `0${day}` : day;
    mockData.push({
      date: `${dayStr}/${monthStr}/22`,
      trips: Math.floor(Math.random() * 15),
    });
  }
  return mockData;
};

export const formatLegend = (name) => {
  let legend = name.toString().replaceAll("_", " ");
  legend = legend.split(" ");
  legend = legend.map((word) => {
    if (["rog", "sog", "cog", "fog"].includes(word)) return word.toUpperCase();
    else return `${word.charAt(0).toUpperCase()}${word.substring(1)}`;
  });
  legend = legend.join(" ");

  return legend;
};

export const groupBy = (object, property) => {
  return object.reduce(function (acc, obj) {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};
