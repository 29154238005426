import { useState, useEffect, useContext } from "react";
import { getDashboard } from "data/dashboard";
import { AuthContext } from "utils/AuthContext";

export const useDashboard = () => {
  const [dashboard, setDashboard] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(async () => {
    let dash = await getDashboard(user.id);
    setDashboard(dash);
  }, []);

  return dashboard;
};
