import { db } from "utils/Firebase";

const DEVICES_COLLECTION = db.collection("devices");
const VESSELS_COLLECTION = db.collection("vessels");

export const getDeviceByCode = async (code) => {
  const devicesSnap = await DEVICES_COLLECTION.where("reg_code", "==", code)
    .limit(1)
    .get();
  devicesSnap.docs.map((d) => {
    let data = d.data();
    data.id = d.id;
    return data;
  });

  if (devicesSnap.docs.length == 0) return null;

  return devicesSnap.docs[0];
};

export const registerDevice = async (deviceId, vesselId, companyId) => {
  let batch = db.batch();

  let deviceRef = DEVICES_COLLECTION.doc(deviceId);
  batch.update(deviceRef, {
    vessel_id: vesselId,
    company_id: companyId,
    is_registered: true,
  });

  let vesselRef = VESSELS_COLLECTION.doc(vesselId);
  batch.update(vesselRef, { device_id: deviceId });

  batch.commit();
};
