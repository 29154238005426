/*******************************
Data
********************************/
import { deleteCompany } from "data/companies";

/*******************************
UI
********************************/
import { Modal } from "components/Modal";

export function CompanyDeleteDialog({ id, show, onHide }) {
  /*******************************
	State and variables
	********************************/

  /*******************************
	Delete user
	********************************/
  const onSubmit = async () => {
    await deleteCompany(id);
    onHide();
  };

  /*******************************
	Render
	********************************/
  return (
    <Modal show={show} onClose={onHide}>
      <h3>Delete Company</h3>
      <p>Are you sure you want to delete company?</p>
      <button onClick={onSubmit}>Delete</button>
    </Modal>
  );
}
