export const navItems = [
  {
    name: "Fleet",
    path: "/",
    authRoles: ["admin", "super"],
    icon: "map",
  },
  // {
  //   name: "Dashboard",
  //   path: "/dashboard",
  //   authRoles: ["user", "admin", "super"],
  //   icon: "dashboard",
  // },
  {
    name: "Trips",
    path: "/trips",
    authRoles: ["user", "admin", "super"],
    icon: "compass",
  },
  {
    name: "Vessels",
    path: "/vessels",
    authRoles: ["user", "admin", "super"],
    icon: "ship",
  },
  // {
  //   name: "Captains",
  //   path: "/captains",
  //   authRoles: ["admin", "super"],
  //   icon: "binoculars",
  // },
  {
    name: "Clients",
    path: "/users",
    authRoles: ["admin", "super"],
    icon: "users",
  },
  {
    name: "Dealers",
    path: "/companies",
    authRoles: ["super"],
    icon: "building",
  },
  {
    name: "Settings",
    path: "/settings",
    authRoles: ["user", "admin", "super"],
    icon: "setting",
  },
  {
    name: "Profile",
    path: "/profile",
    authRoles: ["user", "admin", "super"],
    icon: "user",
  },
  {
    name: "Signout",
    path: "/logout",
    authRoles: ["user", "admin", "super"],
    icon: "sign out",
  },
];

export const filterNavItems = (user) =>
  navItems.filter((x) => x.authRoles.includes(user.auth_role));
