import React, { useState } from "react";
import { Icon, Button } from "semantic-ui-react";

import { ActivityModal } from "components/Modal";

import { useIssues } from "hooks/useIssues";
import { blue } from "utils/Theme";

const Logs = ({
  data,
  canAdd = false,
  param,
  captain = "",
  vessel = "",
  className = "",
}) => {
  const [status, setStatus] = useState("");
  const [log, setLog] = useState(null);
  const issues = useIssues(data?.id, param);

  const onOpen = (log, status) => {
    setStatus(status);
    setLog(log);
  };

  const onClose = () => {
    setStatus("");
    setLog(null);
  };

  return (
    <div className={`logs ${className}`}>
      <div className="logs__element">
        <div className="d-flex justify-between h-35">
          <h3>Logs</h3>
          {!data?.log && canAdd && (
            <Icon
              name="plus"
              onClick={() => setStatus("log")}
              className="cursor-pointer"
            />
          )}
        </div>
        {data?.log ? (
          <Button
            onClick={() => onOpen(data?.log, "log")}
            primary
            key={data?.log.text}
            className="m-5"
            style={{ backgroundColor: blue }}
          >
            Log
          </Button>
        ) : (
          <span>No logs</span>
        )}
      </div>
      <div className="logs__element">
        <div className="d-flex justify-between h-35">
          <h3>Issues</h3>
          {canAdd && (
            <Icon
              name="plus"
              onClick={() => setStatus("issue")}
              className="cursor-pointer"
            />
          )}
        </div>
        {issues?.length ? (
          issues.map((issue, index) => (
            <Button
              style={{ backgroundColor: blue }}
              onClick={() => onOpen(issue, "issue")}
              primary
              key={issue.text + index}
              className="m-5"
            >
              {`Issue #${++index}`}
            </Button>
          ))
        ) : (
          <span>No issues</span>
        )}
      </div>
      <ActivityModal
        id={data?.id}
        show={status !== ""}
        status={status}
        captain={captain}
        vessel={vessel}
        disabled={!!log}
        data={log}
        onClose={onClose}
      />
    </div>
  );
};

export default Logs;
