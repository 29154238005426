import { useContext } from "react";

import { AuthContext } from "utils/AuthContext";
import { useCaptains } from "hooks/useCaptains";
import { useTrips } from "hooks/useTrips";
import { Vessels } from "utils/PagesContent";
import { useVessels } from "hooks/useVessels";
import { useLoading } from "hooks/useLoading";

export const useVesselsData = () => {
  const { user } = useContext(AuthContext);
  const captains = useCaptains();
  const trips = useTrips();
  const vessels = useVessels();
  const allowEdit = user.auth_role === "admin" || user.auth_role === "super";

  const loading = useLoading(captains, vessels, trips);

  const headings = Vessels.headings(allowEdit);

  const vesselsData = Vessels.data(vessels, captains, trips, allowEdit);

  const expandedRowData = vessels;

  return { headings, vesselsData, expandedRowData, loading };
};
