import { db } from "../../utils/Firebase";
import firebase from "firebase";

const CAPTAINS_COLLECTION = db.collection("captains");

export const createCaptain = async (values) => {
  const captainsRef = CAPTAINS_COLLECTION.doc();
  await captainsRef.set({
    id: captainsRef.id,
    created_at: firebase.firestore.Timestamp.now(),
    visible: true,
    ...values,
  });
};

export const updateCaptain = async (id, values) => {
  const captainsRef = CAPTAINS_COLLECTION.doc(id);
  await captainsRef.set(values, { merge: true });
};

export const getCaptain = async (id) => {
  const captainSnap = await CAPTAINS_COLLECTION.doc(id).get();
  let captain = captainSnap.data();
  if (!captain) return {};
  captain.id = captainSnap.id;
  return captain;
};

export const getCaptains = async (companyId) => {
  const captains = await CAPTAINS_COLLECTION.where(
    "company_id",
    "==",
    companyId,
  )
    .where("visible", "==", true)
    .get();
  return captains.docs.map((c) => {
    let data = c.data();
    data.id = c.id;
    return data;
  });
};

export const getAllCaptains = async () => {
  const captains = await CAPTAINS_COLLECTION.where("visible", "==", true).get();
  return captains.docs.map((c) => {
    let data = c.data();
    data.id = c.id;
    return data;
  });
};

export const getCaptainsSubscription = (companyId, callback) => {
  return CAPTAINS_COLLECTION.where("company_id", "==", companyId)
    .where("visible", "==", true)
    .onSnapshot((captainsSnap) => {
      callback(
        captainsSnap.docs.map((c) => {
          let captain = c.data();
          captain.id = c.id;
          return captain;
        }),
      );
    });
};

export const getAllCaptainsSubscription = (callback) => {
  return CAPTAINS_COLLECTION.where("visible", "==", true).onSnapshot(
    (captainsSnap) => {
      callback(
        captainsSnap.docs.map((c) => {
          let captain = c.data();
          captain.id = c.id;
          return captain;
        }),
      );
    },
  );
};

export const deleteCaptain = (id) => {
  CAPTAINS_COLLECTION.doc(id).update({ visible: false });
};
