import { Tabs } from "./Tabs";
import { TabItem } from "./TabItem";

export const tabsContent = {
  setContent: function (tabName, content) {
    this[tabName] = content;
  },
};

export const getProps = (children) => {
  return Array.isArray(children)
    ? children.map((item) => ({ ...item.props }))
    : [];
};

export const Tab = { Container: Tabs, Item: TabItem };
