import { Button, Form, Icon } from "semantic-ui-react";
import { useChangeEmail } from "./hooks/useChangeEmail";

export const ChangeEmail = ({ user }) => {
  const {
    handleChangeEmail,
    handleChangePassword,
    isEditingEmail,
    isEditingPassword,
    emailEditable,
    register,
    handleSubmit,
    setEmailEditable,
    handleChangeAttempt,
  } = useChangeEmail(user);

  return (
    <>
      <h3>Email authentication credentials</h3>
      <div className="settings__changer-container">
        <h4>Change email</h4>
        <Form onSubmit={handleSubmit(handleChangeEmail)}>
          <Form.Field className="settings__field">
            <input
              disabled={!emailEditable}
              defaultValue={user.email}
              type="email"
              name="email"
              {...register("email")}
            />
            <Icon
              onClick={() => setEmailEditable(!emailEditable)}
              className="settings__edit"
              name="edit"
            />
          </Form.Field>
          {isEditingEmail ? (
            <>
              <label className="text white">Confirm password</label>
              <Form.Field>
                <input
                  type="password"
                  name="password"
                  {...register("password")}
                />
              </Form.Field>
              <Button primary type="submit">
                Confirm change
              </Button>
            </>
          ) : (
            <Button
              onClick={handleChangeAttempt("email")}
              disabled={!emailEditable}
              primary
              type="button"
            >
              Change
            </Button>
          )}
        </Form>
        <div>
          <h4 className="settings__section-divider">Reset password</h4>
          {!isEditingPassword && (
            <Button
              onClick={handleChangeAttempt("password")}
              primary
              type="button"
            >
              Reset
            </Button>
          )}
          <Form onSubmit={handleSubmit(handleChangePassword)}>
            {isEditingPassword && (
              <div className="settings__passwords">
                <label className="text white">New password</label>
                <Form.Field className="settings__field">
                  <input
                    type="password"
                    name="newPassword"
                    {...register("newPassword")}
                  />
                </Form.Field>
                <label className="text white">Current password</label>
                <Form.Field className="settings__field">
                  <input
                    type="password"
                    name="password"
                    {...register("password")}
                  />
                </Form.Field>
                <Button primary type="button" onClick={handleChangeAttempt("")}>
                  Cancel
                </Button>
                <Button primary type="submit">
                  Confirm change
                </Button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};
