import { forwardRef } from "react";

const TextareaRef = (
  { label = "", id, error, disabled = false, ...props },
  ref,
) => {
  return (
    <div className="textarea">
      {label && (
        <label htmlFor={id} className="textarea__label">
          {label}
        </label>
      )}
      <textarea
        ref={ref}
        className={`textarea__field textarea__field-${disabled && "disabled"}`}
        disabled={disabled}
        {...props}
      />
      {error && <span className="textarea__error">{error?.message}</span>}
    </div>
  );
};

export const Textarea = forwardRef(TextareaRef);
