import { useContext } from "react";
import { DataContext } from "utils/DataContext";

export const useTripsWithConsole = (data, callback = null) => {
  const { setPinnedTrips, setFocusTrips, pinnedTrips, focusTrips } =
    useContext(DataContext);

  const onFocusTrip = () => {
    if (focusTrips.find((t) => t.id === data.id)) {
      setFocusTrips((current) => current.filter((t) => t.id !== data.id));
    } else {
      setFocusTrips((current) => [...current, data]);
    }
    callback && callback();
  };

  const showOnMap = () => {
    if (pinnedTrips.find((t) => t.id === data.id)) {
      setFocusTrips((current) => [...current, data]);
      callback && callback();
    } else {
      setPinnedTrips((current) => [...current, data]);
      setFocusTrips((current) => [...current, data]);
      callback && callback();
    }
  };

  const onPinAndFocusTrip = () => {
    if (
      pinnedTrips.find((t) => t.id === data.id) &&
      focusTrips.find((t) => t.id === data.id)
    ) {
      setPinnedTrips((current) => current.filter((t) => t.id !== data.id));
      setFocusTrips((current) => current.filter((t) => t.id !== data.id));
    } else {
      setPinnedTrips((current) => [...current, data]);
      setFocusTrips((current) => [...current, data]);
    }
    callback && callback();
  };

  return { onFocusTrip, onPinAndFocusTrip, showOnMap };
};
