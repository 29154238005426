import * as d3 from "d3";
import { useEffect, useRef } from "react";
import * as Data from "../../utils/chartUtils/Data";
import * as draw from "../../utils/chartUtils/Draw";
import * as Color from "../../utils/Theme";
import { Icon } from "semantic-ui-react";
import { Dropdown } from "../Dropdown";
import { useChart } from "./useChart";

export const Chart = ({
  title = "",
  data,
  aspectRatio,
  targetWidth,
  onClickLeft,
  onClickRight,
}) => {
  const ref = useRef();
  const {
    scroll,
    brushPosition,
    showState,
    setBrushPosition,
    setScroll,
    setShowState,
  } = useChart();
  let margin = { top: 50, right: 10, bottom: 30, left: 10 };
  let contextMargin = { top: 15, right: 10, bottom: 30, left: 10 };

  let startIndex, endIndex, previousBrushSelection;

  console.log(data);

  useEffect(() => {
    const width =
      targetWidth ?? ref.current.offsetWidth - margin.left - margin.right;
    const height = width / aspectRatio;
    const contextHeight = height / 5;

    if (!data.show.x) {
      contextMargin.bottom = 15;
      margin.bottom = 15;
    }

    startIndex = 0;
    endIndex = data.data.x.length + 1;

    // create main and context charts
    const chart = draw.chart(ref, width, height, margin);
    const contextChart = draw.chart(ref, width, contextHeight, contextMargin);
    contextChart.attr("class", "chart-context");

    // get x-axis scale
    const xScale = getScale(data.data.x, width);

    // draw x-axis for both charts
    draw.xAxis(chart, xScale, height, margin, data.show.x);
    draw.xAxis(contextChart, xScale, contextHeight, contextMargin, data.show.x);

    // draw legends for main chart
    drawLegends(chart, data);

    // clip for main chart so data does not overflow
    const clip = draw.clip(ref, chart, width, height, margin);
    const contextClip = draw.clip(
      ref,
      contextChart,
      width,
      contextHeight,
      contextMargin,
    );

    // draw grid lines
    draw.gridLines(clip, width, height);

    // draw lines and bars for both charts
    drawSeries(clip, xScale, data, width, height);
    drawSeries(contextClip, xScale, data, width, contextHeight);

    // draw tooltip and hover line
    const tooltip = draw.tooltip(ref, "tooltip");
    const eventTooltip = draw.tooltip(ref, "eventTooltip");
    const hoverLine = draw.hoverLine(chart);

    // create brush for context chart
    const [brush, contextBrush] = draw.brush(
      contextChart,
      width,
      contextHeight,
      contextMargin,
    );
    brush.on("brush end", (e) =>
      brushed(
        e,
        chart,
        xScale,
        clip,
        data,
        data.data.x,
        width,
        height,
        contextBrush,
        brush,
        eventTooltip,
      ),
    );

    // draw an overlay on main chart to catch mouse events
    const overlay = draw.overlay(chart, width, height, margin);
    overlay
      .on("mousemove", (e) =>
        chartMouseMove(e, data, tooltip, hoverLine, width, height),
      )
      .on("mouseover", () => chartMouseOver(tooltip, hoverLine))
      .on("mouseout", () => chartMouseLeave(tooltip, hoverLine));

    // draw events (button pressed)
    if (data.data.events?.length > 0) {
      drawEvents(
        clip,
        xScale,
        data.data.events,
        data.data.x,
        height,
        eventTooltip,
      );
    }

    // brush initial position
    contextBrush.call(brush.move, brushPosition);

    return () => {
      chart.remove();
      contextChart.remove();
      tooltip.remove();
      eventTooltip.remove();
      hoverLine.remove();
      contextBrush.remove();
    };
  }, [data, showState]);

  useEffect(() => {
    setShowState(data.show);
  }, [data]);

  const drawLegends = (chart, data) => {
    let nextPosition = { x: margin.left, y: margin.top - 28 };

    Object.entries(data.legend).map((legend) => {
      const series = legend[0];
      const label = legend[1];

      if (showState[series] && !data.groups.events?.includes(series)) {
        draw.legend(
          chart,
          label,
          data?.color?.[series],
          nextPosition.x,
          nextPosition.y,
        );
        nextPosition.x += 50 + label.length * 4;
      }
    });
  };

  const drawSeries = (chart, xScale, data, width, height) => {
    const bars = [];
    chart.selectAll(".line").remove();
    chart.selectAll(".verticalBar").remove();

    for (const [key, value] of Object.entries(data.data)) {
      if (key.toString().includes("impact") && key.toString() !== "avg_impact")
        bars.push(key);
      if (showState[key] && key !== "x" && !bars.includes(key)) {
        draw.line(
          chart,
          value.slice(startIndex, endIndex),
          width,
          height,
          data.color[key],
          data.range[key],
        );
      }
    }

    let numberOfBars = 0;
    bars.forEach((bar) => {
      if (showState[bar]) numberOfBars += 1;
    });

    let barOffset = 0;
    bars.map((bar) => {
      if (showState[bar]) {
        draw.bars(
          chart,
          data.data.x.slice(startIndex, endIndex),
          data.data[bar].slice(startIndex, endIndex),
          xScale,
          width,
          height,
          data.color[bar],
          data.range[bar],
          numberOfBars,
          barOffset,
        );
        barOffset += 1;
      }
    });
  };

  const drawEvents = (chart, xScale, events, x, height, eventTooltip) => {
    chart.selectAll(".event").remove();
    chart.selectAll(".event-line").remove();

    const grouppedEvents = Data.groupBy(events, "type");

    console.log(grouppedEvents);

    Object.entries(grouppedEvents).map((group) => {
      const groupName = group[0];
      const events = group[1];
      if (showState[groupName] && data?.show?.[groupName]) {
        eventTooltip.selectAll(".eventContainer").remove();
        const shapeWidth = 8;

        if (groupName === "comfort") {
          // group events by common index
          const commonEvents = [];
          events.map((event) => {
            event.data.slice(startIndex, endIndex).map((buttonEvent, i) => {
              if (buttonEvent.g > 0 || buttonEvent.y > 0 || buttonEvent.r > 0) {
                commonEvents.push({
                  title: event.box,
                  content: buttonEvent,
                  index: i,
                });
              }
            });
          });
          const grouppedCommonEvents = Data.groupBy(commonEvents, "index");

          // draw an event on the chart for each group
          Object.entries(grouppedCommonEvents).map((group) => {
            const i = group[0];
            const events = group[1];
            const eventShape = draw.event(
              chart,
              x[i],
              xScale,
              height,
              shapeWidth,
              events[0].content.color,
            );

            eventShape
              .on("mouseover", (e) => eventMouseOver(e, eventTooltip, events))
              .on("mouseout", () => eventMouseOut(eventTooltip));
          });
        }
      }
    });
  };

  const getScale = (x, width) => {
    const timeScale = d3.scalePoint().domain(x).range([0, width]);

    timeScale.invert = (function () {
      var domain = timeScale.domain();
      var range = timeScale.range();
      var scale = d3.scaleQuantize().domain(range).range(domain);

      return function (x) {
        return scale(x);
      };
    })();

    return timeScale;
  };

  const chartMouseOver = (tooltip, hoverLine) => {
    tooltip.transition().duration(200).style("opacity", 1);

    hoverLine.style("opacity", 1);
  };

  const chartMouseLeave = (tooltip, hoverLine) => {
    tooltip.transition().duration(100).style("opacity", 0);

    hoverLine.style("opacity", 0);
  };

  const chartMouseMove = (e, data, tooltip, hoverLine, width, height) => {
    const [x, y] = d3.pointer(e, ref);

    // get nearest date index from mouse position
    const availableDates = data.data.x;
    const xPosition =
      x - ref.current.getBoundingClientRect().left - margin.left;
    const xScale = getScale(availableDates.slice(startIndex, endIndex), width);
    const xValue = xScale.invert(xPosition);
    const nearestDateIndex = availableDates.indexOf(xValue);

    // get nearest date from index
    const d0 = availableDates[nearestDateIndex];
    const d1 = availableDates[nearestDateIndex + 1];
    const closestDate = xValue - d0 > d1 - xValue ? d1 : d0;
    const xCoord = xScale(closestDate);

    // append content to tooltip from aquired date
    draw.tooltipContent(
      tooltip,
      data,
      nearestDateIndex,
      closestDate,
      showState,
    );

    // position tooltip
    let tooltipXPos = x + 12;
    let tooltipYPos = y;
    const rows = document.querySelectorAll(".tooltip-row");

    if (
      x >=
      width / 2 + margin.left + ref.current.getBoundingClientRect().left
    ) {
      let maxWidth = 0;

      for (let i = 0; i < rows.length; ++i) {
        if (rows[i].offsetWidth > maxWidth) maxWidth = rows[i].offsetWidth;
      }
      tooltipXPos = x - maxWidth - 3 * 12;
    }

    if (
      y + rows.length * 32 + 16 >
      ref.current.getBoundingClientRect().bottom
    ) {
      tooltipYPos = y - rows.length * 32;
    }

    tooltip.style("left", `${tooltipXPos}px`).style("top", `${tooltipYPos}px`);

    // position hover line
    hoverLine.attr(
      "d",
      `M ${xCoord + margin.left} 50 V ${height + margin.top}`,
    );
  };

  const eventMouseOver = (e, eventTooltip, events) => {
    const [x, y] = d3.pointer(e, ref);
    const yOffset = events.length * 50 + events.length * 24;

    eventTooltip.selectAll(".eventContainer").remove();

    eventTooltip
      .style("left", `${x - 75}px`)
      .style("top", `${y - yOffset}px`)
      .transition()
      .duration(100)
      .style("opacity", 1);

    // append each event to the tooltip
    events.map((event) => {
      const eventContainer = eventTooltip
        .append("div")
        .attr("class", "eventContainer");

      // title
      eventContainer
        .append("p")
        .attr("class", "eventContainer-title")
        .text(`${event.title}`);

      // body
      const content = eventContainer
        .append("div")
        .attr("class", "eventContainer-content");

      Object.entries(event.content).map((button, i) => {
        if (i >= 3) return;
        const count = button[1];

        const color =
          button[0] === "g"
            ? Color.chartGreen
            : button[0] === "y"
            ? Color.chartYellow
            : Color.chartRed;

        const counter = content
          .append("div")
          .style("display", "flex")
          .style("align-items", "center")
          .style("margin-right", "16px");
        counter
          .append("div")
          .attr("class", "tooltip-circle")
          .style("background-color", color);
        counter.append("p").attr("class", "tooltip-text").text(`${count}`);
      });
    });
  };

  const eventMouseOut = (eventTooltip) => {
    eventTooltip.transition().duration(100).style("opacity", 0);
  };

  const updateShowState = (series) => {
    setShowState((current) => ({
      ...current,
      [series]: !current[series],
    }));
  };

  const brushed = (
    e,
    chart,
    xScale,
    clip,
    data,
    x,
    width,
    height,
    contextBrush,
    brush,
    eventTooltip,
  ) => {
    const brushSelection = e.selection || xScale.range();
    if (brushSelection[1] - brushSelection[0] < width / 10) {
      contextBrush.call(brush.move, previousBrushSelection);
      return;
    }
    const newRange = brushSelection.map(xScale.invert, xScale);

    startIndex = x.indexOf(newRange[0]);
    endIndex = x.indexOf(newRange[1]) + 1;

    const newDomain = x.slice(startIndex, endIndex);
    xScale.domain(newDomain);
    previousBrushSelection = brushSelection;
    setBrushPosition(brushSelection);

    draw.xAxis(chart, xScale, height, margin, data.show.x);
    drawSeries(clip, xScale, data, width, height, startIndex, endIndex);

    if (data.data.events && data.data.events.length > 0) {
      drawEvents(
        clip,
        xScale,
        data.data.events,
        newDomain,
        height,
        eventTooltip,
      );
    }
  };

  return (
    <div className="chart">
      <div className="chart-header">
        <h3>{title}</h3>
        <div className="chart-tools">
          <Icon
            className="chart-tools-arrow"
            fitted
            size="big"
            name="triangle left"
            onClick={onClickLeft}
          />
          <Icon
            className="chart-tools-arrow"
            fitted
            size="big"
            name="triangle right"
            onClick={onClickRight}
          />
          <Dropdown
            data={data}
            showState={showState}
            onSeriesSelect={(series) => updateShowState(series)}
            scroll={scroll}
            setScroll={setScroll}
          />
        </div>
      </div>
      <div ref={ref} className="svg-container"></div>
    </div>
  );
};
