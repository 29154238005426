import * as DateUtils from "./DateUtils";
import * as TripInformation from "./TripInformation";
import * as MapUtils from "./MapUtils";

const data_placeholder = "-";

export const tripDetails = [
  {
    label: "Start time",
    adapter: DateUtils.prettyStartTime,
  },
  {
    label: "End time",
    adapter: DateUtils.prettyEndTime,
  },
  { label: "Duration", adapter: DateUtils.prettyDuration },
];

export const statistics = [
  {
    label: "Avg. impact",
    adapter: TripInformation.getAverageImpact,
  },
  {
    label: "Avg. ROG",
    adapter: TripInformation.getAverageROG,
  },
  {
    label: "Avg. SOG",
    adapter: TripInformation.getAverageSOG,
  },
  {
    label: "Safety quality",
    adapter: TripInformation.getAverageROGoverSOG,
  },
  {
    label: "Peak impact",
    adapter: TripInformation.getPeakImpact,
  },
  {
    label: "Red impacts",
    adapter: TripInformation.getRedImpacts,
  },
];

export const vesselDetails = [
  { label: "Beam Overall", measurement: "m", value: "boa" },
  { label: "Length", measurement: "m", value: "length" },
  { label: "Gross Weight", measurement: "ton", value: "gross_weight" },
  { label: "Fuel Capacity", measurement: "L", value: "fuel_capacity" },
  { label: "Manufacturer", measurement: "", value: "manufacturer" },
  { label: "Cruising Speed", measurement: "kn", value: "cruising_speed" },
  { label: "Max acceleration", measurement: "g", value: "max_acc" },
  { label: "Max speed", measurement: "kn", value: "max_sog" },
];

export const mapGroupsToOptions = (groups) =>
  groups?.map((group) => ({
    text: group.name,
    value: group.id,
  }));

export const mapUsersToOprions = (users) =>
  users?.map((user) => ({ value: user.id, key: user.id, text: user.name }));

export const mapCompaniesToOptions = (companies) => {
  return companies.map((x) => ({ value: x.id, text: x.name }));
};

export const mapTripToDetails = (trip, captains, vessels) => {
  if (!trip) return [];

  const captain = captains?.find((captain) => captain.id === trip.captain);
  const vessel = vessels?.find((vessel) => vessel.id === trip.vessel_id);

  const result = tripDetails.map(({ label, adapter }) => {
    return {
      label,
      data: adapter(trip),
    };
  });

  return !vessel || !captain
    ? result
    : [
        ...result,
        { label: "Regular captain", data: captain?.name ?? data_placeholder },
        { label: "Vessel", data: vessel?.name ?? data_placeholder },
      ];
};

export const getBoundsFromTrip = (trip) => {
  if (!trip?.start_location || !trip?.end_location) {
    return [
      [0, 0],
      [0, 0],
    ];
  }

  return MapUtils.getBoundsFromCoordinatePoints([
    trip.start_location,
    trip.end_location,
  ]);
};

export const mapVesselToDetails = (vessel) => {
  if (!vessel) return [];
  return vesselDetails.map(({ label, value, measurement }) => ({
    label,
    data: `${vessel[value] ?? data_placeholder} ${measurement}`,
  }));
};

export const mapDataToStatistics = (data) => {
  if (!data) return [];
  return statistics.map(({ label, adapter }) => ({
    label,
    data: adapter(data),
  }));
};

export const mapCaptainToDetails = (captain, vessels) => {
  if (!captain) return [];
  return [
    {
      label: "Regular Vessels",
      data:
        vessels
          .filter((v) => v?.company_id === captain.company_id)
          .map((v) => v.name)
          .join(", ") || data_placeholder,
    },
  ];
};

export const findRegularCaptain = (trips, vessel, captains) => {
  if (!trips || !vessel) return data_placeholder;
  const matchedTrips = trips?.filter((trip) => {
    if (!trip?.vessel_id || !vessel?.id) return false;
    return trip?.vessel_id === vessel?.id;
  });
  if (!matchedTrips.length) return data_placeholder;

  const matchedCaptains = matchedTrips
    ?.map((trip) =>
      captains.find((c) => (trip?.captain ? trip.captain === c.id : false)),
    )
    .filter(Boolean)
    .map((c) => c.name);
  const result = matchedCaptains.reduce((acc, val) => {
    acc[val] = (acc[val] || 0) + 1;
    return acc;
  }, {});
  const sorted = Object.entries(result)
    .sort((a, b) => a[1] - b[1])
    .reverse();
  return sorted && sorted[0] ? sorted[0][0] : data_placeholder;
};

export const mapLocationsToOptions = (data) => {
  const countries = data.map((el) => ({ value: el[0], text: el[0] }));
  const regions = data.reduce((acc, el) => {
    const relatedRegions = el[2]
      .split("|")
      .map((el) => ({ value: el, text: el }));
    return [...acc, { country: el[0], relatedRegions }];
  }, []);

  const findRegionByCountry = (country) => {
    if (!country) return [];
    return (
      regions.find((region) => region.country === country)?.relatedRegions ?? []
    );
  };

  return { countries, regions, findRegionByCountry };
};
