import { deleteCaptain } from "data/captains";

import { Modal } from "components/Modal";

export function CaptainDeleteDialog({ id, show, onHide }) {
  const onSubmit = async () => {
    await deleteCaptain(id);
    onHide();
  };

  return (
    <Modal show={show} onClose={onHide}>
      <h3>Delete Captain</h3>
      <p>Are you sure you want to delete captain?</p>
      <button onClick={onSubmit}>Delete</button>
    </Modal>
  );
}
