import React, { useState } from "react";

export const DataContext = React.createContext();

export const DataProvider = (props) => {
  const [pinnedTrips, setPinnedTrips] = useState([]);
  const [ongoingTrips, setOngoingTrips] = useState([]);
  const [todaysTrips, setTodaysTrips] = useState([]);
  const [focusTrips, setFocusTrips] = useState([]);
  const [focusTripData, setFocusTripData] = useState({});
  const [tripData, setTripData] = useState({});
  const [secondData, setSecondData] = useState({});
  const [minute, setMinute] = useState([]);
  const [playback, setPlayback] = useState(null);
  const [dashboardSettings, setDashboardSettings] = useState({
    map: true,
    quality: true,
    performance: true,
    alerts: true,
    forecast: true,
    ranking: true,
    today: true,
    maintenance: true,
    onboarding: true,
    efficiency: true,
    trips: true,
    vessels: true,
    captains: true,
  });

  return (
    <DataContext.Provider
      value={{
        pinnedTrips,
        setPinnedTrips,
        ongoingTrips,
        setOngoingTrips,
        todaysTrips,
        setTodaysTrips,
        focusTrips,
        setFocusTrips,
        focusTripData,
        setFocusTripData,
        tripData,
        setTripData,
        secondData,
        setSecondData,
        minute,
        setMinute,
        playback,
        setPlayback,
        dashboardSettings,
        setDashboardSettings,
      }}
      {...props}
    />
  );
};
