import * as Color from "./Theme";

export const getAverageSOG = (trip) => {
  if (!trip?.avg_sog) return "-";

  return trip.avg_sog.toFixed(1) + "kn";
};

export const getAverageROG = (trip) => {
  if (!trip?.avg_rog) return "-";

  return trip.avg_rog.toFixed(1) + "kn";
};

export const getAvarageSOGoverROG = ({ avg_sog, avg_rog }) => {
  if (Number.isNaN(avg_rog) || Number.isNaN(avg_sog)) return "-";
  if (avg_sog > avg_rog) return "100%";
  const avgRogOverSogInPer = (avg_sog / avg_rog) * 100;
  return avgRogOverSogInPer.toFixed(2) + "%";
};

export const getAverageROGoverSOG = (trip) => {
  if (!trip?.avg_rog_over_sog) return "-";

  return ((1 - trip.avg_rog_over_sog) * 100).toFixed(0) + "%";
};

export const getAverageImpact = (trip) => {
  if (!trip?.avg_impact) return "-";

  return trip.avg_impact.toFixed(1) + "g";
};

export const getPeakImpact = (trip) => {
  if (!trip?.peak_impact) return "-";

  return trip.peak_impact.toFixed(1) + "g";
};

export const getRedImpacts = (trip) => {
  if (!trip?.red_impacts) return 0;

  return trip.red_impacts;
};

export const getNumberOfTrips = (trip) => {
  if (!trip?.number_of_trips) return "-";

  return trip.number_of_trips;
};

export const getCrewComfort = (trip, index) => {
  if (!trip) return "N/A";
  if (index === 1 && !trip?.button_1) return "N/A";
  if (index === 2 && !trip?.button_2) return "N/A";
  if (index === 3 && !trip?.button_3) return "N/A";

  if (index === 1)
    return (
      <p>
        <span style={{ color: Color.green }}>{trip.button_1.g}</span>
        <span style={{ color: Color.yellow, marginLeft: 5 }}>
          {trip.button_1.y}
        </span>
        <span style={{ color: Color.red, marginLeft: 5 }}>
          {trip.button_1.r}
        </span>
      </p>
    );
  if (index === 2)
    return (
      <p>
        <span style={{ color: Color.green }}>{trip.button_2.g}</span>
        <span style={{ color: Color.yellow, marginLeft: 5 }}>
          {trip.button_2.y}
        </span>
        <span style={{ color: Color.red, marginLeft: 5 }}>
          {trip.button_2.r}
        </span>
      </p>
    );

  return (
    <p>
      <span style={{ color: Color.green }}>{trip.button_3.g}</span>
      <span style={{ color: Color.yellow, marginLeft: 5 }}>
        {trip.button_3.y}
      </span>
      <span style={{ color: Color.red, marginLeft: 5 }}>{trip.button_3.r}</span>
    </p>
  );
};
