import React, { useEffect, useContext } from "react";

import { useVesselEditFormHandler } from "../hooks/useVesselEditFromHandler";

import { Modal } from "components/Modal";
import { Input } from "components/Inputs";
import { Select } from "components/Dropdown";

import { AuthContext } from "utils/AuthContext";
import { useGroups } from "hooks/useGroups";
import * as DataAdapters from "utils/DataAdapters";
import { useVessel } from "hooks/useVessels";

let startText = 1979;
let startValue = 1979;
const end = Number.parseInt(new Date().getFullYear());

const years = new Array(end - startText)
  .fill({})
  .map(() => ({ text: ++startText, value: ++startValue }));

export function VesselEditDialog({ id, show, onHide }) {
  const { company } = useContext(AuthContext);
  const groups = useGroups();
  const vessel = useVessel(id);
  const { register, handleSubmit, onSubmit, errors, reset } =
    useVesselEditFormHandler(id, company, onHide);

  let title = id ? "Edit Vessel" : "Add Vessel";

  useEffect(() => {
    reset({
      ...vessel,
      year_built: vessel?.year_built ?? 0,
      group: vessel?.group ?? "",
    });
  }, [id, vessel]);

  const handleClose = () => {
    reset({
      name: null,
      companyId: null,
      max_acc: null,
      max_speed: null,
      button_1_name: null,
      button_2_name: null,
      button_3_name: null,
      manufacturer: null,
      length: null,
      boa: null,
      group: null,
      dis_weight: null,
      fuel_capacity: null,
      top_speed: null,
      cruising_speed: null,
      year_built: null,
    });
    onHide();
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <h3>{title}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          id="name"
          name="name"
          label="Name"
          {...register("name")}
          error={errors?.name}
        />
        <Select
          label="Group"
          id="group"
          name="group"
          prev={groups.find((group) => group.id === vessel?.group)?.name ?? "-"}
          options={DataAdapters.mapGroupsToOptions(groups)}
          error={errors?.group}
          {...register("group")}
        />
        <h3>System Settings</h3>
        <Input
          id="maxAcc"
          name="max_acc"
          label="Impact limit (g force)"
          type="number"
          step="0.1"
          placeholder="3.0g recomended"
          {...register("max_acc")}
          error={errors?.max_acc}
        />
        <Input
          id="maxSpeed"
          name="max_speed"
          label="ROG speed limit (knots)"
          type="number"
          step="0.1"
          placeholder="E.g. 30"
          {...register("max_speed")}
          error={errors?.max_speed}
        />
        {(company?.id === "nwNLRXhWFPi9pIq5tJ0o" ||
          company?.id === "O1uDsl7NMHsxIqxQreI1" ||
          company?.id === "tphy02lEjTuus54299FG") && (
          <>
            <h3>Comfort buttons</h3>
            <Input
              id="buttonBox1"
              name="button_1_name"
              label="Button box 1"
              placeholder="Box 1"
              {...register("button_1_name")}
              error={errors?.comfort}
            />
            <Input
              id="buttonBox2"
              name="button_2_name"
              label="Button box 2"
              placeholder="Box 2"
              {...register("button_2_name")}
              error={errors?.comfort}
            />
            <Input
              id="buttonBox3"
              name="button_3_name"
              label="Button box 3"
              placeholder="Box 3"
              {...register("button_3_name")}
              error={errors?.comfort}
            />
          </>
        )}
        <h3>Specifications</h3>
        <Select
          id="yearBuilt"
          name="year_built"
          label="Year Built"
          prev={vessel?.year_built}
          options={years}
          {...register("year_built")}
          error={errors?.year_built}
        />
        <Input
          id="manufacturer"
          name="manufacturer"
          label="Manufacturer"
          {...register("manufacturer")}
          error={errors?.manufacturer}
        />
        <Input
          id="length"
          name="length"
          label="Length overall (m)"
          type="number"
          step="0.1"
          {...register("length")}
          error={errors?.length}
        />
        <Input
          id="boa"
          name="boa"
          label="Beam overall (m)"
          type="number"
          step="0.1"
          {...register("boa")}
          error={errors?.boa}
        />
        <Input
          id="grossWeight"
          name="dis_weight"
          label="Displacement weight (ton)"
          type="number"
          step="0.1"
          {...register("dis_weight")}
          error={errors?.dis_weight}
        />
        <Input
          id="topSpeed"
          name="top_speed"
          label="Top speed (kn)"
          step="0.1"
          {...register("top_speed")}
          error={errors?.top_speed}
        />
        <Input
          id="cruisingSpeed"
          name="cruising_speed"
          label="Cruising speed (kn)"
          type="number"
          step="0.1"
          {...register("cruising_speed")}
          error={errors?.cruising_speed}
        />
        <Input
          id="fuelCapacity"
          name="fuel_capacity"
          label="Fuel capacity (liters)"
          type="number"
          step="0.1"
          {...register("fuel_capacity")}
          error={errors?.fuel_capacity}
        />
        <input type="submit" value={id ? "Confirm" : "Create"} />
      </form>
    </Modal>
  );
}
