/*******************************
Data
********************************/
import { deleteVessel } from "data/vessels";

/*******************************
UI
********************************/
import { Modal } from "components/Modal";

export function VesselDeleteDialog({ id, show, onHide }) {
  /*******************************
	Delete vessel
	********************************/
  const onSubmit = async () => {
    await deleteVessel(id);
    onHide();
  };

  /*******************************
	Render
	********************************/
  return (
    <Modal show={show} onClose={onHide}>
      <h3>Delete Vessel</h3>
      <p>Are you sure you want to delete vessel?</p>
      <button onClick={onSubmit}>Delete</button>
    </Modal>
  );
}
