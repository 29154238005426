import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { updateTrip } from "data/trips";
import { getCaptains, getAllCaptains } from "data/captains";
import { AuthContext } from "utils/AuthContext";
import { useTrip } from "hooks/useTrips";

import { Modal } from "components/Modal";
import { Select } from "components/Dropdown";

const TripEditSchema = yup.object().shape({
  captain: yup.string().nullable().required(),
});

export function TripsEditDialog({ id, show, onHide }) {
  const { register, handleSubmit, reset } = useForm({
    resolver: yupResolver(TripEditSchema),
  });

  const [captains, setCaptains] = useState([]);
  const trip = useTrip(id);
  const { user, company } = useContext(AuthContext);

  const onSubmit = async (values) => {
    await updateTrip(id, values);
    reset({
      captain: null,
    });
    onHide();
  };

  useEffect(async () => {
    if (user.auth_role === "super") {
      let c = await getAllCaptains();
      setCaptains(c.map((x) => ({ value: x.id, key: x.id, text: x.name })));
    } else {
      let c = await getCaptains(company.id);
      setCaptains(c.map((x) => ({ value: x.id, key: x.id, text: x.name })));
    }
  }, []);

  useEffect(async () => {
    reset({
      captain: trip?.captain,
    });
  }, [id, trip]);

  const handleClose = () => {
    reset({
      captain: null,
    });
    onHide();
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <h3>Edit Trip</h3>
      <form id="tripForm" onSubmit={handleSubmit(onSubmit)}>
        <Select
          id="captain"
          name="captain"
          label="Captain"
          prev={
            captains.find((captain) => captain.value === trip?.captain)?.text ??
            "-"
          }
          options={captains}
          {...register("captain")}
        />
        <input type="submit" value="Edit" />
      </form>
    </Modal>
  );
}
