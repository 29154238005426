import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export const WeatherWidget = ({ link = "", forecast, className = "" }) => {
  return (
    <div className={`weather-widget ${className}`}>
      <span>
        <h3>Forecast</h3>
        {link && (
          <Link to={link}>
            <Icon name="arrow right" style={{ color: "white" }} />
          </Link>
        )}
      </span>
      {forecast &&
        forecast.map((x, ndx) => {
          return (
            <div key={ndx} className="weather-widget-row">
              <Icon style={{ color: x.color }} size="large" name={x.icon} />
              <p style={{ color: "white" }}>{x.time}</p>
              <p style={{ color: "white" }}>{x.wind_speed}</p>
            </div>
          );
        })}
    </div>
  );
};
