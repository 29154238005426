import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { SigninScreen } from "pages/signin/Signin";
import { LoadingScreen } from "components/Loading";
import { NotFoundScreen } from "components/error/404";
import { SettingsScreen } from "pages/settings/Settings";
import { PrivateTemplate, PublicTemplate } from "templates";
import { AuthContext } from "./AuthContext";
import TripsPage from "pages/trips/TripsPage";
import Logout from "pages/signin/Logout";
import CompaniesPage from "pages/companies/CompaniesPage";
import CaptainsPage from "pages/captains/CaptainsPage";
import { ProfileScreen } from "pages/profile";
import UsersPage from "pages/users/UsersPage";
import VesselsPage from "pages/vessels/VesselsPage";
import { LiveConsoleScreen } from "pages/console/LiveConsole";
import { SignUp } from "pages/signup/SignUp";
import { EmailVerification } from "pages/signin/EmailVerification";
import { TripDescriptionPage } from "pages/trips/TripsDescriptionPage";
import { CaptainDescriptionPage } from "pages/captains/CaptainDescriptionPage";
import { VesselDescriptionPage } from "pages/vessels/VesselDescriptionPage";
// import DashboardPage from "pages/dashboard/DashboardPage";

const PrivateRoute = (props) => {
  return props.shouldRender ? <Route {...props} /> : <NotFoundScreen />;
};

export const Routes = () => {
  const { isAuthorized, loadingAuthState, isSuperAdmin, isAdmin, user } =
    useContext(AuthContext);
  const isEmailNotVerified =
    isAuthorized && "emailVerified" in user && !user?.emailVerified;

  if (loadingAuthState) return <LoadingScreen />;

  if (!isAuthorized) {
    return (
      <Switch>
        <Route path="/signin" component={SigninScreen} exact />
        <Route path="/logout" exact component={Logout} />
        <Route path="/signup" component={SignUp} />

        <Redirect to="/signin" />
      </Switch>
    );
  } else if (isAuthorized && isEmailNotVerified) {
    return (
      <Switch>
        <Route path="/">
          <PublicTemplate>
            <EmailVerification />
          </PublicTemplate>
        </Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Redirect from="/signin" to="/" />
        <Redirect from="/signup" to="/" />
        <Route path="/logout" exact component={Logout} />

        <PrivateTemplate>
          <Switch>
            <Route
              path="/trips/description/:id"
              component={TripDescriptionPage}
            />
            <Route
              path="/captain/description/:id"
              component={CaptainDescriptionPage}
            />
            <Route
              path="/vessel/description/:id"
              component={VesselDescriptionPage}
            />
            {/* <Route path="/dashboard" component={DashboardPage} /> */}
            <Route path="/trips" component={TripsPage} />
            <Route path="/vessels" component={VesselsPage} />
            <Route path="/settings" component={SettingsScreen} />
            <Route path="/profile" component={ProfileScreen} />
            <PrivateRoute
              shouldRender={isSuperAdmin}
              path="/companies"
              component={CompaniesPage}
            />
            <PrivateRoute
              shouldRender={isAdmin}
              path="/captains"
              component={CaptainsPage}
            />
            <PrivateRoute
              shouldRender={isAdmin}
              path="/users"
              component={UsersPage}
            />
            <PrivateRoute
              shouldRender={isAdmin}
              path="/"
              exact
              component={LiveConsoleScreen}
            />
            <Redirect exact from="/" to="/trips" />
            <Route path="*">
              <NotFoundScreen />
            </Route>
          </Switch>
        </PrivateTemplate>
      </Switch>
    );
  }
};
