import { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Modal } from "components/Modal";

import { AuthContext } from "utils/AuthContext";
import { getDeviceByCode, registerDevice } from "data/devices";
import * as Color from "utils/Theme";

const VesselLinkSchema = yup.object().shape({
  code: yup
    .string()
    .min(6, "Code must be 6 characters")
    .max(6, "Code must be 6 characters")
    .required("Code is required"),
});

export function VesselLinkingDialog({ id, show, onHide }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(VesselLinkSchema),
  });

  const [noDeviceFound, setNoDeviceFound] = useState(false);
  const { company } = useContext(AuthContext);

  const onSubmit = async (values) => {
    const device = await getDeviceByCode(values.code);
    if (!device) {
      setNoDeviceFound(true);
    } else {
      await registerDevice(device.id, id, company.id);
      handleClose();
    }
  };

  const handleClose = () => {
    setNoDeviceFound(false);
    reset({
      code: null,
    });
    onHide();
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <h3>Link Vessel</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Registration Code</label>
        <input
          {...register("code")}
          type="text"
          placeholder="Type in the code from "
          name="code"
        />
        <p style={{ color: Color.red }}>{errors.code?.message}</p>
        {noDeviceFound && (
          <p style={{ color: Color.red }}>
            Wrong key provided, check if key has changed on your device!
          </p>
        )}
        <input type="submit" value="Link" />
      </form>
    </Modal>
  );
}
