import React from "react";
import { Route } from "react-router-dom";

import { CaptainsScreen } from "./Captain";
import { CaptainEditDialog } from "./modals/CaptainEditDialog";
import { CaptainDeleteDialog } from "./modals/CaptainDeleteDialog";

export default function CaptainsPage() {
  return (
    <div>
      <Route path="/captains/new">
        {({ history, match }) => (
          <CaptainEditDialog
            show={match != null}
            onHide={() => {
              history.push("/captains");
            }}
          />
        )}
      </Route>
      <Route path="/captains/:id/edit">
        {({ history, match }) => (
          <CaptainEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/captains");
            }}
          />
        )}
      </Route>
      <Route path="/captains/:id/delete">
        {({ history, match }) => (
          <CaptainDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/captains");
            }}
          />
        )}
      </Route>
      <CaptainsScreen />
    </div>
  );
}
