const TEST = "test";
const PRODUCTION = "prod";
const DEMO = "demo";

export const CURRENT_BRAND =
  process.env.REACT_APP_BRAND !== "" ? process.env.REACT_APP_BRAND : TEST;

export const TEST_CONFIG = {
  apiKey: "AIzaSyAgkvLtwmNcPeqatfbHJ-lJ42Wd7TnJW3A",
  authDomain: "mk2-test.firebaseapp.com",
  projectId: "mk2-test",
  storageBucket: "mk2-test.appspot.com",
  messagingSenderId: "322048751601",
  appId: "1:322048751601:web:26e17e4e929eb27ebab650",
  measurementId: "G-390JTKQHCM",
};

export const PROD_CONFIG = {
  apiKey: "AIzaSyB5ooy6-zPmodmXnCNr7FFKPzbcdLoPCG0",
  authDomain: "mk2-prod.firebaseapp.com",
  projectId: "mk2-prod",
  storageBucket: "mk2-prod.appspot.com",
  messagingSenderId: "1013087376822",
  appId: "1:1013087376822:web:cff2f5e9481fb3c576dd17",
  measurementId: "G-5V0KMX4Y6Q",
};

export const DEMO_CONFIG = {
  apiKey: "AIzaSyB6OLgq11wvLXdV6KgF0ZhHGHyNOcHUEqw",
  authDomain: "mk2-demo.firebaseapp.com",
  projectId: "mk2-demo",
  storageBucket: "mk2-demo.appspot.com",
  messagingSenderId: "810227750699",
  appId: "1:810227750699:web:27effd62388d83130f976e",
  measurementId: "G-6RRY2VXT4X",
};

export const PRODUCTION_BRAND = {
  key: PRODUCTION,
  name: "MK2",
};

export const TEST_BRAND = {
  key: TEST,
  name: "MK2 Test",
};

export const DEMO_BRAND = {
  key: DEMO,
  name: "MK2 Demo",
};

export function getBrand() {
  let brand = {};

  if (CURRENT_BRAND === PRODUCTION_BRAND.key) {
    brand = PRODUCTION_BRAND;
    brand.firebase = PROD_CONFIG;
  } else if (CURRENT_BRAND === TEST_BRAND.key) {
    brand = TEST_BRAND;
    brand.firebase = TEST_CONFIG;
  } else if (CURRENT_BRAND === DEMO_BRAND.key) {
    brand = DEMO_BRAND;
    brand.firebase = DEMO_CONFIG;
  } else {
    brand = PRODUCTION_BRAND;
    brand.firebase = PROD_CONFIG;
  }

  return brand;
}
