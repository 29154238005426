import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { DataWidget } from "components/Widgets/DataWidget";
// import { RadarChart } from "components/RadarChart";

import * as DataAdapters from "utils/DataAdapters";

import { useCaptain } from "hooks/useCaptains";
import { useVessel } from "hooks/useVessels";
import { useTripsWithConsole } from "hooks/useTripsWithConsole";
import { ExpandedRowLogs } from "components/Logs";

import { StaticMap } from "components/StaticMap";
import { useTripData } from "hooks/useTripData";

export const TripsExpandedRowData = ({ onExpand, data }) => {
  const captain = useCaptain(data?.captain);
  const vessel = useVessel(data?.vessel_id);
  const history = useHistory();
  const tripData = useTripData(data.id);

  const { showOnMap } = useTripsWithConsole(data, () => history.push("/"));

  return (
    <tr className="expanded">
      <td colSpan={9}>
        <div
          style={{
            cursor: "pointer",
            textAlign: "right",
            marginBottom: "6px",
          }}
        >
          <Icon onClick={onExpand(-1)} name="delete" />
        </div>
        <div className="expanded__grid">
          <div className="expanded__grid-row">
            <div className="expanded__grid-column">
              <DataWidget
                data={[
                  { label: "Captain", data: captain?.name ?? "-" },
                  { label: "Vessel", data: vessel?.name ?? "-" },
                ]}
                className="expanded__grid-widget expanded__grid-widget-small"
              />
              <DataWidget
                data={DataAdapters.mapTripToDetails(data)}
                className="expanded__grid-widget expanded__grid-widget-medium"
              />
              <div className="expanded__grid-column">
                <ExpandedRowLogs
                  data={data}
                  captain={captain}
                  vessel={vessel}
                  hasIssues
                  hasLogs
                  canAdd
                />
              </div>
              <div className="expanded__grid-column"></div>
            </div>
            <div className="expanded__grid-column">
              <DataWidget
                label={"Statistics"}
                data={DataAdapters.mapDataToStatistics(data)}
                className="expanded__grid-widget"
              />
            </div>
            <div className="expanded__grid-column-wide">
              <div className="expanded__grid-map">
                {/* <RadarChart data={data} drawLabels width={250} height={195} /> */}
                {tripData.length > 0 && (
                  <div style={{ height: "250px", width: "300px" }}>
                    <StaticMap tripData={tripData} />
                  </div>
                )}
              </div>
              <div className="expanded__grid-column">
                <div className="expanded__grid-button-group justify-between">
                  <button onClick={showOnMap} className="expanded__grid-button">
                    Show on map
                  </button>
                  {/* <button
                  onClick={() => history.push(`trips/description/${data?.id}`)}
                  className="expanded__grid-button"
                >
                  View more
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
