import { Fragment, useState } from "react";

export const Table = ({
  headings = [],
  data = [],
  expandableData = [],
  expandable = false,
  ExpandedRowContent,
}) => {
  const [expandedRow, setExpandedRow] = useState(-1);

  const handleExpand = (ndx) => () => {
    if (expandedRow === ndx) setExpandedRow(-1);
    else setExpandedRow(ndx);
  };

  return (
    <table className="table">
      <tbody>
        <tr>
          {headings.map((heading) => (
            <th key={heading}>{heading}</th>
          ))}
        </tr>
        {data.map((item, ndx) => (
          <Fragment key={ndx}>
            <tr onClick={expandable ? handleExpand(ndx) : undefined}>
              {item.map((value, i) => (
                <td key={i + ndx}>{value}</td>
              ))}
            </tr>
            {expandable && expandedRow === ndx && expandedRow !== -1 && (
              <ExpandedRowContent
                onExpand={handleExpand}
                data={expandableData[ndx]}
                expandedIndex={expandedRow}
              />
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};
