import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import firebase from "utils/Firebase";

const ChangeEmailSchema = Yup.object().shape({
  email: Yup.string().email(),
  password: Yup.string(),
  newPassword: Yup.string(),
});

export const useChangeEmail = (user) => {
  const [emailEditable, setEmailEditable] = useState(false);
  const [changeAttempt, setChangeAttempt] = useState("");
  const { register, handleSubmit, reset } = useForm({
    resolver: yupResolver(ChangeEmailSchema),
  });

  const isEditingEmail = changeAttempt === "email";
  const isEditingPassword = changeAttempt === "password";

  const handleChangeEmail = async (values) => {
    await user.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(user.email, values.password),
    );
    await user.updateEmail(values.email);
    await user.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(values.email, values.password),
    );
    setChangeAttempt("");
    setEmailEditable(false);
    reset();
  };

  const handleChangePassword = async (values) => {
    await user.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(user.email, values.password),
    );
    await user.updatePassword(values.newPassword);
    await user.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(
        user.email,
        values.newPassword,
      ),
    );
    setChangeAttempt("");
    reset();
  };

  const handleChangeAttempt = (attempt) => () => {
    setChangeAttempt(attempt);
  };

  useEffect(() => {
    if (changeAttempt !== "email") setEmailEditable(false);
  }, [changeAttempt, setEmailEditable]);

  return {
    emailEditable,
    register,
    handleSubmit,
    isEditingPassword,
    isEditingEmail,
    handleChangeEmail,
    handleChangePassword,
    setEmailEditable,
    handleChangeAttempt,
  };
};
