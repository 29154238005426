import logo from "../assets/axopar.png";

export const PublicTemplate = ({ title, children }) => (
  <div className="public">
    <img src={logo} width={300} />
    <h2>{title}</h2>
    {children ? (
      <div className="public__content">{children}</div>
    ) : (
      <div style={{ height: 30 }} />
    )}
    <p>Hefring ehf. &copy; 2022</p>
  </div>
);
