import { useState, useEffect } from "react";

import { getTripData } from "data/trips";
import { useTrips } from "./useTrips";

export const useChart = (id) => {
  const [tripDataSlice, setTripDataSlice] = useState({ left: 0, right: 60 });
  const [tripData, setTripData] = useState([]);
  const trips = useTrips();

  useEffect(() => {
    if (id) {
      (async () => {
        const data = await getTripData(id);
        setTripData(data);
      })();
    }
    setTripDataSlice({ left: 0, right: 60 });
  }, [id]);

  const onClickLeft = () => {
    setTripDataSlice((current) => ({
      ...current,
      ["left"]: Math.max(current.left - 1, 0),
      ["right"]: Math.max(current.right - 1, 60),
    }));
  };

  const onClickRight = () => {
    setTripDataSlice((current) => ({
      ...current,
      ["left"]: Math.min(current.left + 1, trips ? trips.length - 60 : 0),
      ["right"]: Math.min(current.right + 1, trips ? trips?.length : 0),
    }));
  };

  return {
    tripData,
    right: tripDataSlice.right,
    left: tripDataSlice.left,
    tripDataSlice,
    onClickLeft,
    onClickRight,
  };
};
