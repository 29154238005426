import { useEffect, useRef, useState } from "react";
import * as Color from "../../utils/Theme";
import * as draw from "../../utils/chartUtils/Draw";
import * as d3 from "d3";

let startText = 2019;
let startValue = 2019;
const end = Number.parseInt(new Date().getFullYear());

const years = new Array(end - startText)
  .fill({})
  .map(() => ({ text: ++startText, value: ++startValue }));

export const Heatmap = ({ data, aspectRatio }) => {
  const ref = useRef(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const margin = { top: 25, right: 10, bottom: 5, left: 55 };

  useEffect(() => {
    const width = ref.current.offsetWidth - margin.left - margin.right;
    const height = width / aspectRatio;
    const cellSize = (width - margin.left - margin.right) / 53;
    const labelColor = "rgba(255, 255, 255, 0.9)";
    const dataInYear = data.filter(
      (date) => date.date.substring(7) === year.toString().substring(3),
    );
    const maxTrips = Math.max.apply(
      Math,
      dataInYear.map((date) => {
        return date.trips;
      }),
    );
    const color = d3.scaleQuantize().domain([0, maxTrips]).range(Color.heatmap);

    const chart = draw.chart(ref, width, height, margin);
    const tooltip = draw.tooltip(ref, "heatmap-tooltip");

    chart.data(d3.range(year, year + 1));

    draw.cells(chart, data, color, cellSize, margin, tooltip);
    draw.xLabelsHeatmap(chart, cellSize, margin, labelColor, year);
    draw.yLabelsHeatmap(chart, cellSize, margin, labelColor);

    return () => {
      chart.remove();
      tooltip.remove();
    };
  }, [data, year]);

  return (
    <div ref={ref} className="heatmap">
      <select
        className="heatmap__year"
        onChange={(e) => setYear(Number.parseInt(e.target.value))}
        defaultValue={year}
      >
        {years?.map(({ text, value }) => (
          <option value={value} key={value}>
            {text}
          </option>
        ))}
      </select>
    </div>
  );
};
