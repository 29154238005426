import { useState } from "react";
import { Icon } from "semantic-ui-react";
import { Modal } from "components/Modal";
import { Issue } from "./index";

import { useIssues } from "hooks/useIssues";

const IssuesModal = ({
  data,
  show,
  onClose,
  captain,
  vessel,
  param,
  hasAddButton,
}) => {
  const issues = useIssues(data?.id, param);
  const [canAdd, setCanAdd] = useState(false);

  const closeModal = () => {
    setCanAdd(false);
    onClose();
  };

  return (
    <Modal show={show} onClose={closeModal}>
      <div className="issue-modal">
        <div className="issue-modal__header">
          <span className="issue-modal__header-label">Issues</span>
          {hasAddButton && (
            <Icon onClick={() => setCanAdd((prev) => !prev)} name="add" />
          )}
        </div>
        <div className="issue-modal__body">
          {issues?.map((issue) => (
            <Issue
              key={issue.id}
              id={data?.id}
              data={issue}
              captain={captain}
              vessel={vessel}
            />
          ))}
          {!issues?.length && !canAdd && <h3>No issue to report</h3>}
        </div>
        {canAdd && <Issue id={data?.id} captain={captain} vessel={vessel} />}
      </div>
    </Modal>
  );
};

export default IssuesModal;
