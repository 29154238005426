import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { addLog } from "data/trips";
import { createIssue, editIssue } from "data/issues";
import { useEffect } from "react";

const validationSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  text: yup.string().required("Content is required"),
});

export const useActivityFormHandler = (
  status,
  id,
  data,
  vessel,
  captain,
  onDone = null,
) => {
  const {
    handleSubmit,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset({
      title: data?.title,
      text: data?.text,
    });
  }, [data]);

  const onSubmit = handleSubmit(async (values) => {
    if (status === "issue" && data) {
      await editIssue({ ...data, title: values.title, text: values.text });
    }
    if (status === "log") {
      await addLog(id, {
        ...values,
        vessel_id: vessel?.id ?? "",
        captain_id: captain?.id ?? "",
        date: Date.now(),
      });
    }
    if (status === "issue" && !data) {
      await createIssue(id, {
        ...values,
        vessel_id: vessel?.id ?? "",
        captain_id: captain?.id ?? "",
        date: Date.now(),
      });
    }
    reset({
      title: null,
      text: null,
      date: null,
    });
    onDone && onDone();
  });

  return { getValues, register, reset, errors, onSubmit };
};
