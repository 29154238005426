import { useState, useEffect, useContext } from "react";

import { AuthContext } from "utils/AuthContext";
import {
  getVesselsSubscription,
  getAllVesselsSubscription,
  getVessel,
} from "data/vessels";

export const useVessels = () => {
  const [vessels, setVessels] = useState([]);
  const { user, company } = useContext(AuthContext);
  const isSuper = user.auth_role === "super";

  let unsubscribeToVessels;

  useEffect(() => {
    (async () => {
      await getData();
    })();

    return () => {
      if (unsubscribeToVessels) unsubscribeToVessels();
    };
  }, []);

  const getData = async () => {
    if (isSuper) {
      unsubscribeToVessels = await getAllVesselsSubscription((vessels) => {
        setVessels(vessels);
      });
    } else {
      unsubscribeToVessels = await getVesselsSubscription(
        company.id,
        (vessels) => {
          setVessels(vessels);
        },
      );
    }
  };

  return vessels;
};

export const useVessel = (id) => {
  const [vessel, setVessel] = useState(null);

  useEffect(async () => {
    let vessel;
    if (id) {
      vessel = await getData();
    }
    setVessel(vessel);
  }, [id]);

  const getData = async () => {
    return await getVessel(id);
  };

  return vessel;
};
