import { Button, Form, Icon } from "semantic-ui-react";
import { useChangePhone } from "./hooks/useChangePhone";

export const ChangePhoneNumber = ({ user }) => {
  const {
    handleChangePhone,
    isEditingPhone,
    phoneNumber,
    setPhoneNumber,
    setIsEditingPhone,
    isEnteringCode,
    confirmCode,
    setConfirmCode,
    handleProceedToEnterCode,
  } = useChangePhone(user);
  return (
    <>
      <h3>Phone number authentication credentials</h3>
      <div className="settings__changer-container">
        <Form onSubmit={handleChangePhone}>
          <label>Phone number</label>
          <Form.Field className="settings__field">
            <input
              disabled={!isEditingPhone}
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <Icon
              onClick={() => setIsEditingPhone(!isEditingPhone)}
              className="settings__edit"
              name="edit"
            />
          </Form.Field>
          {isEnteringCode ? (
            <>
              <label>Verification code</label>
              <Form.Field className="settings__field">
                <input
                  type="number"
                  maxLength={6}
                  value={confirmCode}
                  onChange={(e) => setConfirmCode(e.target.value)}
                />
              </Form.Field>
              <Button primary type="submit">
                Confirm
              </Button>
            </>
          ) : (
            <Button
              onClick={handleProceedToEnterCode}
              disabled={!isEditingPhone}
              type="button"
              primary
            >
              Change
            </Button>
          )}
        </Form>
        <div id="recaptcha-container" style={{}} />
      </div>
    </>
  );
};
