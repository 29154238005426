import { useEffect } from "react";

import { PublicTemplate } from "templates";

import firebase from "utils/Firebase";
import { EmailSignForm } from "pages/signup/EmailSignForm";
import { Tab } from "components/Tabs";
import { SignInPhone } from "./SignInPhone";

export const SigninScreen = () => {
  const onEmailAuth = async (data) => {
    await firebase.auth().signInWithEmailAndPassword(data.email, data.password);
  };

  useEffect(() => {
    window.recaptchaVerifier = null;
  }, []);

  return (
    <PublicTemplate title="Sign in">
      <Tab.Container>
        <Tab.Item label="Phone">
          <SignInPhone />
        </Tab.Item>
        <Tab.Item label="Email">
          <EmailSignForm isSignIn onSubmitExternal={onEmailAuth} />
        </Tab.Item>
      </Tab.Container>
    </PublicTemplate>
  );
};
