import { useContext, useEffect, useState } from "react";

import {
  getTripsSubscription,
  getAllTripsSubscription,
  getTrip,
} from "data/trips";
import { AuthContext } from "utils/AuthContext";

export const useTrips = () => {
  const [trips, setTrips] = useState([]);
  const { company, user } = useContext(AuthContext);
  const isSuper = user.auth_role === "super";

  let unsubscribeToTrips;

  useEffect(() => {
    (async () => {
      await getData();
    })();

    return () => {
      if (unsubscribeToTrips) unsubscribeToTrips();
    };
  }, [unsubscribeToTrips]);

  const getData = async () => {
    if (isSuper) {
      unsubscribeToTrips = await getAllTripsSubscription((trips) => {
        setTrips(trips);
      });
    } else {
      unsubscribeToTrips = await getTripsSubscription(company.id, (trips) => {
        setTrips(trips);
      });
    }
  };

  return trips;
};

export const useTrip = (id) => {
  const [trip, setTrip] = useState(null);

  useEffect(async () => {
    let trip;
    if (id) {
      trip = await getData();
    }
    setTrip(trip);
  }, [id]);

  const getData = async () => {
    return await getTrip(id);
  };

  return trip;
};
