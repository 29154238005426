import { Button } from "semantic-ui-react";

import firebase from "utils/Firebase";

export const EmailVerification = () => {
  const handleSendEmail = () => {
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() => {
        firebase.auth().signOut();
      });
  };

  return (
    <div>
      <p>You need to verify your email in order to use the Console</p>
      <p>
        Send a verification email to the {firebase.auth().currentUser.email} and
        login into your account again
      </p>
      <Button primary fluid onClick={handleSendEmail}>
        Send
      </Button>
    </div>
  );
};
