import { EditableField } from "components/EditableField";
import { updateUser } from "data/users";
import { useContext } from "react";

import { AuthContext } from "utils/AuthContext";

export const ProfileScreen = () => {
  const { user, company } = useContext(AuthContext);

  let authorization =
    user.auth_role === "super" ? "Super admin" : "Administrator";
  let organizationRoles = user.org_roles
    ?.map((x) => {
      if (x === "captain") return "Captain";
      if (x === "fleetManager") return "Fleet manager";
    })
    .join(", ");

  const handleSaveName = async (name) => {
    await updateUser(user.id, { name });
  };

  return (
    <div className="container">
      <h1 className="text white">Profile</h1>
      <div className="profile">
        <div>
          <EditableField
            className="profile__field"
            label="Name"
            data={user.name}
            onSave={handleSaveName}
          />
          <div className="profile__field">
            <h3>Company</h3>
            <p>{company.name}</p>
          </div>
          {user.email && (
            <div className="profile__field">
              <h3>Email</h3>
              <p>{user.email}</p>
            </div>
          )}
          <div className="profile__field">
            <h3>Phone Number</h3>
            <p>{user.phone_number}</p>
          </div>
          <div className="profile__field">
            <h3>Organization roles</h3>
            <p>{organizationRoles}</p>
          </div>
          {(user.auth_role === "super" || user.auth_role === "admin") && (
            <div className="profile__field">
              <h3>Authorization</h3>
              <p>{authorization}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
