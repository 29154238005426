import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { LoadingScreen } from "components/Loading";

import firebase from "utils/Firebase";
import { AuthContext } from "utils/AuthContext";

export default function Logout() {
  const { isAuthorized } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthorized) {
      firebase.auth().signOut();
    }
  }, [isAuthorized]);

  return isAuthorized ? <LoadingScreen /> : <Redirect exact to="/signin" />;
}
