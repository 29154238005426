import firebase from "firebase";

import { db } from "utils/Firebase";

const COMPANIES_COLLECTION = db.collection("companies");

export const createCompany = async (values) => {
  return new Promise((resolve, reject) => {
    const companiesRef = COMPANIES_COLLECTION.doc();
    companiesRef
      .set({
        ...values,
        id: companiesRef.id,
        created_at: firebase.firestore.Timestamp.now(),
        visible: true,
      })
      .then(() => {
        companiesRef.onSnapshot((snapshot) => {
          resolve(snapshot.id);
        });
      })
      .catch((e) => reject(e));
  });
};

export const updateCompany = async (id, values) => {
  await COMPANIES_COLLECTION.doc(id).set(values, { merge: true });
};

export const updateCompanyLastLogin = async (companyId) => {
  await COMPANIES_COLLECTION.doc(companyId).update({
    last_login: firebase.firestore.Timestamp.now(),
  });
};

export const getCompany = async (id) => {
  const companySnap = await COMPANIES_COLLECTION.doc(id).get();
  let company = companySnap.data();
  company.id = companySnap.id;
  return company;
};

export const getCompanies = async () => {
  const companies = await COMPANIES_COLLECTION.where(
    "visible",
    "==",
    true,
  ).get();
  return companies.docs.map((v) => {
    let data = v.data();
    data.id = v.id;
    return data;
  });
};

export const getCompaniesSubscription = (callback) => {
  return COMPANIES_COLLECTION.where("visible", "==", true).onSnapshot(
    (companiesSnap) => {
      callback(
        companiesSnap.docs.map((v) => {
          let company = v.data();
          company.id = v.id;
          return company;
        }),
      );
    },
  );
};

export const getCompanySubscription = (id, callback) => {
  return COMPANIES_COLLECTION.doc(id).onSnapshot((companySnap) => {
    let company = companySnap.data();
    company.id = companySnap.id;
    callback(company);
  });
};

export const deleteCompany = (id) => {
  COMPANIES_COLLECTION.doc(id).update({ visible: false });
};
