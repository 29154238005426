import React from "react";
import { Route } from "react-router-dom";

import { UsersScreen } from "./Users";
import { UserEditDialog } from "./modals/UserEditDialog";
import { UserDeleteDialog } from "./modals/UsersDeleteDialog";

export default function UsersPage() {
  return (
    <div>
      <Route path="/users/new">
        {({ history, match }) => (
          <UserEditDialog
            show={match != null}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>
      <Route path="/users/:id/edit">
        {({ history, match }) => (
          <UserEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>
      <Route path="/users/:id/delete">
        {({ history, match }) => (
          <UserDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>
      <UsersScreen />
    </div>
  );
}
