import { useState, useEffect } from "react";
import { Button, Form } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { createCompany } from "data/companies";
import { createUser, createUserManually } from "data/users";

export const CompanyProfile = ({ userInfo }) => {
  const { handleSubmit } = useForm();
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (!userInfo.email) history.push("/signup");
  }, []);

  const onSubmit = async () => {
    const emailData =
      userInfo.email && userInfo.password
        ? { email: userInfo.email, password: userInfo.password }
        : {};
    const companyId = await createCompany(
      userInfo.companyName,
      userInfo.email,
      country,
      region,
    );
    const { data: userId } = await createUser({
      name: userInfo.firstName + " " + userInfo.lastName,
      phoneNumber: userInfo.phoneNumber,
      ...emailData,
    });
    await createUserManually({
      organizationRoles: ["fleetManager", "captain"],
      companyId,
      admin: true,
      name: userInfo.firstName + " " + userInfo.lastName,
      phoneNumber: userInfo.phoneNumber,
      id: userId,
    });
    history.push("/");
  };

  return (
    <div>
      <h1>Company Profile</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <label>Company Name</label>
          <input
            disabled={!!userInfo.companyName}
            value={userInfo.companyName}
            name="companyName"
          />
        </Form.Field>
        <Form.Field>
          <label>Contact</label>
          <input
            disabled={!!userInfo.email}
            value={userInfo.email}
            name="contact"
          />
        </Form.Field>
        <CountryDropdown value={country} onChange={setCountry} />
        <RegionDropdown
          style={{ margin: "15px 0" }}
          country={country}
          value={region}
          onChange={setRegion}
        />
        <Button fluid primary type="submit">
          Create
        </Button>
      </Form>
    </div>
  );
};
