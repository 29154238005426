import { deleteTrip } from "data/trips";

import { Modal } from "components/Modal";

export function TripDeleteDialog({ id, show, onHide }) {
  const onSubmit = async () => {
    await deleteTrip(id);
    onHide();
  };

  return (
    <Modal show={show} onClose={onHide}>
      <h3>Delete Trip</h3>
      <p>Are you sure you want to delete this trip?</p>
      <button onClick={onSubmit}>Delete</button>
    </Modal>
  );
}
