import { useGroup } from "hooks/useGroups";
import { useUser } from "hooks/useUsers";

export const useCaptainDetails = (data) => {
  const user = useUser(data?.user);
  const group = useGroup(data?.group);

  return [
    { label: "Name", data: data?.name },
    { label: "User Name", data: user?.name ?? "-" },
    { label: "Group Name", data: group?.name ?? "-" },
  ];
};
