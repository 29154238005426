import { useContext } from "react";

import { TabsContext } from "./Tabs";

export const TabItem = ({ label, className = "" }) => {
  const tabs = useContext(TabsContext);

  const isActiveTab = label === tabs.displayedTab;

  const handleChooseTab = () => {
    tabs.setDisplayedTab(label);
  };

  return (
    <div
      className={`tabs__item ${isActiveTab ? "active" : ""} ${className}`}
      onClick={handleChooseTab}
    >
      <h2>{label}</h2>
    </div>
  );
};
