import { useContext } from "react";

import { AuthContext } from "utils/AuthContext";
import { Captains } from "utils/PagesContent";
import { useCaptains } from "hooks/useCaptains";
import { useLoading } from "hooks/useLoading";

export const useCaptainsData = () => {
  const { user } = useContext(AuthContext);
  const captains = useCaptains();
  const loading = useLoading(captains);
  const isSuper = user.auth_role === "super";

  const captainsData = Captains.data(captains, isSuper);

  const headings = Captains.headings(isSuper);

  const expandedRowData = captains;

  return { captainsData, headings, loading, expandedRowData };
};
