import { MapContainer, TileLayer, Marker, LayersControl } from "react-leaflet";
import FullScreen from "react-leaflet-fullscreen-plugin";
import { Loader } from "semantic-ui-react";
import "leaflet/dist/leaflet.css";

import * as MapUtils from "utils/MapUtils";
import { mapboxAccessToken } from "utils/Constants";
import { ColorLine } from "./ColorLine";
import { useVesselMap } from "./useVesselMap";
import { boatMarker, impactMarker, sogMarker } from "./mapMarkers";
import circle from "../../assets/markers/circle.png";
import boat from "../../assets/markers/boat.png";

export const VesselMap = ({
  ongoingTrips,
  focusTripData,
  secondData,
  setMapRef,
  onPolylineClick,
  playback,
  handleZoom,
  onSliceHover,
}) => {
  const { BaseLayer, Overlay } = LayersControl;

  const { focusTripMaxImpactObj, focusTripMaxSogObj } =
    useVesselMap(focusTripData);

  return (
    <MapContainer
      key="1"
      bounds={MapUtils.getBoundsFromCoordinatePoints(
        ongoingTrips
          ?.filter((x) => x?.end_location)
          .map((x) => x?.end_location),
      )}
      style={{ height: "100vh", width: "100wh" }}
      whenCreated={setMapRef}
    >
      <LayersControl position="bottomleft">
        <BaseLayer checked name="Light">
          <TileLayer
            key="2"
            attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url={
              "https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=" +
              mapboxAccessToken
            }
          ></TileLayer>
        </BaseLayer>
        <BaseLayer name="Satellite">
          <TileLayer
            key="2"
            attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url={
              "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=" +
              mapboxAccessToken
            }
          ></TileLayer>
        </BaseLayer>
        <Overlay name="Seamarks">
          <TileLayer
            attribution='&copy; <a href="http://www.openseamap.org">OpenSeaMap</a>'
            url={"https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"}
          ></TileLayer>
        </Overlay>
      </LayersControl>
      <FullScreen position="topright" />
      <div className="console__legend">
        <div className="d-flex" style={{ marginBottom: "6px" }}>
          <img
            src={circle}
            className="impact-marker"
            style={{ marginRight: "6px" }}
            width={16}
            height={16}
          ></img>
          <p>Max Impact</p>
        </div>
        <div className="d-flex" style={{ marginBottom: "6px" }}>
          <img
            src={circle}
            className="sog-marker"
            style={{ marginRight: "6px" }}
            width={16}
            height={16}
          ></img>
          <p>Max SOG</p>
        </div>
        <div className="d-flex" style={{ marginBottom: "6px" }}>
          <img
            src={boat}
            className="boat-marker"
            style={{ marginRight: "6px" }}
            width={16}
            height={16}
          ></img>
          <p>Vessel Location</p>
        </div>
      </div>
      {focusTripData && (
        <ColorLine
          focusTripData={focusTripData}
          secondData={secondData}
          onPolylineClick={onPolylineClick}
          playback={playback}
          handleZoom={handleZoom}
          onSliceHover={onSliceHover}
        />
      )}
      {ongoingTrips
        ?.filter((x) => x?.end_location)
        .map((x) => {
          return (
            <Marker
              key={x.id}
              position={[x?.end_location.lat, x?.end_location.lon]}
              icon={boatMarker}
              eventHandlers={{
                click: () => {
                  /** TODO investigate this is not defined! */
                  // onVesselClick(x);
                },
              }}
            />
          );
        })}
      {focusTripMaxImpactObj && focusTripMaxSogObj && (
        <>
          <Marker
            position={[
              focusTripMaxImpactObj.latitudes[0],
              focusTripMaxImpactObj.longitudes[0],
            ]}
            icon={impactMarker}
          />
          <Marker
            position={[
              focusTripMaxSogObj.latitudes[0],
              focusTripMaxSogObj.longitudes[0],
            ]}
            icon={sogMarker}
          />
        </>
      )}
    </MapContainer>
  );
};

export const VesselMapLoader = () => {
  return (
    <div style={{ backgroundColor: "#111", height: "100%", width: "100%" }}>
      <Loader
        style={{
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%,-50%)",
        }}
        size="small"
        active
        inline="centered"
      />
    </div>
  );
};
