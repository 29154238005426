import { useState, useEffect } from "react";

import { getCompaniesSubscription, getCompany } from "data/companies";

export const useCompanies = () => {
  const [companies, setCompanies] = useState([]);

  let unsubscribeToCompanies;

  useEffect(() => {
    (async () => {
      await getData();
    })();

    return () => {
      if (unsubscribeToCompanies) unsubscribeToCompanies();
    };
  }, []);

  const getData = async () => {
    unsubscribeToCompanies = getCompaniesSubscription((companies) => {
      setCompanies(companies);
    });
  };

  return companies;
};

export const useCompany = (id) => {
  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        await getData();
      })();
    }
  }, [id]);

  const getData = async () => {
    const company = await getCompany(id);
    setCompany(company);
  };

  return company;
};
