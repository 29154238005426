import { ChartWidget } from "../ChartWidget";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export const StatisticsWidget = ({ item, hasLink = true }) => {
  return (
    <div className="statistics-widget">
      {hasLink && (
        <span>
          <h3>Quality</h3>
          <Link>
            <Icon name="arrow right" style={{ color: "white" }} />
          </Link>
        </span>
      )}
      <div className="statistics-widget-grid">
        <div className="statistics-row">
          <p>Speed</p>
          <h2>98%</h2>
        </div>
        <ChartWidget
          style={{ flex: 1 }}
          item={item}
          type={"speed"}
          aspectRatio={5.5}
        />
        <div className="statistics-row">
          <p>Fuel</p>
          <h2>96%</h2>
        </div>
        <ChartWidget
          style={{ flex: 1 }}
          item={item}
          type={"fuel"}
          aspectRatio={5.5}
        />
      </div>
    </div>
  );
};
