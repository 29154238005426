import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";

import * as DateUtils from "utils/DateUtils";
import { useActivityFormHandler } from "hooks/useActivityFormHandler";

import { Modal } from "./index";
import { Input, Textarea } from "../Inputs";

const ActivityModal = ({
  show,
  onClose,
  captain = "",
  vessel = "",
  data = null,
  status,
  id,
  disabled,
}) => {
  const [canEdit, setCanEdit] = useState(false);
  const { register, errors, onSubmit } = useActivityFormHandler(
    status,
    id,
    data,
    vessel,
    captain,
    () => {
      onClose();
      setCanEdit(false);
    },
  );

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose();
        setCanEdit(false);
      }}
    >
      <div className="activity">
        {disabled && (
          <Icon
            name="edit"
            className={`cursor-pointer text ${canEdit ? "blue" : "black"}`}
            onClick={() => setCanEdit((prev) => !prev)}
          />
        )}
        <div className="activity__header">
          {captain && (
            <span className="activity__header-element">
              {captain?.name || ""}
            </span>
          )}
          {vessel && (
            <span className="activity__header-element">
              {vessel?.name || ""}
            </span>
          )}
          <span className="activity__header-element">
            {DateUtils.prettyDate(data?.date)}
          </span>
        </div>
        <form className="activity__form" id="actifityForm" onSubmit={onSubmit}>
          <h3>Report</h3>
          <Input
            label={!disabled || canEdit ? "Title" : ""}
            id="title"
            name="title"
            defaultValue={data?.title}
            error={errors.title}
            disabled={disabled && !canEdit}
            {...register("title")}
          />
          <Textarea
            label={!disabled || canEdit ? "Text" : ""}
            id="text"
            name="text"
            defaultValue={data?.text}
            error={errors.text}
            disabled={disabled && !canEdit}
            {...register("text")}
          />
          {(!disabled || canEdit) && <Button type="submit">Submit</Button>}
        </form>
      </div>
    </Modal>
  );
};

export default ActivityModal;
