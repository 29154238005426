import { Icon } from "semantic-ui-react";

import { Chart } from "components/Chart";
import * as convertData from "utils/chartUtils/ConvertData";
import moment from "moment";
import { ChartProvider } from "components/Chart/ChartContext";
import { useEffect, useState } from "react";

export const LiveConsoleChart = ({
  minute,
  vessel,
  toggleShowChart,
  showFullTrip,
  handleChartClick,
  playback,
}) => {
  const [chartSlice, setChartSlice] = useState(1);

  useEffect(() => {
    let interval;
    if (playback) {
      interval = setInterval(() => {
        setChartSlice(chartSlice < minute.length ? chartSlice + 1 : 1);
      }, 1000);
    }
    return () => interval && clearInterval(interval);
  }, [playback, chartSlice]);

  return (
    <div className="console-container-chart">
      <div style={{ color: "white", float: "right" }}>
        <Icon onClick={toggleShowChart} name="delete" />
      </div>
      <div>
        {minute.length > 0 && (
          <ChartProvider>
            <Chart
              title={
                showFullTrip
                  ? `${vessel.name} ${moment(minute[0].time.toDate())
                      .format("DD/MM HH:mm")
                      .toString()} -
                   ${moment(minute[minute.length - 1].time.toDate())
                     .format("DD/MM HH:mm")
                     .toString()}`
                  : `${vessel.name} ${moment(minute[0].time.toDate())
                      .format("HH:mm:ss")
                      .toString()} - 
                   ${moment(minute[minute.length - 1].time.toDate())
                     .format("HH:mm:ss")
                     .toString()}`
              }
              data={
                showFullTrip
                  ? convertData.convertTripDataMinutesToChartData(
                      playback ? minute.slice(0, chartSlice + 1) : minute,
                      vessel,
                    )
                  : convertData.convertTripDataSecondsToChartData(
                      playback ? minute.slice(0, chartSlice + 1) : minute,
                      vessel,
                    )
              }
              aspectRatio={12}
              onClickLeft={handleChartClick("left")}
              onClickRight={handleChartClick("right")}
            />
          </ChartProvider>
        )}
      </div>
    </div>
  );
};
