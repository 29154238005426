import { useState, useEffect, useContext } from "react";

import {
  getUsersSubscription,
  getAllUsersSubscription,
  getUser,
} from "data/users";
import { AuthContext } from "utils/AuthContext";

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const { user, company } = useContext(AuthContext);
  const isSuper = user.auth_role === "super";

  let unsubscribeToUsers;

  useEffect(() => {
    (async () => {
      await getData();
    })();

    return () => {
      if (unsubscribeToUsers) unsubscribeToUsers();
    };
  }, []);

  const getData = async () => {
    if (isSuper) {
      unsubscribeToUsers = await getAllUsersSubscription((users) => {
        setUsers(users);
      });
    } else {
      unsubscribeToUsers = await getUsersSubscription(company.id, (users) => {
        setUsers(users);
      });
    }
  };

  return users;
};

export const useUser = (id) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (id) {
      (async () => {
        await getData();
      })();
    }
  }, [id]);

  const getData = async () => {
    const user = await getUser(id);
    setUser(user);
  };

  return user;
};
