import { useState, useEffect } from "react";
import { RadarChart } from "components/RadarChart";
import { useCaptain } from "hooks/useCaptains";
import { useVessel } from "hooks/useVessels";
import * as MapUtils from "utils/MapUtils";
import { getTripData } from "data/trips";
import { StatisticsWidget } from "components/Widgets/StatisticsWidget";
import { useDashboard } from "hooks/useDashboard";
import { EfficiencyWidget } from "components/Widgets/EfficiencyWidget";

export const DashboardTripPreview = ({ trip }) => {
  const captain = useCaptain(trip.captain);
  const vessel = useVessel(trip.vessel_id);

  const [tripData, setTripData] = useState([]);
  const dashboardData = useDashboard();

  useEffect(async () => {
    setTripData(await getTripData(trip.id));
  }, []);

  if (!dashboardData.length) return null;

  return (
    <div className="trip-dashboard">
      <div className="trip-dashboard__details-container">
        <div className="trip-dashboard__details">
          <div>
            <span className="label-medium">Captain:</span>{" "}
            {captain?.name || "N/A"}
          </div>
          <div>
            <span className="label-medium">Vessel:</span> {vessel?.name}
          </div>
          <div>
            <span className="label-medium">Distance:</span>{" "}
            {MapUtils.calculateTripDistance(tripData)}
          </div>
        </div>
        <div className="trip-dashboard__stats">
          <div className="trip-dashboard__details-chart">
            <RadarChart drawLabels usePlaceholder padding={0} data={trip} />
          </div>
        </div>
      </div>
      <div className="trip-dashboard__stats">
        <div className="trip-dashboard__divider">
          <h2>Statistics</h2>
          <StatisticsWidget
            hasLink={false}
            item={dashboardData.find((item) => item.type === "quality")}
          />
        </div>
        <h2>Efficiency</h2>
        <EfficiencyWidget
          hasLink={false}
          item={dashboardData.find((item) => item.type === "efficiency")}
        />
      </div>
    </div>
  );
};
