import { db } from "utils/Firebase";

const GROUPS_COLLECTION = db.collection("group");

export const createGroup = async (data) => {
  const groupRef = GROUPS_COLLECTION.doc();
  await groupRef.set({ id: groupRef.id, visible: true, ...data });
};

export const getGroupsSubscription = async (companyId, callback) => {
  return GROUPS_COLLECTION.where("company_id", "==", companyId)
    .where("visible", "==", true)
    .onSnapshot((groupSnap) => {
      callback(
        groupSnap.docs.map((v) => {
          let group = v.data();
          group.id = v.id;
          return group;
        }),
      );
    });
};

export const getGroup = async (id) => {
  const groupRef = await GROUPS_COLLECTION.doc(id).get();
  return groupRef?.data();
};

export const deleteGroup = (id) => {
  GROUPS_COLLECTION.doc(id).update({ visible: false });
};
