import * as d3 from "d3";
import { useRef, useEffect } from "react";
import * as ChartUtils from "../../../utils/chartUtils/Draw";
import * as Color from "../../../utils/Theme";

export const ChartWidget = ({ item, type, aspectRatio }) => {
  const ref = useRef();
  const margin = { top: 10, right: 10, bottom: 10, left: 10 };

  useEffect(() => {
    let width = ref.current.offsetWidth - margin.left - margin.right;
    let height = width / aspectRatio;

    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr(
        "viewBox",
        "0 0 " +
          (width + margin.left + margin.right) +
          " " +
          (height + margin.top + margin.bottom),
      )
      .classed("svg-content-responsive", true);

    if (type === "speed") {
      // draw_bars(
      //     svg,
      //     item.impact,
      //     width,
      //     height,
      //     Color.blue,
      //     [0, 3.0],
      //     1,
      //     0
      // )

      draw_line(svg, item.rog, width, height, Color.red, [23, 30]);

      draw_line(svg, item.sog, width, height, Color.green, [23, 30]);
    } else if (type === "fuel") {
      draw_line(svg, item.fog, width, height, Color.yellow, [23, 30]);

      draw_line(svg, item.sog, width, height, Color.green, [23, 30]);
    } else if (type === "efficiency") {
      draw_line(
        svg,
        item.engine_hours,
        width,
        height,
        Color.yellow,
        [2.2, 6.0],
      );

      draw_line(svg, item.fuel_spend, width, height, Color.green, [55, 100]);

      draw_legend(
        svg,
        { x: 500, y: 0 },
        "Engine hours",
        Color.yellow,
        () => {},
      );

      draw_legend(svg, { x: 500, y: 20 }, "Fuel spend", Color.green, () => {});
    }

    return () => {
      svg.remove();
    };
  }, [item, type]);

  const draw_legend = (svg, position, text, color, onClick) => {
    ChartUtils.legend(svg, text, color, position.x, position.y, onClick);
  };

  const draw_line = (svg, y, width, height, color, range) => {
    const xScale = d3
      .scaleLinear()
      .domain([y.length - 1, 0])
      .range([width - width / (2 * y.length), width / (2 * y.length)]);

    const linechartYscale = d3.scaleLinear().domain(range).range([height, 0]);

    ChartUtils.drawLine(svg, y, color, xScale, linechartYscale, margin);
  };

  // const draw_bars = (
  //     svg,
  //     y,
  //     width,
  //     height,
  //     color,
  //     range,
  //     numberOfBars,
  //     barOffset,
  // ) => {
  //     const barchartYscale = d3.scaleLinear().domain(range).range([height, 0]);

  //     svg
  //         .selectAll(".bar")
  //         .data(y)
  //         .enter()
  //         .append("rect")
  //         .attr(
  //             "x",
  //             (_, i) =>
  //                 (i * width) / y.length +
  //                 barOffset * (width / (numberOfBars * y.length)) +
  //                 margin.left,
  //         )
  //         .attr("y", (d) => barchartYscale(d) + margin.top)
  //         .attr("width", width / (numberOfBars * y.length) - 5)
  //         .attr("height", (d) => height - barchartYscale(d))
  //         .attr('rx',"5px")
  //         .attr("fill", color);
  // };

  return <div ref={ref} className="svg-container" />;
};
