import { useContext } from "react";
import { AuthContext } from "utils/AuthContext";
import { ChangeEmail } from "./ChangeEmail";
import { ChangePhoneNumber } from "./ChangePhoneNumber";
import { CreateGroup } from "./CreateGroup";

export const SettingsScreen = () => {
  const { currentUser } = useContext(AuthContext);

  const isEmailAuth =
    "emailVerified" in currentUser && currentUser.emailVerified;

  return (
    <div className="container">
      <h1 className="text white">Settings</h1>
      <div className="settings">
        <div className="settings__changer">
          {isEmailAuth ? (
            <ChangeEmail user={currentUser} />
          ) : (
            <ChangePhoneNumber user={currentUser} />
          )}
          <CreateGroup />
        </div>
      </div>
    </div>
  );
};
