import { IssuesModal } from "components/Issues";
import { ActivityModal } from "components/Modal";
import { useState } from "react";

const ExpandedRowLogs = ({
  data,
  captain,
  vessel,
  param,
  canAdd = false,
  hasLogs = false,
  hasIssues = false,
}) => {
  const [log, setLog] = useState(null);
  const [isOpenedLog, setIsOpenedLog] = useState(false);
  const [isOpenIssue, setIsOpenedIssue] = useState(false);

  const onOpenLog = (log) => {
    setIsOpenedLog(true);
    setLog(log);
  };

  const onCloseLog = () => {
    setIsOpenedLog(false);
    setLog(null);
  };

  return (
    <>
      <div className="expanded__grid-column">
        <div className="expanded__grid-button-group justify-between">
          {hasLogs && (
            <button
              onClick={() => onOpenLog(data?.log)}
              className="expanded__grid-button"
            >
              {`Captain's log`}
            </button>
          )}
          {hasIssues && (
            <button
              onClick={() => setIsOpenedIssue(true)}
              className="expanded__grid-issue-button"
            >
              Issues
            </button>
          )}
        </div>
      </div>
      {hasLogs && (
        <ActivityModal
          show={isOpenedLog}
          onClose={onCloseLog}
          data={log || isOpenedLog}
          id={data?.id}
          status="log"
          captain={captain}
          vessel={vessel}
          disabled={!!log}
        />
      )}
      {hasIssues && (
        <IssuesModal
          data={data}
          show={isOpenIssue}
          onClose={() => setIsOpenedIssue(false)}
          captain={captain}
          vessel={vessel}
          param={param}
          hasAddButton={canAdd}
        />
      )}
    </>
  );
};

export default ExpandedRowLogs;
