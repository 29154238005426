import { useEffect } from "react";
import { Button, Form } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";

import * as Color from "utils/Theme";
import { isPhoneAlreadyUsed } from "data/users";

const UserProfileSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  companyName: Yup.string().required(),
  email: Yup.string().email().required(),
});

export const UserProfile = ({ userInfo, setUserInfo }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm({ resolver: yupResolver(UserProfileSchema) });
  const history = useHistory();

  const hasPhoneNumber = !!userInfo.phoneNumber;
  const hasEmail = !!userInfo.email;

  if (hasEmail) setValue("email", userInfo.email);
  else if (hasPhoneNumber) setValue("phoneNumber", userInfo.phoneNumber);

  useEffect(() => {
    if (!hasPhoneNumber && !hasEmail) history.push("/signup");
  }, []);

  const onSubmit = (values) => {
    if (isPhoneAlreadyUsed(values.phoneNumber)) {
      setError("phoneNumber", "Such number is already registered");
    } else {
      setUserInfo({ ...userInfo, ...values });
      history.push("/signup/company");
      reset();
    }
  };

  return (
    <div>
      <h1>Set up your profile</h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <label>First Name</label>
          <input name="firstName" {...register("firstName")} />
          <p style={{ color: Color.red }}>{errors.firstName?.message}</p>
        </Form.Field>
        <Form.Field>
          <label>Last Name</label>
          <input name="lastName" {...register("lastName")} />
          <p style={{ color: Color.red }}>{errors.lastName?.message}</p>
        </Form.Field>
        <Form.Field>
          <label>Phone Number</label>
          <input
            placeholder="Phone number"
            disabled={hasPhoneNumber}
            value={hasPhoneNumber ? userInfo.phoneNumber : undefined}
            name="phoneNumber"
            type="tel"
            {...register("phoneNumber")}
          />
          <p style={{ color: Color.red }}>{errors.phoneNumber?.message}</p>
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <input
            disabled={hasEmail}
            placeholder="Email"
            type="email"
            name="email"
            value={hasEmail ? userInfo.email : undefined}
            {...register("email")}
          />
          <p style={{ color: Color.red }}>{errors.email?.message}</p>
        </Form.Field>
        <Form.Field>
          <label>Company</label>
          <input name="companyName" {...register("companyName")} />
          <p style={{ color: Color.red }}>{errors.companyName?.message}</p>
        </Form.Field>
        <Button fluid primary type="submit">
          Create
        </Button>
      </Form>
    </div>
  );
};
