import { AddButton } from "components/AddButton";
import { Loader } from "components/Loader";
import { Table } from "components/Table";
import { useCompaniesData } from "./hooks/useCompaniesData";
import logo from "../../assets/axopar.png";

export const CompaniesScreen = () => {
  const { companiesData, headings, loading } = useCompaniesData();

  if (loading) return <Loader />;

  return (
    <div className="container">
      <div className="d-flex justify-between align-center">
        <h1 className="text white">Dealers</h1>
        <img src={logo} width={200}/>
        <AddButton link="/companies/new" />
      </div>
      <Table headings={headings} data={companiesData} />
    </div>
  );
};
