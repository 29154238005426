import { useCompanies } from "hooks/useCompanies";
import { useGroups } from "hooks/useGroups";
import { useLoading } from "hooks/useLoading";
import { useUsers } from "hooks/useUsers";
import { useContext } from "react";

import { AuthContext } from "utils/AuthContext";
import { Users } from "utils/PagesContent";

const roles = {
  captain: "Captain",
  maintenance: "Maintenance",
  fleetManager: "Fleet manager",
};
export const useUsersData = () => {
  const { user } = useContext(AuthContext);
  const allowEdit = user.auth_role === "admin" || user.auth_role === "super";
  let isSuper = user.auth_role === "super";
  const companies = useCompanies();
  const users = useUsers();
  const groups = useGroups();

  const loading = useLoading(companies, users);

  const getOrganizationRoles = (user) => {
    return user.org_roles?.map((role) => roles[role]).join(", ");
  };

  const getCompanyName = (user) => {
    if (isSuper)
      return companies.find((c) => c.id === user.company_id)?.name ?? "-";

    return groups.find((group) => group.id === user.group)?.name ?? "-";
  };

  const usersData = Users.data(
    users,
    user,
    getCompanyName,
    getOrganizationRoles,
    allowEdit,
  );

  const headings = Users.headings(allowEdit, isSuper);

  return { usersData, headings, loading };
};
