import { Icon } from "semantic-ui-react";
import * as DateUtils from "utils/DateUtils";

export const LiveConsolePinnedTrips = ({
  focusTrips,
  resetFocusTrips,
  vessels,
  pinnedTrip,
  displayChart,
  handlePlayback,
  playback,
  handleDelete,
  //handleShowDashboard,
}) => {
  return (
    <div className="trips-container">
      <div
        className={`console__trips-trip ${
          focusTrips.find((t) => t.id === pinnedTrip.id) ? "focused" : ""
        }`}
        onClick={resetFocusTrips(pinnedTrip)}
      >
        <div>
          <Icon name="pin" />
          {vessels.find((v) => v.id === pinnedTrip?.vessel_id)?.name ?? "N/A"}
          {", "}
          {DateUtils.prettyDate(pinnedTrip.start.toDate().toString())}
        </div>
      </div>
      <div className="trips-container-content">
        <Icon name="chart bar" onClick={displayChart(pinnedTrip)} />
      </div>
      {/* <div className="trips-container-content">
        <Icon name="info circle" onClick={handleShowDashboard(pinnedTrip)} />
      </div> */}
      <div className="trips-container-content">
        <Icon
          name={playback === pinnedTrip.id ? "pause" : "play"}
          onClick={handlePlayback(pinnedTrip)}
        />
      </div>
      <div className="trips-container-content">
        <Icon name="delete" onClick={handleDelete(pinnedTrip)} />
      </div>
    </div>
  );
};
