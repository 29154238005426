import * as yup from "yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { updateCompany, createCompany } from "data/companies";
import { useCompany } from "hooks/useCompanies";

const CompanyEditSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
  contact: yup.string().email().required("Contact email is required"),
  country: yup.string().nullable(),
  region: yup.string().nullable(),
});

export const useCompanyEditFormHandler = (id, onDone) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(CompanyEditSchema),
  });
  const company = useCompany(id);

  const onSubmit = async (values) => {
    if (id) {
      await updateCompany(id, values);
    } else {
      await createCompany(values);
    }
    reset({
      name: null,
      contact: null,
    });
    onDone && onDone();
  };

  useEffect(() => {
    reset({
      name: company?.name ?? "",
      contact: company?.contact ?? "",
      region: company?.region ?? "",
      country: company?.country ?? "",
    });
  }, [id, company]);

  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    reset,
    getValues,
    watch,
    company,
  };
};
