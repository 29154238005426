import * as Color from "../../utils/Theme";

export const getDashboard = async (userId) => {
  console.log(userId);
  return [
    {
      type: "map",
      layout: "3by3",
      link: "/",
      bounds: [
        [64.15476 + 0.01, -21.943821 + 0.01],
        [64.15476 - 0.01, -21.943821 - 0.01],
      ],
      vessels: [
        {
          name: "Boaty McBoatface",
          captain: "-",
          duration: "2h 30m",
          position: { latitude: 123, longitude: 123 },
          peak_impact: 4.0,
        },
      ],
    },
    {
      type: "quality",
      layout: "2by1",
      link: "/dashboard",
      sog: [25, 23, 27, 28, 30, 25, 23, 27, 28, 30],
      rog: [30, 30, 30, 28, 27, 30, 30, 30, 28, 27],
      fog: [24, 23, 23, 23, 25, 24, 23, 23, 23, 25],
      impact: [2.5, 1.3, 1.2, 1.8, 2.8, 2.5, 1.3, 1.2, 1.8, 2.8],
    },
    {
      type: "performance",
      layout: "2by2",
      link: "/dashboard",
      rog_sog: 0.5,
      red_impact: 0.4,
      peak_impact: 0.6,
      sea_state: 0.4,
      avg_impact: 0.4,
      avg_sog: 0.3,
    },
    {
      type: "alerts",
      layout: "1by1",
      link: "/dashboard",
      alerts: [
        {
          icon: "warning",
          color: Color.red,
          message: "2 red impacts last week",
        },
        {
          icon: "sticky note",
          color: Color.yellow,
          message: "3 issues logged last week",
        },
      ],
    },
    {
      type: "forecast",
      layout: "1by1",
      link: "/dashboard",
      forecast: [
        {
          icon: "sun",
          color: Color.yellow,
          time: "Now",
          wind_speed: "4 m/s",
        },
        {
          icon: "snowflake",
          color: "white",
          time: "3 hours",
          wind_speed: "10 m/s",
        },
        {
          icon: "umbrella",
          color: Color.blue,
          time: "6 hours",
          wind_speed: "15 m/s",
        },
      ],
    },
    {
      type: "ranking",
      layout: "1by1",
      link: "/captains",
      rank: [
        {
          name: "Jane Doe",
          rank: "1",
          quality: "98%",
        },
        {
          name: "John Doe",
          rank: "2",
          quality: "96%",
        },
      ],
    },
    {
      type: "today",
      layout: "1by1",
      link: "/",
      trips: 3,
      red_impacts: 2,
      peak_impact: 4.2,
      avg_rog: 30,
      avg_sog: 28,
    },
    {
      type: "maintenance",
      layout: "1by1",
      link: "/dashboard",
      maintenance: [
        {
          vessel: "Boaty McBoatface",
          date: "March 14th",
          issues: ["Engine noise", "Ignition switch"],
        },
      ],
    },
    {
      type: "onboarding",
      layout: "1by1",
      link: "/",
      message: "Have you tried our new playback in the console?",
    },
    {
      type: "efficiency",
      layout: "3by1",
      link: "/dashboard",
      engine_hours: [4.0, 5.0, 3.3, 4.0, 2.2, 6.0, 4.0, 5.0, 3.3, 4.0],
      fuel_spend: [100, 60, 80, 55, 72, 100, 60, 80, 55, 72],
    },
    {
      type: "trips",
      layout: "3by2",
      link: "/trips",
      trips: [
        {
          vessel: "Boaty McBoatface",
          captain: "Captain Nemo",
          date: "February 9th, 9:00",
        },
        {
          vessel: "Boaty McBoatface",
          captain: "Captain Nemo",
          date: "February 8th, 9:00",
        },
        {
          vessel: "Boaty McBoatface",
          captain: "Captain Nemo",
          date: "February 7th, 9:00",
        },
      ],
    },
    {
      type: "vessels",
      layout: "3by2",
      link: "/vessels",
      vessels: [
        {
          name: "Boaty McBoatface",
          captain: "Captain Nemo",
          trips: 10,
          avg_impact: 2.8,
        },
        {
          name: "Derp",
          captain: "qwerty",
          trips: 8,
          avg_impact: 3.1,
        },
      ],
    },
    {
      type: "captains",
      layout: "3by2",
      link: "/captains",
      captains: [
        {
          name: "Captain Nemo",
          trips: 10,
          avg_impact: 2.8,
        },
        {
          name: "qwerty",
          trips: 8,
          avg_impact: 3.1,
        },
      ],
    },
  ];
};
