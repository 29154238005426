import { getTripData } from "data/trips";
import { useEffect, useState } from "react";

export const useTripData = (tripId) => {
  const [tripData, setTripData] = useState([]);

  useEffect(() => {
    (async () => {
      let data = await getTripData(tripId);
      setTripData(data);
    })();
  }, []);

  return tripData;
};
