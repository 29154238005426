import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { DataWidget } from "components/Widgets/DataWidget";
import { MapWidget } from "components/Widgets/MapWidget";
import { WeatherWidget } from "components/Widgets/WeatherWidget";
import { AlertWidget } from "components/Widgets/AlertWidget";
import { RadarChart } from "components/RadarChart";
import { Chart } from "components/Chart";
import { Logs } from "components/Logs";

import * as DataAdapters from "utils/DataAdapters";
import * as ChartUtils from "utils/chartUtils/ConvertData";
import * as Color from "utils/Theme";
import { useTrip } from "hooks/useTrips";
import { useCaptains } from "hooks/useCaptains";
import { useVessels } from "hooks/useVessels";
import { useChart } from "hooks/useChart";
import { useTripsWithConsole } from "hooks/useTripsWithConsole";

const forecast = [
  {
    icon: "sun",
    color: Color.yellow,
    time: "Now",
    wind_speed: "4 m/s",
  },
  {
    icon: "snowflake",
    color: "white",
    time: "3 hours",
    wind_speed: "10 m/s",
  },
  {
    icon: "umbrella",
    color: Color.blue,
    time: "6 hours",
    wind_speed: "15 m/s",
  },
];

const alerts = [
  {
    icon: "warning",
    color: Color.red,
    message: "2 red impacts last week",
  },
  {
    icon: "sticky note",
    color: Color.yellow,
    message: "3 issues logged last week",
  },
];

export const TripDescriptionPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const trip = useTrip(id);
  const captains = useCaptains();
  const vessels = useVessels();
  const { onPinAndFocusTrip } = useTripsWithConsole(trip, () =>
    history.push("/"),
  );
  const { tripData, right, left, onClickLeft, onClickRight } = useChart(
    trip?.id,
  );

  return (
    <div className="description">
      <Link to="/trips">
        <Icon name="arrow left" style={{ color: "white", padding: "20px" }} />
      </Link>
      <div className="description__header">
        <DataWidget
          data={DataAdapters.mapTripToDetails(trip, captains, vessels)}
          label="Details"
          className="w-60"
        />

        <DataWidget
          data={DataAdapters.mapDataToStatistics(trip)}
          label="Statistics"
          className="w-60"
        />
        <Logs data={trip} className="description-logs" canAdd />
      </div>
      <div className="description__charts">
        <div className="description__charts-map" onClick={onPinAndFocusTrip}>
          <MapWidget bounds={DataAdapters.getBoundsFromTrip(trip)} />
        </div>
        {trip && (
          <div className="description__charts-spider">
            <RadarChart data={trip} drawLabels />
          </div>
        )}
        <div className="description__charts-additional">
          <WeatherWidget forecast={forecast} />
          <AlertWidget alerts={alerts} />
        </div>
      </div>
      <div className="description__trip-chart">
        {tripData?.length && (
          <Chart
            data={ChartUtils.convertTripDataMinutesToChartData(
              tripData.slice(left, right),
            )}
            drawAxes
            drawLegend
            aspectRatio={8}
            onClickRight={onClickRight}
            onClickLeft={onClickLeft}
          />
        )}
      </div>
    </div>
  );
};
