import firebase from "firebase";

import { db } from "utils/Firebase";

const USERS_COLLECTION = db.collection("users");

export const userRoles = [
  { key: "fleetManager", text: "Fleet Manager", value: "fleetManager" },
  { key: "captain", text: "Captain", value: "captain" },
  { key: "maintenance", text: "Maintenance", value: "maintenance" },
];

export const createUser = async (values) => {
  const manageUser = firebase.functions().httpsCallable("manageUser");

  return await manageUser({
    ...values,
    id: null,
  }).catch((e) => {
    console.error(e.message);
  });
};

export const updateUser = async (id, values) => {
  const user = USERS_COLLECTION.doc(id);
  return await user.update({
    ...values,
  });
};

export const createUserManually = async (values) => {
  const userRef = USERS_COLLECTION.doc(values.id);
  await userRef.set({
    name: values.name,
    id: values.id,
    phone_number: values.phoneNumber,
    company_id: values.companyId,
    created_at: firebase.firestore.Timestamp.now(),
    org_roles: values.organizationRoles,
    auth_role: values.admin ? "admin" : "user",
    visible: true,
  });
};

export const editUser = async (id, values) => {
  const manageUser = firebase.functions().httpsCallable("manageUser");
  await manageUser({
    id,
    name: values.name,
    companyId: values.companyId,
    phoneNumber: values.phoneNumber,
    organizationRoles: values.organizationRoles,
    group: values.group,
    admin: values.admin,
  }).catch((e) => {
    console.error(e.message);
  });
};

export const getUser = async (id) => {
  const userSnap = await USERS_COLLECTION.doc(id).get();
  let user = userSnap.data();
  user.id = userSnap.id;
  return user;
};

export const getUserByPhoneNumber = async (phoneNumber) => {
  const userSnap = await USERS_COLLECTION.where(
    "phone_number",
    "==",
    phoneNumber,
  )
    .limit(1)
    .get();
  let user = userSnap.docs[0].data();
  user.id = userSnap.docs[0].id;
  return user;
};

export const getUserById = async (userId) => {
  const userSnap = await USERS_COLLECTION.where("id", "==", userId)
    .limit(1)
    .get();
  let user = userSnap.docs[0].data();
  user.id = userSnap.docs[0].id;
  return user;
};

export const getUsers = async (companyId) => {
  const usersSnap = await USERS_COLLECTION.where("company_id", "==", companyId)
    .where("visible", "==", true)
    .get();
  return usersSnap.docs.map((v) => {
    let data = v.data();
    data.id = v.id;
    return data;
  });
};

export const getAllUsers = async () => {
  const usersSnap = await USERS_COLLECTION.where("visible", "==", true).get();
  return usersSnap.docs.map((v) => {
    let data = v.data();
    data.id = v.id;
    return data;
  });
};

export const getUsersSubscription = (companyId, callback) => {
  return USERS_COLLECTION.where("company_id", "==", companyId)
    .where("visible", "==", true)
    .onSnapshot((usersSnap) => {
      callback(
        usersSnap.docs.map((v) => {
          let vessel = v.data();
          vessel.id = v.id;
          return vessel;
        }),
      );
    });
};

export const getAllUsersSubscription = (callback) => {
  return USERS_COLLECTION.where("visible", "==", true).onSnapshot(
    (usersSnap) => {
      callback(
        usersSnap.docs.map((v) => {
          let vessel = v.data();
          vessel.id = v.id;
          return vessel;
        }),
      );
    },
  );
};

export const getUserSubscription = (id, callback) => {
  return USERS_COLLECTION.doc(id).onSnapshot((userSnap) => {
    let user = userSnap.data();
    user.id = userSnap.id;
    callback(user);
  });
};

export const deleteUser = (id) => {
  USERS_COLLECTION.doc(id).update({ visible: false });
};

export const isPhoneAlreadyUsed = async (phoneNumber) => {
  const ifUserExists = firebase.functions().httpsCallable("ifUserExists");
  return await ifUserExists({ data: { phoneNumber } }).catch((e) =>
    console.error(e),
  );
};
