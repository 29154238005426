/*******************************
UI
********************************/
import { MapContainer, TileLayer, Polyline } from "react-leaflet";

/*******************************
Utils
********************************/
import * as Color from "utils/Theme";
import { mapboxAccessToken } from "utils/Constants";

const getBounds = (data) => {
  const Leaflet = window.L;

  if (data?.length == 0) return null;

  let lats = [];
  let lons = [];

  data.forEach((element) => {
    for (let i = 0; i < element.latitudes.length; i++) {
      if (isLocationValid(element.latitudes[i], element.longitudes[i])) {
        lats.push(element.latitudes[i]);
        lons.push(element.longitudes[i]);
      }
    }
  });

  if (lats.length === 0 && lons.length === 0)
    return Leaflet.latLngBounds([
      [0, 0],
      [0, 0],
    ]);

  const point1 = [Math.min(...lats) - 0.01, Math.min(...lons) - 0.01];
  const point2 = [Math.max(...lats) + 0.01, Math.max(...lons) + 0.01];

  return Leaflet.latLngBounds([point1, point2]);
};

const getLine = (data) => {
  let path = [];
  let color;
  let previousColor;
  let linePositions = [];

  data.forEach((element) => {
    if (element.avg_sog > 0.5) {
      let impact = Math.abs(element.imu_1.max_impact);

      if (impact <= 1.0) {
        color = Color.green;
      } else if (impact > 1.0 && impact <= 3.0) {
        color = Color.yellow;
      } else if (impact > 3.0) {
        color = Color.red;
      }

      for (let i = 0; i < element.latitudes.length; i++) {
        if (isLocationValid(element.latitudes[i], element.longitudes[i])) {
          path.push([element.latitudes[i], element.longitudes[i]]);
        }
      }

      linePositions.push({
        positions: path,
        color: color,
        time: element.time,
        rog: element.avg_rog,
        sog: element.avg_sog,
        impact: element.imu_1.max_impact,
      });
      path = [];
      if (
        isLocationValid(
          element.latitudes[element.latitudes.length - 1],
          element.longitudes[element.longitudes.length - 1],
        )
      ) {
        path.push([
          element.latitudes[element.latitudes.length - 1],
          element.longitudes[element.longitudes.length - 1],
        ]);
      }
      previousColor = color;
    }
  });

  linePositions.push({
    positions: path,
    color: previousColor,
    time: data[data.length - 1].time,
    rog: data[data.length - 1].avg_rog,
    sog: data[data.length - 1].avg_sog,
    impact: data[data.length - 1].imu_1.max_impact,
  });

  return linePositions;
};

export const isLocationValid = (latitude, longitude) => {
  return (
    !isNaN(latitude) &&
    !isNaN(longitude) &&
    latitude !== 0 &&
    latitude !== 214.7483646 &&
    longitude !== 0 &&
    longitude !== 214.7483646
  );
};

export const StaticMap = ({ tripData }) => {
  const interactions = {
    zoomControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    zoomSnap: false,
    zoomDelta: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false,
  };

  return (
    <MapContainer
      {...interactions}
      bounds={getBounds(tripData)}
      style={{ height: "100%", width: "100wh" }}
    >
      <TileLayer
        key="2"
        attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
        url={
          "https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=" +
          mapboxAccessToken
        }
      ></TileLayer>
      {tripData?.length > 0 &&
        getLine(tripData).map((x, ndx) => {
          return (
            <Polyline
              key={ndx}
              positions={x.positions}
              pathOptions={{ color: x.color }}
            />
          );
        })}
    </MapContainer>
  );
};
