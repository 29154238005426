import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { editVessel, createVessel } from "data/vessels";
import { useEffect } from "react";

const nullableNumber = yup
  .number()
  .transform((val) => (Number.isNaN(val) ? 0 : Number.parseInt(val)))
  .min(0, "Can't contain negative value")
  .typeError("You must specify a number")
  .nullable();

const VesselEditSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
  max_acc: yup
    .number()
    .min(0, "Can't contain negative value")
    .typeError("You must specify a number")
    .required("This field is required"),
  max_speed: yup
    .number()
    .min(0, "Can't contain negative value")
    .typeError("You must specify a number")
    .required("This field is required"),
  manufacturer: yup.string("Must be a string").nullable(),
  length: nullableNumber,
  boa: nullableNumber,
  dis_weight: nullableNumber,
  fuel_capacity: nullableNumber,
  top_speed: yup
    .number()
    .min(0, "Can't contain negative value")
    .typeError("You must specify a number")
    .required("This field is required"),
  cruising_speed: yup
    .number()
    .min(0, "Can't contain negative value")
    .typeError("You must specify a number")
    .required("This field is required"),
  year_built: nullableNumber,
  group: yup.string().nullable(),
  comfort: yup
    .string()
    .min(1, "Minimum 1 symbol")
    .max(50, "Maximum 50 symbols"),
});

export const useVesselEditFormHandler = (id, company, onHide) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(VesselEditSchema),
  });

  useEffect(() => {
    reset({
      max_acc: 3.0,
      length: 0,
      year_built: 0,
    });
  }, [id]);

  const onSubmit = async (values) => {
    if (id) {
      await editVessel(id, { ...values, company_id: company.id });
    } else {
      await createVessel({ ...values, company_id: company.id });
    }
    reset({
      name: null,
      company_id: null,
      max_acc: null,
      max_speed: null,
      manufacturer: null,
      length: null,
      boa: null,
      gross_weight: null,
      fuel_capacity: null,
      top_speed: null,
      cruising_speed: null,
      year_built: null,
      group: null,
    });
    onHide();
  };

  return { getValues, register, handleSubmit, onSubmit, errors, reset };
};
