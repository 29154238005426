import { useEffect, useRef } from "react";
import * as d3 from "d3";

import * as Color from "utils/Theme";

/***
 * change the data so that it is mapped between 0 and 1
 * Right now most of these values are random, since the data doesn't exist
 */
export const prepareRadarChartData = (data) => {
  let redImpacts = data.red_impacts
    ? Math.min(1.0, data.red_impacts / 10.0)
    : 0.5;
  let peakImpact = data.peak_impact
    ? Math.min(1.0, data.peak_impact / 16.0)
    : 0.5;
  let avgSogRog = data.avg_sog_over_rog
    ? Math.min(1.0, data.avg_sog_over_rog / 6.0)
    : Math.random() * 0.8 + 0.2;
  let avgSogFog = Math.pow(Math.random() * 0.7 + 0.3, 2);
  let impactsAbove3 = Math.pow(Math.random() * 0.7 + 0.3, 2);
  let avgSeaState = Math.pow(Math.random() * 0.7 + 0.3, 2);

  let dat = [
    redImpacts,
    peakImpact,
    avgSogRog,
    avgSogFog,
    impactsAbove3,
    avgSeaState,
  ];
  for (let i = 0; i < dat.length; i++) {
    dat[i] = Math.max(0.05, dat[i]);
  }
  return dat;
};

/**
 * gives a colour scheme based on wether the data is high or not
 * @param {*} data the values mapped between 0 and 1
 * @returns the colour theme based on what the highest value is
 */
export const prepareColor = (data) => {
  let maxVal = 0;
  for (let i = 0; i < data.length; i++) {
    maxVal = Math.max(maxVal, data[i]);
  }
  if (maxVal > 0.9) {
    return Color.warning;
  }
  if (maxVal > 0.5) {
    return Color.caution;
  }
  return Color.good;
};

export const RadarChart = ({
  data,
  compareData,
  drawLabels = false,
  padding = 20,
  usePlaceholder = false,
  height = 150,
  width = 200,
}) => {
  const ref = useRef();
  const margin = {
    top: padding,
    right: padding,
    bottom: padding,
    left: padding,
  };

  const axisLabels = [
    "Red impacts",
    "Peak impact",
    "SOG/ROG",
    "SOG/FOG",
    "impacts over 3g",
    "average Sea State",
  ];

  const radius = height / 2 - margin.top - margin.bottom;
  const rScale = d3.scaleLinear().range([0, radius]).domain([0, 1]);

  const NUM_OF_SIDES = 6,
    NUM_OF_LEVELS = 6,
    polyangle = (Math.PI * 2) / NUM_OF_SIDES,
    offset = Math.PI;

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr(
        "viewBox",
        "0 0 " +
          (width + margin.left + margin.right) +
          " " +
          (height + margin.top + margin.bottom),
      )
      .classed("svg-content-responsive", true);

    let tooltip = drawTooltip();
    drawCanvas(svg);
    if (drawLabels) drawAxisLabels(svg);
    drawBlob(svg);
    drawTooltipHitbox(svg, tooltip, data);
    return () => {
      svg.remove();
      tooltip.remove();
    };
  }, [data, compareData]);

  /**
   * Generates a point on a polygon
   * @param {*} length length of the polygon
   * @param angle angle between points
   * @returns a single point on a polygon
   */
  const generatePoint = ({ length, angle }) => {
    const point = {
      x: length * Math.sin(offset - angle),
      y: length * Math.cos(offset - angle),
    };
    return point;
  };

  const drawCanvas = (svg) => {
    // Create an array of points that make up a polygon based on number of angles and number of levels
    let points = [];
    for (let level = 1; level <= NUM_OF_LEVELS; level++) {
      let length = (radius * level) / NUM_OF_LEVELS;
      for (let vertex = 0; vertex <= NUM_OF_SIDES; vertex++) {
        const theta = vertex * polyangle;
        let temp = generatePoint({ length, angle: theta });
        points.push(temp.x);
        points.push(temp.y);
      }
    }
    svg
      .append("polygon")
      .attr("points", points)
      .style("stroke", "#151515")
      .style("stroke-width", "0.4px")
      .style("fill", "#222")
      .style("fill-opacity", 0)
      .style("stroke-opacity", 0.6)
      .attr(
        "transform",
        "translate(" +
          (margin.left + width / 2) +
          "," +
          (margin.top + height / 2) +
          ")",
      );

    svg
      .selectAll(".axis")
      .data(axisLabels)
      .enter()
      .append("g")
      .attr("class", "axis")
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr(
        "x2",
        (d, i) => rScale(1) * Math.cos((Math.PI / 3) * i - Math.PI / 2),
      )
      .attr(
        "y2",
        (d, i) => rScale(1) * Math.sin((Math.PI / 3) * i - Math.PI / 2),
      )
      .attr("class", "line")
      .style("stroke", "#151515")
      .style("stroke-width", "0.7px")
      .style("stroke-opacity", 0.5)
      .attr(
        "transform",
        "translate(" +
          (margin.left + width / 2) +
          "," +
          (margin.top + height / 2) +
          ")",
      );
  };

  const mouseOver = (tooltip, data) => {
    tooltip.transition().duration(100).style("opacity", 1);

    drawTooltipContent(tooltip, data);
  };

  const mouseLeave = (tooltip) => {
    tooltip.transition().duration(100).style("opacity", 0);
  };

  const mouseMove = (e, tooltip) => {
    if (window.scrollX + e.clientX < 1000) {
      tooltip
        .style("left", window.scrollX + e.clientX + `px`)
        .style("top", window.scrollY + e.clientY + `px`);
    } else {
      tooltip
        .style("left", window.scrollX + e.clientX - 200 + `px`)
        .style("top", window.scrollY + e.clientY + `px`);
    }
  };

  const drawTooltipContent = (tooltip, data) => {
    if (data.statistics) {
      data = data.statistics;
    }

    //Reset tooltip
    tooltip.selectAll(".tooltip-paragraph").remove();

    const labels = {
      "Red impacts": "red_impacts",
      "Peak impact": "peak_impact",
      "average SOG/ROG": "avg_sog_over_rog",
      "average SOG/FOG": "avg_sog_over_fog",
      "impacts over 3g": "impacts_over_3g",
      "average sea state": "avg_sea_state",
    };
    for (let i in axisLabels) {
      if (labels[axisLabels[i]] in data) {
        let dat = data[labels[axisLabels[i]]];
        if (typeof dat === "number" && !Number.isInteger(dat)) {
          dat = dat.toPrecision(5);
        }
        tooltip
          .append("p")
          .attr("class", "tooltip-paragraph")
          .text(axisLabels[i] + " : " + dat);
      }
    }
  };

  const drawAxisLabels = (svg) => {
    svg
      .selectAll(".axisLabels")
      .data(axisLabels)
      .enter()
      .append("text")
      .attr("class", "legend")
      .style("font-size", "6px")
      .attr("text-anchor", "middle")
      .attr("dy", "0.35em")
      .attr(
        "x",
        (d, i) => rScale(1.5) * Math.cos((Math.PI / 3) * i - Math.PI / 2),
      )
      .attr(
        "y",
        (d, i) => rScale(1.3) * Math.sin((Math.PI / 3) * i - Math.PI / 2),
      )
      .text((d) => d)
      .style("fill", "#151515")
      .attr(
        "transform",
        "translate(" +
          (margin.left + width / 2) +
          "," +
          (margin.top + height / 2) +
          ")",
      );
  };

  const drawTooltip = () => {
    const tooltip = d3
      .select(ref.current)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", "0");
    return tooltip;
  };

  const drawTooltipHitbox = (svg, tooltip, data) => {
    svg
      .append("circle")
      .attr("r", radius)
      .style("fill-opacity", 0)
      .attr(
        "transform",
        "translate(" +
          (margin.left + width / 2) +
          "," +
          (margin.top + height / 2) +
          ")",
      )
      .on("mousemove", (e) => mouseMove(e, tooltip))
      .on("mouseover", () => mouseOver(tooltip, data))
      .on("mouseout", () => mouseLeave(tooltip));
  };

  const generateChartPoints = (data) => {
    let points = [];
    for (let item = 0; item < 6; item++) {
      const theta = item * polyangle;
      const length = radius * data[item];
      const temp = generatePoint({ length, angle: theta });
      points.push(temp.x);
      points.push(temp.y);
    }
    return points;
  };

  const drawBlob = (svg) => {
    const chartData = prepareRadarChartData(data);
    if (chartData) {
      usePlaceholder = false;
    }

    /**
     * takes in a polygon and creates a triangle slice of the polygon
     * @param points an array that makes up a polygon
     * @param level the index of the slice being created
     * @returns an array that makes up the points of a triangle slice of the polygon
     */
    const prepareTriangle = (points, level) => {
      return [
        points[2 * level],
        points[2 * level + 1],
        points[(2 * level + 2) % points.length],
        points[(2 * level + 3) % points.length],
        0.0,
        0.0,
      ];
    };

    if (compareData) {
      const colors = Color.backgroundRadar;
      const compDat = prepareRadarChartData(compareData);
      let compPoints = generateChartPoints(compDat);
      for (let i = 0; i < NUM_OF_SIDES; i++) {
        const triangle = prepareTriangle(compPoints, i);
        svg
          .append("polygon")
          .attr("points", triangle)
          .style("fill", colors[i])
          .style("fill-opacity", 0.8)
          .style("stroke", colors[i])
          .style("stroke-width", 0.25)
          .style("stroke-opacity", 0.8)
          .attr(
            "transform",
            "translate(" +
              (margin.left + width / 2) +
              "," +
              (margin.top + height / 2) +
              ")",
          );
      }
    }

    let dat = usePlaceholder ? [0.5, 0.3, 0.2, 0.5, 0.7, 0.5] : chartData;
    let chartPoints = generateChartPoints(dat);
    let col = prepareColor(dat);
    for (let i = 0; i < NUM_OF_SIDES; i++) {
      const triangle = prepareTriangle(chartPoints, i);
      svg
        .append("polygon")
        .attr("points", triangle)
        .style("fill", col[i])
        .style("fill-opacity", 0.8)
        .style("stroke", col[i])
        .style("stroke-width", 0.1)
        .style("stroke-opactiry", 0.8)
        .attr(
          "transform",
          "translate(" +
            (margin.left + width / 2) +
            "," +
            (margin.top + height / 2) +
            ")",
        );
    }
  };

  return <div ref={ref} className="svg-container"></div>;
};
