import { useCompanies } from "hooks/useCompanies";
import { useLoading } from "hooks/useLoading";

import * as DateUtils from "utils/DateUtils";
import { Companies } from "utils/PagesContent";

export const useCompaniesData = () => {
  const companies = useCompanies();
  const loading = useLoading(companies);

  const getCreatedDate = (x) => {
    if (!x.created_at) return "-";

    return DateUtils.prettyDate(x.created_at.toDate().toString());
  };
  const getLastLogin = (x) => {
    if (!x.last_login) return "-";

    return DateUtils.prettyDate(x.last_login.toDate().toString());
  };

  const getCountryRegion = (x) => {
    if (!x.country && !x.region) return "-";
    if (!x.country) return x.region;
    if (!x.region) return x.country;

    return x.country + "/" + x.region;
  };

  const companiesData = Companies.data(
    companies,
    getCreatedDate,
    getLastLogin,
    getCountryRegion,
  );

  const headings = Companies.headings();

  return { companiesData, headings, loading };
};
