import { useState } from "react";
import { Button, Form, Icon } from "semantic-ui-react";

export const EditableField = ({ label, data, onSave, className }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState(data);

  const toggleEditable = () => setIsEditable(!isEditable);

  return (
    <Form className={className}>
      <div className="settings__changer-container">
        <h3 className="text white">{label}</h3>
        <Form.Field className="settings__field">
          {isEditable ? (
            <input value={value} onChange={(e) => setValue(e.target.value)} />
          ) : (
            <span>{value}</span>
          )}
          {isEditable ? (
            <>
              <Button
                style={{ margin: "0 5px" }}
                primary
                onClick={toggleEditable}
              >
                Cancel
              </Button>
              <Button
                primary
                onClick={() => {
                  toggleEditable();
                  onSave(value);
                }}
              >
                Save
              </Button>
            </>
          ) : (
            <Icon
              className="settings__edit"
              name="edit"
              onClick={toggleEditable}
            />
          )}
        </Form.Field>
      </div>
    </Form>
  );
};
