import { useHistory } from "react-router-dom";

import { Tab } from "components/Tabs";

import { EmailSignForm } from "./EmailSignForm";
import { PhoneSignUpForm } from "./PhoneSignUpForm";

export const SignUpForms = ({ setUserInfo }) => {
  const history = useHistory();

  const handleEmailSubmit = (data) => {
    setUserInfo({ email: data.email, password: data.password });
    history.push("/signup/account");
  };

  const handlePhoneSubmit = (data) => {
    setUserInfo({ phoneNumber: data.phoneNumber });
    history.push("/signup/account");
  };

  return (
    <Tab.Container contentClassName="signup-form">
      <Tab.Item label="Email">
        <EmailSignForm onSubmitExternal={handleEmailSubmit} />
      </Tab.Item>
      <Tab.Item label="Phone">
        <PhoneSignUpForm onSubmitExternal={handlePhoneSubmit} />
      </Tab.Item>
    </Tab.Container>
  );
};
