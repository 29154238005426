import { Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import { RadarChart } from "components/RadarChart";
import { ExpandedRowLogs } from "components/Logs";
import { DataWidget } from "components/Widgets/DataWidget";

import * as DataAdapters from "utils/DataAdapters";
import { useVessels } from "hooks/useVessels";
import { useCaptainDetails } from "./hooks/useCaptainDetails";

export const CaptainExpandedRowData = ({ data, onExpand }) => {
  const vessels = useVessels();
  const history = useHistory();
  const details = useCaptainDetails(data);

  return (
    <tr className="expanded">
      <td colSpan={9}>
        <div
          style={{
            cursor: "pointer",
            textAlign: "right",
            marginBottom: "6px",
          }}
        >
          <Icon onClick={onExpand(-1)} name="delete" />
        </div>
        <div className="expanded__grid">
          <div className="expanded__grid-row">
            <div className="expanded__grid-column">
              <DataWidget
                data={DataAdapters.mapCaptainToDetails(data, vessels)}
                className="expanded__grid-widget expanded__grid-widget-small"
              />
              <DataWidget
                data={details || {}}
                className="expanded__grid-widget expanded__grid-widget-medium"
              />
            </div>
            <div className="expanded__grid-column">
              <DataWidget
                label="Statistics"
                data={DataAdapters.mapDataToStatistics(data.statistics)}
                className="expanded__grid-widget"
              />
            </div>
            <div className="expanded__grid-column">
              <div className="expanded__grid-chart">
                <RadarChart data={data} drawLabels width={250} height={195} />
              </div>
            </div>
          </div>
          <div className="expanded__grid-row">
            <div className="expanded__grid-column">
              <ExpandedRowLogs data={data} param="captain_id" hasIssues />
            </div>
            <div className="expanded__grid-column"></div>
            <div className="expanded__grid-column">
              <div className="expanded__grid-button-group justify-end">
                <button
                  onClick={() =>
                    history.push(`captain/description/${data?.id}`)
                  }
                  className="expanded__grid-button"
                >
                  View more
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
