import { useState } from "react";
import { Icon, Button } from "semantic-ui-react";

import { Input } from "components/Inputs";
import { Select } from "components/Dropdown";

import { deleteIssue } from "data/issues";
import { useIssueFormHandler } from "hooks/useIssueFormHandler";

const statuses = [
  { text: "Open", value: "open" },
  { text: "Closed", value: "closed" },
];

const severeties = [
  {
    text: "L",
    value: "L",
  },
  {
    text: "M",
    value: "M",
  },
  {
    text: "H",
    value: "H",
  },
];

const Issue = ({ id, data = null, captain, vessel }) => {
  const [disable, setDisable] = useState(true);
  const { register, errors, onSubmit } = useIssueFormHandler(
    id,
    data,
    vessel,
    captain,
    () => {
      setDisable(true);
    },
  );

  console.log(data);

  return (
    <form id="actifityForm" onSubmit={onSubmit} className="issue">
      <Select
        options={statuses}
        disabled={data && disable}
        prev={data?.status}
        className="issue__input"
        {...register("status")}
      />
      <Select
        options={severeties}
        disabled={data && disable}
        prev={data?.severety}
        className="issue__input"
        {...register("severety")}
      />
      <Input
        placeholder="Title"
        errors={errors?.title}
        disabled={data && disable}
        className="issue__input"
        {...register("title")}
      />
      <Input
        placeholder="Text"
        errors={errors?.text}
        disabled={data && disable}
        className="issue__input"
        {...register("text")}
      />
      {data && disable ? (
        <>
          <Icon
            name="edit"
            onClick={() => setDisable((prev) => !prev)}
            style={{ color: disable ? "#000" : "blue" }}
          />
          <Icon name="delete" onClick={() => deleteIssue(data?.id)} />
        </>
      ) : (
        <Button type="submit">
          <Icon name="check" />
        </Button>
      )}
    </form>
  );
};

export default Issue;
