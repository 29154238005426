import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import * as Color from "utils/Theme";

export const EmailSignForm = ({ isSignIn = false, onSubmitExternal }) => {
  const EmailSignSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password is required"),
    ...(isSignIn
      ? {}
      : {
          confirmPassword: Yup.string()
            .required()
            .oneOf([Yup.ref("password")], "Passwords do not match"),
        }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(EmailSignSchema),
  });

  const onSubmit = (values) => {
    onSubmitExternal(values);
    reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Field>
        <label>Email</label>
        <input
          placeholder="Email"
          name="email"
          type="email"
          {...register("email")}
        />
        <p style={{ color: Color.red }}>{errors.email?.message}</p>
      </Form.Field>
      <Form.Field>
        <label>Password</label>
        <input
          placeholder="Password"
          name="password"
          type="password"
          {...register("password")}
        />
        <p style={{ color: Color.red }}>{errors.password?.message}</p>
      </Form.Field>
      {!isSignIn && (
        <Form.Field>
          <label>Confirm password</label>
          <input
            placeholder="Confirm Password"
            name="confirmPassword"
            type="password"
            {...register("confirmPassword")}
          />
          <p style={{ color: Color.red }}>{errors.confirmPassword?.message}</p>
        </Form.Field>
      )}
      <Button primary fluid type="submit">
        Sign {isSignIn ? "in" : "up"}
      </Button>
    </Form>
  );
};
