import { Table } from "components/Table";
import { useCaptainsData } from "./hooks/useCaptainsData";
import { AddButton } from "components/AddButton";
import { Loader } from "components/Loader";
import { CaptainExpandedRowData } from "./CaptainExpandedRowData";
import logo from "../../assets/axopar.png";

export const CaptainsScreen = () => {
  const { captainsData, headings, loading, expandedRowData } =
    useCaptainsData();

  if (loading) return <Loader />;

  return (
    <div className="container">
      <div className="d-flex justify-between align-center">
        <h1 className="text white">Captains</h1>
        <img src={logo} width={200}/>
        <AddButton link="/captains/new" />
      </div>
      <Table
        headings={headings}
        data={captainsData}
        expandableData={expandedRowData}
        ExpandedRowContent={CaptainExpandedRowData}
        expandable
      />
    </div>
  );
};
