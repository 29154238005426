import React from "react";
import { Route } from "react-router-dom";

import { VesselsScreen } from "./Vessels";
import { VesselEditDialog } from "./modals/VesselEditDialog";
import { VesselDeleteDialog } from "./modals/VesselDeleteDialog";
import { VesselLinkingDialog } from "./VesselLinkingDialog";

export default function VesselsPage() {
  return (
    <div>
      <Route path="/vessels/new">
        {({ history, match }) => (
          <VesselEditDialog
            show={match != null}
            onHide={() => {
              history.push("/vessels");
            }}
          />
        )}
      </Route>
      <Route path="/vessels/:id/edit">
        {({ history, match }) => {
          return (
            <VesselEditDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/vessels");
              }}
            />
          );
        }}
      </Route>
      <Route path="/vessels/:id/delete">
        {({ history, match }) => (
          <VesselDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/vessels");
            }}
          />
        )}
      </Route>
      <Route path="/vessels/:id/link">
        {({ history, match }) => (
          <VesselLinkingDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/vessels");
            }}
          />
        )}
      </Route>
      <VesselsScreen />
    </div>
  );
}
