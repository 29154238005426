import React, { useEffect, useState, useContext } from "react";

import { Modal } from "components/Modal";
import { Select } from "components/Dropdown";
import { Input } from "components/Inputs";

import { AuthContext } from "utils/AuthContext";
import { useUserEditFormHandler } from "../hooks/useUserEditFormHandler";
import { userRoles } from "data/users";
import { getCompanies } from "data/companies";
import * as Color from "utils/Theme";
import * as DataAdapters from "utils/DataAdapters";
import { useGroups } from "hooks/useGroups";

export function UserEditDialog({ id, show, onHide }) {
  const { currentUser, reset, handleSubmit, register, errors, onSubmit } =
    useUserEditFormHandler(id, onHide);

  const { user, company } = useContext(AuthContext);
  const [companies, setCompanies] = useState([]);
  const groups = useGroups();

  let title = id ? "Edit User" : "Add User";

  useEffect(async () => {
    if (user.auth_role === "super") {
      let companies = await getCompanies();
      setCompanies(DataAdapters.mapCompaniesToOptions(companies));
    } else {
      register("companyId");
      reset({ companyId: company.id });
    }
  }, []);

  const handleClose = () => {
    reset({
      name: null,
      companyId: null,
      phoneNumber: null,
      group: null,
      organizationRoles: [],
      admin: false,
    });
    onHide();
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <h3>{title}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Name"
          placeholder="Name"
          name="name"
          {...register("name")}
        />
        {user.auth_role === "super" ? (
          <Select
            options={companies}
            label="Company"
            name="companyId"
            prev={
              companies.find(
                (company) => company.value === currentUser?.company_id,
              )?.text ?? "-"
            }
            errors={errors?.companyId}
            {...register("companyId")}
          />
        ) : (
          <Select
            options={DataAdapters.mapGroupsToOptions(groups)}
            label="Group"
            name="group"
            prev={
              groups.find((group) => group.id === currentUser?.group)?.name ??
              "-"
            }
            errors={errors?.group}
            {...register("group")}
          />
        )}
        <Input
          label="Phone number"
          placeholder="Phone number"
          name="phoneNumber"
          errors={errors?.phoneNumber}
          {...register("phoneNumber")}
        />
        <select
          {...register("organizationRoles")}
          id="roles"
          name="organizationRoles"
          multiple
        >
          <option value="" disabled selected hidden>
            Select organization roles
          </option>
          {userRoles &&
            userRoles.map((x) => {
              return (
                <option key={x.text} value={x.value}>
                  {x.text}
                </option>
              );
            })}
        </select>
        <p style={{ color: Color.red }}>{errors.org_roles?.message}</p>
        {user?.id !== id && user.auth_role === "admin" && (
          <>
            <input
              {...register("admin")}
              type="checkbox"
              id="admin"
              name="admin"
              value={true}
            />
            <label htmlFor="admin"> Admin</label>
            <p style={{ color: Color.red }}>{errors.admin?.message}</p>
          </>
        )}
        <input type="submit" value={id ? "Edit" : "Create"} />
      </form>
    </Modal>
  );
}
