import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";

export const AddButton = ({ link }) => {
  return (
    <div className="add-btn">
      <NavLink className="text white" to={link}>
        <Icon link name="plus" />
      </NavLink>
    </div>
  );
};
