import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { AuthContext } from "utils/AuthContext";
import { filterNavItems } from "utils/Navigation";

export const PrivateTemplate = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);
  const closeMenu = () => expanded && setExpanded(false);
  const navItems = filterNavItems(user);

  useEffect(() => {
    window.addEventListener("click", closeMenu);

    return () => {
      window.removeEventListener("click", closeMenu);
    };
  });

  return (
    <div className="private">
      <div className="sidebar__container">
        <div className="sidebar">
          {navItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              style={{ marginTop: item.name === "Settings" ? "auto" : 0 }}
            >
              <div className="sidebar__item">
                <Icon
                  name={item.icon}
                  size="large"
                  style={{ color: "#545D62" }}
                />
                <p>{item.name}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="private__content">{children}</div>
    </div>
  );
};
