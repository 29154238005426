import * as Color from "../utils/Theme";
import * as DateUtils from "../utils/DateUtils";

const speedThresholdInKnots = 0.5;
export const coloringValues = {
  IMPACT: "impact",
  COMFORT: "comfort",
};

export const getBoundsFromCoordinatePoints = (points, margin = 0.01) => {
  const Leaflet = window.L;

  if (points?.length == 0)
    return Leaflet.latLngBounds([
      [0.0, 0.0],
      [0.0, 0.0],
    ]);

  const lats = points?.map((x) => x.lat ?? 0);
  const lons = points?.map((x) => x.lon ?? 0);

  const point1 = [Math.min(...lats) - margin, Math.min(...lons) - margin];
  const point2 = [Math.max(...lats) + margin, Math.max(...lons) + margin];

  return Leaflet.latLngBounds([point1, point2]);
};

export const getColoredMapLineBySeconds = (
  seconds,
  coloringValue = coloringValues.IMPACT,
) => {
  let path = [[seconds[0].latitude, seconds[0].longitude]];
  let color;
  let pathColors = [];

  for (let index = 1; index < seconds.length; index++) {
    const second = seconds[index];

    if (vesselIsMoving(second)) {
      if (coloringValue === coloringValues.IMPACT) {
        let impact = Math.abs(second.imu_1.impact);
        color = getColorForImpactValue(impact);
      }
      if (coloringValue === coloringValues.COMFORT) {
        let comfort = second.button;
        color = getColorForComfortValue(comfort);
      }

      if (locationIsValid(second.latitude, second.longitude)) {
        path.push([second.latitude, second.longitude]);
        pathColors.push({
          id: second.id,
          positions: path,
          color: color,
          time: DateUtils.prettyDateSeconds(second.time?.toDate().toString()),
          rog: second.rog?.toFixed(1),
          sog: second.sog?.toFixed(1),
          impact: second.imu_1.impact?.toFixed(1),
        });
        path = [[second.latitude, second.longitude]];
      }
    }
  }

  return pathColors;
};

export const getColoredMapLineByMinutes = (
  minutes,
  coloringValue = coloringValues.IMPACT,
) => {
  let path = [];
  let color;
  let pathColors = [];

  minutes.forEach((minute) => {
    if (vesselIsMoving(minute)) {
      if (coloringValue === coloringValues.IMPACT) {
        let impact = Math.abs(minute.imu_1.max_impact);
        color = getColorForImpactValue(impact);
      }
      if (coloringValue === coloringValues.COMFORT) {
        let comfort = minute.button;
        color = getColorForComfortValue(comfort);
      }

      for (let i = 0; i < minute.latitudes.length; i++) {
        if (locationIsValid(minute.latitudes[i], minute.longitudes[i])) {
          path.push([minute.latitudes[i], minute.longitudes[i]]);
        }
      }

      pathColors.push({
        id: minute.id,
        positions: path,
        color: color,
        time: DateUtils.prettyDate(minute.time?.toDate().toString()),
        rog: minute.avg_rog?.toFixed(1),
        sog: minute.avg_sog?.toFixed(1),
        impact: minute.imu_1.max_impact?.toFixed(1),
      });
      path = [];
      path.push([
        minute.latitudes[minute.latitudes.length - 1],
        minute.longitudes[minute.longitudes.length - 1],
      ]);
    }
  });

  pathColors.push({
    id: minutes[minutes.length - 1].id,
    positions: path,
    color: color,
    time: minutes[minutes.length - 1].time,
    rog: minutes[minutes.length - 1].rog?.toFixed(1),
    sog: minutes[minutes.length - 1].sog?.toFixed(1),
    impact: minutes[minutes.length - 1].imu_1.max_impact?.toFixed(1),
  });

  return pathColors;
};

export const getColoredMapLine = (
  data,
  coloringValue = coloringValues.IMPACT,
) => {
  if (data.length === 0) return [];

  let path = [];
  let color;
  let pathColors = [];

  data.forEach((x) => {
    if (vesselIsMoving(x)) {
      if (coloringValue === coloringValues.IMPACT) {
        let impact = Math.abs(x.imu_1.max_impact ?? x.imu_1.impact);
        color = getColorForImpactValue(impact);
      }
      if (coloringValue === coloringValues.COMFORT) {
        let comfort = x.button;
        color = getColorForComfortValue(comfort);
      }

      if (x.latitude) {
        if (locationIsValid(x.latitude, x.longitude)) {
          path.push([x.latitude, x.longitude]);
        }
      } else {
        for (let i = 0; i < x.latitudes?.length; i++) {
          if (locationIsValid(x.latitudes[i], x.longitudes[i])) {
            path.push([x.latitudes[i], x.longitudes[i]]);
          }
        }
      }

      pathColors.push({
        type: x.avg_rog ? "minute" : "second",
        id: x.id,
        positions: path,
        color: color,
        time: x.avg_rog
          ? DateUtils.prettyDate(x.time?.toDate().toString())
          : DateUtils.prettyDateSeconds(x.time?.toDate().toString()),
        rog: x.avg_rog?.toFixed(1) ?? x.rog?.toFixed(1),
        sog: x.avg_sog?.toFixed(1) ?? x.sog?.toFixed(1),
        cog: x.cog,
        impact: x.imu_1.max_impact?.toFixed(1) ?? x.imu_1.impact?.toFixed(1),
        sea_state: x.weather?.sea_state,
        temperature: x.weather?.temperature?.toFixed(1),
        wind_direction: x.weather?.wind_direction,
        wind_speed: x.weather?.wind_speed?.toFixed(1),
      });
      path = [];
      if (x.latitude) {
        if (locationIsValid(x.latitude, x.longitude)) {
          path.push([x.latitude, x.longitude]);
        }
      } else {
        if (
          locationIsValid(
            x.latitudes[x.latitudes.length - 1],
            x.longitudes[x.longitudes.length - 1],
          )
        ) {
          path.push([
            x.latitudes[x.latitudes.length - 1],
            x.longitudes[x.longitudes.length - 1],
          ]);
        }
      }
    }
  });

  let last = data[data.length - 1];

  pathColors.push({
    type: last.avg_rog ? "minute" : "second",
    id: last.id,
    positions: path,
    color: color,
    time: last.avg_rog
      ? DateUtils.prettyDate(last.time?.toDate().toString())
      : DateUtils.prettyDateSeconds(last.time?.toDate().toString()),
    rog: last.avg_rog?.toFixed(1) ?? last.rog?.toFixed(1),
    sog: last.avg_sog?.toFixed(1) ?? last.sog?.toFixed(1),
    cog: last.cog,
    impact: last.imu_1.max_impact?.toFixed(1) ?? last.imu_1.impact?.toFixed(1),
    sea_state: last.weather?.sea_state,
    temperature: last.weather?.temperature?.toFixed(1),
    wind_direction: last.weather?.wind_direction,
    wind_speed: last.weather?.wind_speed?.toFixed(1),
  });

  return pathColors;
};

export const locationIsValid = (latitude, longitude) => {
  return (
    !isNaN(latitude) &&
    !isNaN(longitude) &&
    latitude !== 0 &&
    latitude !== 214.7483646 &&
    longitude !== 0 &&
    longitude !== 214.7483646
  );
};

export const vesselIsMoving = (data) => {
  if (data.avg_sog) return data.avg_sog > speedThresholdInKnots;
  return data.sog > speedThresholdInKnots;
};

const getColorForImpactValue = (impact) => {
  if (impact <= 1.0) return Color.green;
  else if (impact > 1.0 && impact <= 3.0) return Color.yellow;
  return Color.red;
};

const getColorForComfortValue = (comfort) => {
  if (comfort === "g") return Color.green;
  else if (comfort === "y") return Color.yellow;
  return Color.red;
};

export const calculateTripDistance = (tripData) => {
  if (tripData.length === 0) return 0;

  let distances = [];

  let latitudes = tripData
    .filter((x) => x.avg_sog > 0.5)
    .map((x) => x.latitudes)
    .flat();
  let longitudes = tripData
    .filter((x) => x.avg_sog > 0.5)
    .map((x) => x.longitudes)
    .flat();

  for (let i = 1; i < latitudes.length; i++) {
    let lat1 = latitudes[i - 1];
    let lat2 = latitudes[i];
    let lon1 = longitudes[i - 1];
    let lon2 = longitudes[i];

    if ((lat1 === 0 && lon1 === 0) || (lat2 === 0 && lon2 === 0)) continue;

    let dist = distance(lat1, lat2, lon1, lon2);

    distances.push(dist);
  }

  if (distances.length === 0) return 0;

  return distances.reduce((a, b) => a + b).toFixed(2) + "nmi";
};

const distance = (lat1, lat2, lon1, lon2) => {
  if (!locationIsValid(lat1, lon1) || !locationIsValid(lat2, lon2)) return 0;

  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  lon1 = (lon1 * Math.PI) / 180;
  lon2 = (lon2 * Math.PI) / 180;
  lat1 = (lat1 * Math.PI) / 180;
  lat2 = (lat2 * Math.PI) / 180;

  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in
  // Use 3440 for nautical miles
  // Use 6371 for kilometers
  // Use 3956 for miles
  let r = 3440;

  // calculate the result
  return c * r;
};
