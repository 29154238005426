import { Button, Icon } from "semantic-ui-react";

import { VesselMap, VesselMapLoader } from "components/VesselMap";
import { Loader } from "components/Loader";

import { useLiveConsole } from "./useLiveConsole";
import { LiveConsolePinnedTrips } from "./LiveConsolePinnedTrips";
import { LiveConsoleOngoingTrips } from "./LiveConsoleOngoingTrips";
import { LiveConsoleTodaysTrips } from "./LiveConsoleTodaysTrips";
import { LiveConsoleChart } from "./LiveConsoleChart";
import { DashboardTripPreview } from "./DashboardTripPreview";

export const LiveConsoleScreen = () => {
  const {
    todaysTrips,
    pinnedTrips,
    minute,
    playback,
    showChart,
    showFullTrip,
    vessels,
    setMapRef,
    handlePolylineClick,
    company,
    user,
    ongoingTrips,
    focusTrips,
    focusTripData,
    loading,
    secondData,
    filterFocusTripData,
    handleChartClick,
    toggleShowChart,
    resetFocusTrips,
    handlePlayback,
    handleDelete,
    displayChart,
    handleZoom,
    isTrackingOngoingTrips,
    toggleTrackingOngoingTrips,
    showDashboard,
    toggleShowDashboard,
    dashboardData,
    vessel,
    resetHoveredTripSlice,
  } = useLiveConsole();

  if (!company || !user) return <Loader />;

  return (
    <>
      {todaysTrips && <LiveConsoleTodaysTrips todaysTrips={todaysTrips} />}
      <div className="console__trips">
        {ongoingTrips &&
          ongoingTrips
            .filter(
              ({ end_location }) =>
                end_location.lat !== 0 && end_location.lon !== 0,
            )
            .map((ongoingTrip) => (
              <LiveConsoleOngoingTrips
                key={ongoingTrip.id}
                ongoingTrip={ongoingTrip}
                focusTrips={focusTrips}
                resetFocusTrips={resetFocusTrips}
                vessels={vessels}
                displayChart={displayChart}
                handlePlayback={handlePlayback}
                playback={playback}
              />
            ))}
        {pinnedTrips &&
          pinnedTrips.map((pinnedTrip) => (
            <LiveConsolePinnedTrips
              key={pinnedTrip.id}
              focusTrips={focusTrips}
              resetFocusTrips={resetFocusTrips}
              vessels={vessels}
              pinnedTrip={pinnedTrip}
              displayChart={displayChart}
              handlePlayback={handlePlayback}
              playback={playback}
              handleDelete={handleDelete}
              handleShowDashboard={toggleShowDashboard}
            />
          ))}
      </div>
      <div className="console__btn-container">
        <Button icon className="console__btn">
          <Icon
            name={isTrackingOngoingTrips ? "eye" : "eye slash"}
            onClick={toggleTrackingOngoingTrips}
          />
        </Button>
      </div>
      {loading ? (
        <VesselMapLoader />
      ) : (
        focusTripData && (
          <div className="console-container">
            <VesselMap
              setMapRef={setMapRef}
              ongoingTrips={ongoingTrips}
              focusTripData={filterFocusTripData(focusTripData, focusTrips)}
              secondData={secondData}
              onPolylineClick={handlePolylineClick}
              playback={playback}
              handleZoom={handleZoom}
              onSliceHover={resetHoveredTripSlice}
            />
            {showChart && (
              <LiveConsoleChart
                minute={minute}
                vessel={vessel}
                toggleShowChart={toggleShowChart}
                showFullTrip={showFullTrip}
                handleChartClick={handleChartClick}
                playback={playback}
              />
            )}
            {showDashboard && <DashboardTripPreview trip={dashboardData} />}
          </div>
        )
      )}
    </>
  );
};
