import { db } from "utils/Firebase";

const ISSUES_COLLECTION = db.collection("issues");

export const getAllIssuesSubscription = async (id, param, callback) => {
  return ISSUES_COLLECTION.where(param, "==", id)
    .where("visible", "==", true)
    .onSnapshot((issueSnap) => {
      callback(
        issueSnap.docs.map((issue) => {
          return issue.data();
        }),
      );
    });
};

export const createIssue = async (id, data) => {
  const issueCollectionRef = ISSUES_COLLECTION.doc();
  await issueCollectionRef.set({
    id: issueCollectionRef.id,
    trip_id: id,
    visible: true,
    ...data,
  });
};

export const editIssue = async (data) => {
  const issueCollectionRef = ISSUES_COLLECTION.doc(data.id);
  await issueCollectionRef.update(data);
};

export const deleteIssue = (id) => {
  ISSUES_COLLECTION.doc(id).update({ visible: false });
};
