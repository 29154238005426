import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import * as Color from "utils/Theme";
import * as DateUtils from "utils/DateUtils";
import * as DataAdapters from "./DataAdapters";
import * as TripInformation from "utils/TripInformation";

import { Alert } from "components/Utils";
import { RadarChart } from "components/RadarChart";

const data_placeholder = "-";

export const Companies = {
  data: (companies, getCreatedDate, getLastLogin, getCountryRegion) =>
    companies?.length
      ? companies.map((company) => [
          company.name,
          company.contact,
          getCreatedDate(company),
          getLastLogin(company),
          getCountryRegion(company),
          company.statistics?.number_of_trips ?? data_placeholder,
          company.statistics?.number_of_vessels ?? data_placeholder,
          <>
            <span title="Edit">
              <NavLink
                style={{ color: "white" }}
                to={`/companies/${company.id}/edit`}
              >
                <Icon link name="edit" />
              </NavLink>
            </span>
            <span title="Delete">
              <NavLink
                style={{ color: "white" }}
                to={`/companies/${company.id}/delete`}
              >
                <Icon link name="trash" />
              </NavLink>
            </span>
          </>,
        ])
      : [],
  headings: () => [
    "Name",
    "Contact",
    "Created at",
    "Last Login",
    "Country/Region",
    "# of trips",
    "# of vessels",
    "Actions",
  ],
};

export const Users = {
  data: (users, currentUser, getCompanyName, getOrganizationRoles, allowEdit) =>
    users?.length
      ? users.map((user) => [
          user.name,
          getCompanyName(user),
          user.phone_number,
          user.email ?? "-",
          getOrganizationRoles(user),

          ...(allowEdit
            ? [
                <>
                  <span title="Edit">
                    <NavLink
                      style={{ color: "white" }}
                      to={`/users/${user.id}/edit`}
                    >
                      <Icon link name="edit" />
                    </NavLink>
                  </span>
                  {user?.id !== currentUser?.id && (
                    <span title="Delete">
                      <NavLink
                        style={{ color: "white" }}
                        to={`/users/${user.id}/delete`}
                      >
                        <Icon link name="trash" />
                      </NavLink>
                    </span>
                  )}
                </>,
              ]
            : []),
        ])
      : [],
  headings: (allowEdit, isSuper) => [
    "Name",
    isSuper ? "Company" : "Group",
    "Phone Number",
    "Email",
    "Organizational Roles",
    ...(allowEdit ? ["Actions"] : []),
  ],
};

export const Captains = {
  data: (captains, isSuper) =>
    captains?.length
      ? captains.map((captain) => [
          <div style={{ width: 40 }} key={captain.name}>
            <RadarChart data={captain} padding={5} usePlaceholder={true} />
          </div>,
          captain.name,
          captain.statistics?.number_of_trips ?? data_placeholder,
          captain.statistics?.avg_sog?.toFixed(1) ?? data_placeholder,
          captain.statistics?.avg_rog?.toFixed(1) ?? data_placeholder,
          captain.statistics?.avg_impact?.toFixed(1) ?? data_placeholder,
          captain.statistics?.peak_impact?.toFixed(1) ?? data_placeholder,
          captain.statistics?.red_impacts ?? data_placeholder,

          ...(isSuper
            ? [
                <>
                  <span title="Edit">
                    <NavLink
                      onClick={(e) => e.stopPropagation()}
                      style={{ color: "white" }}
                      to={`/captains/${captain.id}/edit`}
                    >
                      <Icon link name="edit" />
                    </NavLink>
                  </span>
                  <span title="Delete">
                    <NavLink
                      onClick={(e) => e.stopPropagation()}
                      style={{ color: "white" }}
                      to={`/captains/${captain.id}/delete`}
                    >
                      <Icon link name="trash" />
                    </NavLink>
                  </span>
                </>,
              ]
            : []),
        ])
      : [],
  headings: (isSuper) => [
    "",
    "Name",
    "# of trips",
    "Avg SOG",
    "Avg ROG",
    "Avg Impact",
    "Peak Impact",
    "Red Impacts",
    ...(isSuper ? ["Actions"] : []),
  ],
};

export const Vessels = {
  data: (vessels, captains, trips, allowEdit) =>
    vessels?.length
      ? vessels.map((vessel) => [
          <div style={{ width: 40 }} key={vessel.name}>
            <RadarChart data={vessel} padding={5} usePlaceholder={true} />
          </div>,
          vessel.name,
          <label
            key={vessel.device_id}
            style={{
              textAlign: "center",
              borderRadius: "4px",
              padding: "5px",
              color: "white",
              backgroundColor: vessel.device_id ? Color.green : Color.red,
            }}
          >
            {vessel.device_id ? "Paired" : "Unpaired"}
          </label>,
          DataAdapters.findRegularCaptain(trips, vessel, captains),
          vessel.statistics
            ? vessel.statistics?.number_of_trips
            : data_placeholder,
          vessel.statistics
            ? (+vessel.statistics?.avg_rog)?.toFixed(1)
            : data_placeholder,
          vessel.statistics
            ? (+vessel.statistics?.avg_sog)?.toFixed(1)
            : data_placeholder,
          vessel.statistics
            ? (+vessel.statistics?.avg_impact)?.toFixed(1)
            : data_placeholder,

          ...(allowEdit
            ? [
                <div onClick={(e) => e.stopPropagation()} key={vessel.name}>
                  {!vessel.device_id && (
                    <span title="Link">
                      <NavLink
                        style={{ color: "white" }}
                        to={`/vessels/${vessel.id}/link`}
                      >
                        <Icon link name="linkify" />
                      </NavLink>
                    </span>
                  )}
                  <span title="Edit">
                    <NavLink
                      style={{ color: "white" }}
                      to={`/vessels/${vessel.id}/edit`}
                    >
                      <Icon link name="edit" />
                    </NavLink>
                  </span>
                  <span title="Delete">
                    <NavLink
                      style={{ color: "white" }}
                      to={`/vessels/${vessel.id}/delete`}
                    >
                      <Icon link name="trash" />
                    </NavLink>
                  </span>
                </div>,
              ]
            : []),
        ])
      : [],
  headings: (allowEdit) => [
    "",
    "Name",
    "Registered",
    "Captain",
    "# of trips",
    "Avg ROG",
    "Avg SOG",
    "Avg Impact",
    ...(allowEdit ? ["Actions"] : []),
  ],
};

export const Trips = {
  data: (trips, vessels, captains, pinnedTrips, onPinTrip) => {
    return trips?.length
      ? trips.map((trip, ndx) => [
          <div style={{ width: 40 }} key={ndx}>
            <RadarChart data={trip} padding={5} usePlaceholder={true} />
          </div>,
          vessels?.find((v) => v.id === trip.vessel_id)?.name ??
            data_placeholder,
          captains?.find((c) => c.id === trip.captain)?.name ??
            data_placeholder,
          DateUtils.prettyDate(trip.start.toDate().toString()),
          DateUtils.prettyDuration(trip),
          TripInformation.getPeakImpact(trip),
          TripInformation.getAverageROGoverSOG(trip),
          <div key={ndx} className="d-flex align-center">
            {trip.red_impacts > 0 && <Alert status="error" />}
            {(trip?.log || trip?.issues?.length > 0) && (
              <span>Expand to see logs</span>
            )}
          </div>,
          <div onClick={(e) => e.stopPropagation()} key={ndx}>
            <span title="Edit">
              <Icon
                link
                name="pin"
                style={{
                  color: pinnedTrips.find((t) => t.id === trip.id)
                    ? Color.blue
                    : "white",
                }}
                onClick={onPinTrip(trip)}
              />
            </span>
            <span title="Edit">
              <NavLink className="text white" to={`/trips/${trip.id}/edit`}>
                <Icon link name="edit" />
              </NavLink>
            </span>
            <span title="Delete">
              <NavLink className="text white" to={`/trips/${trip.id}/delete`}>
                <Icon link name="trash" />
              </NavLink>
            </span>
            {/* <span title="Download">
              <Icon link name="download" onClick={async () => {

                const storage = firebase.storage()
                const fileRef = storage.refFromURL('gs://mk2-prod-csv-download/'+trip.id+'.csv.gz')

                await fileRef.getDownloadURL()
              }}/>
            </span> */}
          </div>,
        ])
      : [];
  },
  headings: () => [
    "",
    "Vessel",
    "Captain",
    "Date",
    "Duration",
    "Peak Impact",
    "Safety Quality",
    "Alerts/Issues",
    "Actions",
  ],
};
