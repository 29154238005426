import { forwardRef, useState } from "react";
import { Icon } from "semantic-ui-react";

const Select = (
  {
    label = "",
    options,
    name,
    id,
    className = "",
    disabled = false,
    prev = null,
    error,
    ...props
  },
  ref,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const onSelect = (e, option) => {
    e.target.name = name;
    e.target.value = option.value;
    props.onChange(e);
    props.onBlur(e);
    setSelected(option);
    setIsOpen(false);
  };

  return (
    <div className={`select ${className}`}>
      {label && (
        <label htmlFor={id} className="select__label">
          {label}
        </label>
      )}
      <div
        className={`select__container ${disabled && "disabled"}`}
        onClick={disabled ? null : () => setIsOpen((prev) => !prev)}
      >
        <span>{prev && !selected ? prev : selected?.text}</span>
        <Icon name={`angle ${isOpen ? "up" : "down"}`} />
      </div>
      <div
        className={`select__options ${isOpen ? "visible" : "closed"}`}
        ref={ref}
      >
        {options?.map(({ text, value }) => (
          <div
            className="select__options-item"
            key={value}
            name={name}
            onClick={(e) => onSelect(e, { text, value })}
          >
            {text}
          </div>
        ))}
      </div>
      {error && <span className="input__error">{error?.message}</span>}
    </div>
  );
};

export default forwardRef(Select);
