import { ChartWidget } from "../ChartWidget";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export const EfficiencyWidget = ({ item, hasLink = true }) => {
  return (
    <div className="efficiency-widget">
      {hasLink && (
        <span>
          <h3>Efficiency</h3>
          <Link to={item.link}>
            <Icon name="arrow right" style={{ color: "white" }} />
          </Link>
        </span>
      )}
      <ChartWidget item={item} type={"efficiency"} aspectRatio={6.0} />
    </div>
  );
};
