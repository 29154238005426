import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export const AlertWidget = ({ link = "", alerts }) => {
  return (
    <div className="alert-widget">
      <span>
        <h3>Alerts</h3>
        {link && (
          <Link to={link}>
            <Icon name="arrow right" style={{ color: "white" }} />
          </Link>
        )}
      </span>
      {alerts.map((a, ndx) => {
        return (
          <div key={ndx}>
            <div className="alert-widget-row">
              <Icon
                style={{ color: a.color, marginBottom: "5px" }}
                name={a.icon}
              />
              <p>{a.message}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
