import React, { useEffect, useState } from "react";
import firebase from "./Firebase";
import { getUserById, getUserSubscription } from "data/users";
import {
  getCompany,
  getCompanySubscription,
  updateCompanyLastLogin,
} from "data/companies";

export const AuthContext = React.createContext();

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  let unsubscribeToUser;
  let unsubscribeToCompany;

  const setUserUnauthorized = () => {
    setLoadingAuthState(false);
    setUser(null);
    setCompany(null);
    firebase.auth().signOut();
  };

  const startUserListener = (id) => {
    unsubscribeToUser = getUserSubscription(id, (user) => {
      const isPhoneProvider =
        firebase.auth().currentUser?.providerData[0]?.providerId === "phone";
      setUser({
        ...user,
        ...(isPhoneProvider
          ? {}
          : { emailVerified: firebase.auth().currentUser?.emailVerified }),
      });
    });
  };

  const startCompanyListener = (id) => {
    unsubscribeToCompany = getCompanySubscription(id, (company) => {
      setCompany(company);
    });
  };

  const startAuthListener = () => {
    firebase.auth().onAuthStateChanged(async (authUser) => {
      if (authUser) {
        const isPhoneProvider =
          firebase.auth().currentUser?.providerData[0]?.providerId === "phone";
        const currentUser = await getUserById(authUser.uid);
        const company = await getCompany(currentUser.company_id);
        if (currentUser) {
          setUser({
            ...currentUser,
            ...(isPhoneProvider
              ? {}
              : { emailVerified: authUser.emailVerified }),
          });
          setCompany(company);
          setLoadingAuthState(false);
          startUserListener(currentUser.id);
          updateCompanyLastLogin(currentUser.company_id);
          startCompanyListener(currentUser.company_id);
        } else {
          setUserUnauthorized();
        }
      } else {
        setUserUnauthorized();
      }
    });
  };

  useEffect(() => {
    startAuthListener();
    return () => {
      unsubscribeToUser();
      unsubscribeToCompany();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        company,
        isAuthorized: user !== null,
        isSuperAdmin: user?.auth_role === "super",
        isAdmin: user?.auth_role === "super" || user?.auth_role === "admin",
        loadingAuthState,
        currentUser: firebase.auth().currentUser,
        setLoadingAuthState,
        startAuthListener,
      }}
      {...props}
    />
  );
};
