import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import { RadarChart } from "components/RadarChart";
import { ExpandedRowLogs } from "components/Logs";
import { DataWidget } from "components/Widgets/DataWidget";

import * as DataAdapters from "utils/DataAdapters";
import { useTrips } from "hooks/useTrips";
import { useCaptains } from "hooks/useCaptains";

export const VesselExpandedRowData = ({ data, onExpand }) => {
  const captains = useCaptains();
  const trips = useTrips();
  const history = useHistory();

  if (!data.device_id) return null;

  return (
    <tr className="expanded">
      <td colSpan={9}>
        <div
          style={{
            cursor: "pointer",
            textAlign: "right",
            marginBottom: "6px",
          }}
        >
          <Icon onClick={onExpand(-1)} name="delete" />
        </div>
        <div className="expanded__grid">
          <div className="expanded__grid-row">
            <div className="expanded__grid-column">
              <DataWidget
                data={[
                  {
                    label: "Captain",
                    data: DataAdapters.findRegularCaptain(
                      trips,
                      data,
                      captains,
                    ),
                  },
                  { label: "Vessel", data: data?.name ?? "-" },
                ]}
                className="expanded__grid-widget expanded__grid-widget-small"
              />
              <DataWidget
                data={DataAdapters.mapVesselToDetails(data)}
                className="expanded__grid-widget expanded__grid-widget-medium"
              />
            </div>
            <div className="expanded__grid-column">
              <DataWidget
                label={"Statistics"}
                data={DataAdapters.mapDataToStatistics(data.statistics)}
                className="expanded__grid-widget"
              />
            </div>
            <div className="expanded__grid-column">
              <div className="expanded__grid-chart">
                <RadarChart data={data} drawLabels width={250} height={195} />
              </div>
            </div>
          </div>
          <div className="expanded__grid-row">
            <div className="expanded__grid-column">
              <ExpandedRowLogs
                data={data}
                captain={DataAdapters.findRegularCaptain(trips, data, captains)}
                vessel={data?.name}
                param="vessel_id"
                hasIssues
              />
            </div>
            <div className="expanded__grid-column"></div>
            <div className="expanded__grid-column">
              <div className="expanded__grid-button-group justify-end">
                <button
                  onClick={() => history.push(`vessel/description/${data?.id}`)}
                  className="expanded__grid-button"
                >
                  View more
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
