import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { updateCaptain, createCaptain } from "data/captains";

const CaptainEditSchema = yup
  .object()
  .shape({
    name: yup
      .string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Name is required"),
    user_id: yup.string().nullable(),
    group: yup.string().nullable(),
  })
  .required();

export const useCaptainsEditFormHandler = (id, company, onHide) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(CaptainEditSchema),
  });

  const onSubmit = async (values) => {
    if (id) {
      await updateCaptain(id, { ...values, company_id: company.id });
    } else {
      await createCaptain({ ...values, company_id: company.id });
    }
    reset({ name: null, user_id: null });
    onHide();
  };

  return { getValues, register, handleSubmit, errors, onSubmit, reset };
};
