import { CountryRegionData } from "react-country-region-selector";

import { Modal } from "components/Modal";
import { Input } from "components/Inputs";
import { Select } from "components/Dropdown";

import * as DataAdapters from "utils/DataAdapters";
import { useCompanyEditFormHandler } from "../hooks/useCompanyEditFormHandler";

export function CompanyEditDialog({ id, show, onHide }) {
  const { register, handleSubmit, onSubmit, errors, reset, watch, company } =
    useCompanyEditFormHandler(id, onHide);

  const { countries, findRegionByCountry } =
    DataAdapters.mapLocationsToOptions(CountryRegionData);

  let title = id ? "Edit Company" : "Add Company";

  const handleClose = () => {
    reset({
      name: null,
      contact: null,
    });
    onHide();
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <h3>{title}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Name"
          placeholder="Name"
          name="name"
          errors={errors?.name}
          {...register("name")}
        />
        <Input
          label="Contact"
          placeholder="Contact"
          name="contact"
          errors={errors?.contact}
          {...register("contact")}
        />
        <h3>Country/Region</h3>
        <Select
          label="Country"
          options={countries}
          prev={company?.country ?? "-"}
          {...register("country")}
        />
        <Select
          label="Region"
          options={findRegionByCountry(watch("country"))}
          prev={company?.region ?? "-"}
          {...register("region")}
        />
        <input type="submit" value={id ? "Edit" : "Create"} />
      </form>
    </Modal>
  );
}
