import { useEffect, useState } from "react";

export const useLoading = (...data) => {
  const [loading, setLoading] = useState(true);
  const [dataTimeout, setDataTimeout] = useState(null);

  useEffect(() => {
    if (loading) {
      setLoading(data.filter((arr) => !!arr.length).length !== data.length);
    }
  }, [data]);

  useEffect(() => {
    setDataTimeout(
      setTimeout(() => {
        setLoading(false);
      }, 2000),
    );

    return () => dataTimeout && clearTimeout(dataTimeout);
  }, []);

  return loading;
};
