import React from "react";
import { Route } from "react-router-dom";

import { CompaniesScreen } from "./Companies";
import { CompanyDeleteDialog } from "./modals/CompanyDeleteDialog";
import { CompanyEditDialog } from "./modals/CompanyEditDialog";

export default function CompaniesPage() {
  return (
    <div>
      <Route path="/companies/new">
        {({ history, match }) => (
          <CompanyEditDialog
            show={match != null}
            onHide={() => {
              history.push("/companies");
            }}
          />
        )}
      </Route>
      <Route path="/companies/:id/edit">
        {({ history, match }) => (
          <CompanyEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/companies");
            }}
          />
        )}
      </Route>
      <Route path="/companies/:id/delete">
        {({ history, match }) => (
          <CompanyDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/companies");
            }}
          />
        )}
      </Route>
      <CompaniesScreen />
    </div>
  );
}
