import "semantic-ui-css/semantic.min.css";

import { Routes } from "./utils/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./utils/AuthContext";
import { DataProvider } from "./utils/DataContext";
import { createBrowserHistory } from "history";
import * as Color from "./utils/Theme";

export const AppWrapper = () => {
  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <App />
    </Router>
  );
};

export default function App() {
  return (
    <div style={{ backgroundColor: Color.backgroundColor }}>
      <AuthProvider>
        <DataProvider>
          <Routes />
        </DataProvider>
      </AuthProvider>
    </div>
  );
}
