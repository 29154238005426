import { AddButton } from "components/AddButton";
import { Loader } from "components/Loader";
import { Table } from "components/Table";
import { useVesselsData } from "./hooks/useVesselsData";
import { VesselExpandedRowData } from "./VesselExpandedRowData";
import logo from "../../assets/axopar.png";

export const VesselsScreen = () => {
  const { expandedRowData, headings, vesselsData, loading } = useVesselsData();

  if (loading) return <Loader />;

  return (
    <div className="container">
      <div className="d-flex justify-between align-center">
        <h1 className="text white">Vessels</h1>
        <img src={logo} width={200}/>
        <AddButton link="/vessels/new" />
      </div>
      <Table
        headings={headings}
        data={vesselsData}
        expandableData={expandedRowData}
        ExpandedRowContent={VesselExpandedRowData}
        expandable
      />
    </div>
  );
};
