import { useState, useEffect, useContext } from "react";

import { AuthContext } from "utils/AuthContext";
import {
  getCaptainsSubscription,
  getAllCaptainsSubscription,
  getCaptain,
} from "data/captains";

export const useCaptains = () => {
  const [captains, setCaptains] = useState([]);
  const { user, company } = useContext(AuthContext);
  const isSuper = user.auth_role === "super";

  let unsubscribeToCaptains;

  useEffect(() => {
    (async () => {
      await getData();
    })();
    return () => {
      if (unsubscribeToCaptains) unsubscribeToCaptains();
    };
  }, []);

  const getData = async () => {
    if (isSuper) {
      unsubscribeToCaptains = await getAllCaptainsSubscription((captains) => {
        setCaptains(captains);
      });
    } else {
      unsubscribeToCaptains = await getCaptainsSubscription(
        company.id,
        (captains) => {
          setCaptains(captains);
        },
      );
    }
  };

  return captains;
};

export const useCaptain = (id) => {
  const [captain, setCaptain] = useState({});

  useEffect(() => {
    if (id) {
      (async () => {
        const captain = await getData();
        setCaptain(captain);
      })();
    }
  }, [id]);

  const getData = async () => {
    return await getCaptain(id);
  };

  return captain;
};
