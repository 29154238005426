import { updateUser } from "data/users";
import { useState } from "react";
import firebase from "utils/Firebase";

export const useChangePhone = (user) => {
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [id, setId] = useState();
  const [isEnteringCode, setIsEnteringCode] = useState(false);
  const [confirmCode, setConfirmCode] = useState("");

  const handleProceedToEnterCode = async () => {
    setIsEditingPhone(false);
    setIsEnteringCode(true);
    const verifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      },
    );
    const phoneProvider = new firebase.auth.PhoneAuthProvider();
    setId(await phoneProvider.verifyPhoneNumber(phoneNumber, verifier));
  };

  const handleChangePhone = async () => {
    await user.updatePhoneNumber(
      firebase.auth.PhoneAuthProvider.credential(id, confirmCode),
    );
    await updateUser(user.uid, { phoneNumber });
    setIsEnteringCode(false);
  };

  return {
    handleChangePhone,
    isEditingPhone,
    phoneNumber,
    setPhoneNumber,
    setIsEditingPhone,
    isEnteringCode,
    confirmCode,
    setConfirmCode,
    handleProceedToEnterCode,
  };
};
