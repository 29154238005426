export const DataWidget = ({ data, label, className = "" }) => {
  return (
    <div className={`details ${className}`}>
      {label && (
        <div className="details__header">
          <h3>{label}</h3>
        </div>
      )}
      <div className="details__content">
        {data?.map(({ label, data }, ndx) => (
          <div className="details__content-element" key={ndx}>
            <span className="details__content-label">{label}</span>
            <span className="details__content-data">{data}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
