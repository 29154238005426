import { Icon } from "semantic-ui-react";

import * as DateUtils from "utils/DateUtils";

export const LiveConsoleOngoingTrips = ({
  ongoingTrip,
  focusTrips,
  resetFocusTrips,
  vessels,
  displayChart,
  playback,
  handlePlayback,
}) => {
  const shouldDisplay =
    ongoingTrip.end_location?.lat > 0 || ongoingTrip.end_location?.lng > 0;

  return (
    shouldDisplay && (
      <div className="trips-container">
        <div
          className={`console__trips-trip ${
            focusTrips.find((t) => t.id === ongoingTrip.id) ? "focused" : ""
          }`}
          onClick={resetFocusTrips(ongoingTrip)}
        >
          <div>
            <Icon name="ship" />
            {vessels.find((v) => v.id === ongoingTrip?.vessel_id)?.name ?? "-"},
            {DateUtils.prettyDate(ongoingTrip.start.toDate().toString())}
          </div>
        </div>
        <div className="trips-container-content">
          <Icon name="chart bar" onClick={displayChart(ongoingTrip)} />
        </div>
        <div className="trips-container-content">
          <Icon
            name={playback === ongoingTrip.id ? "pause" : "play"}
            onClick={handlePlayback(ongoingTrip)}
          />
        </div>
      </div>
    )
  );
};
