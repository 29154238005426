import moment from "moment";

export const prettyDate = (date) => {
  return date ? moment(date).format("DD/MM/YY HH:mm") : "-";
};

export const prettyStartTime = (data) => {
  const stringDate = data?.start?.toDate().toString();
  return prettyDate(stringDate);
};

export const prettyEndTime = (data) => {
  const stringDate = data?.end?.toDate().toString();
  return prettyDate(stringDate);
};

export const prettyDuration = (data) => {
  const start = data?.start?.toDate().toString(),
    end = data?.end?.toDate().toString();
  const duration = moment.duration(moment(end).diff(moment(start)));

  return (
    duration.get("days") * 24 +
    duration.get("hours") +
    " h " +
    duration.get("minutes") +
    " m"
  );
};

export const prettyDateSeconds = (date) => {
  return moment(date).format("DD/MM/YY, HH:mm:ss");
};

export const getDateMinute = (date) => {
  return moment(date).minute();
};

export const isDateLastTenMinutesUTC = (date) => {
  let start = moment.utc().subtract(10, "minutes");
  let end = moment.utc();

  return moment(date).isBetween(start, end);
};

export const isDateLastThirtyMinutesUTC = (date) => {
  let start = moment.utc().subtract(30, "minutes");
  let end = moment.utc();

  return moment(date).isBetween(start, end);
};

export const isDateLastHourUTC = (date) => {
  let start = moment.utc().subtract(1, "hour");
  let end = moment.utc();

  return moment(date).isBetween(start, end);
};

export const isDateLastThreeDaysUTC = (date) => {
  let start = moment.utc().subtract(3, "days");
  let end = moment.utc();

  return moment(date).isBetween(start, end);
};

export const isDateTodayUTC = (date) => {
  let now = new Date();

  return (
    now.getUTCFullYear() === date?.getUTCFullYear() &&
    now.getUTCMonth() === date?.getUTCMonth() &&
    now.getUTCDate() === date?.getUTCDate()
  );
};
