import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { createIssue, editIssue } from "data/issues";
import { useEffect } from "react";

const validationSchema = yup.object().shape({
  status: yup.string().required("Status is required"),
  severety: yup.string().required("Severety level is required"),
  title: yup.string().required("Title is required"),
  text: yup.string().required("Content is required"),
});

export const useIssueFormHandler = (
  id,
  data,
  vessel,
  captain,
  onDone = null,
) => {
  const {
    handleSubmit,
    getValues,
    register,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset({
      status: data?.status,
      severety: data?.severety,
      title: data?.title,
      text: data?.text,
    });
  }, [data]);

  const onSubmit = handleSubmit(async (values) => {
    if (data) {
      await editIssue({
        ...values,
        id: data.id,
        vessel_id: vessel?.id ?? "",
        captain_id: captain?.id ?? "",
      });
    } else {
      await createIssue(id, {
        ...values,
        vessel_id: vessel?.id ?? "",
        captain_id: captain?.id ?? "",
        date: Date.now(),
      });
      reset({
        title: null,
        text: null,
        date: null,
      });
    }
    onDone && onDone();
  });

  return { getValues, register, reset, errors, onSubmit };
};
