import React, { useEffect, useState, useContext } from "react";

import { getUsers, getAllUsers } from "data/users";
import { useCaptainsEditFormHandler } from "../hooks/useCaptaisEditFormHandler";

import { Modal } from "components/Modal";
import { Input } from "components/Inputs";
import { Select } from "components/Dropdown";

import { AuthContext } from "utils/AuthContext";
import { useGroups } from "hooks/useGroups";
import { mapUsersToOprions, mapGroupsToOptions } from "utils/DataAdapters";
import { useCaptain } from "hooks/useCaptains";

export function CaptainEditDialog({ id, show, onHide }) {
  const [users, setUsers] = useState([]);
  const { user, company } = useContext(AuthContext);
  const groups = useGroups();
  const captain = useCaptain(id);
  const { register, handleSubmit, errors, onSubmit, reset } =
    useCaptainsEditFormHandler(id, company, onHide);

  useEffect(async () => {
    const users = await getUsers(company.id);
    setUsers(mapUsersToOprions(users));

    if (user.auth_role === "super") {
      const users = await getAllUsers();
      setUsers(mapUsersToOprions(users));
    } else {
      let users = await getUsers(company.id);
      setUsers(mapUsersToOprions(users));
      reset({ company_id: company.id });
    }
  }, []);

  useEffect(async () => {
    if (id) {
      const group = groups?.find((group) => group.id === captain.group)?.id;
      reset({ name: captain?.name, user_id: captain?.user_id, group: group });
    }
  }, [id, captain, groups]);

  const handleClose = () => {
    reset({ name: null, user_id: null });
    onHide();
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <h3>{id ? "Edit Captain" : "Add Captain"}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Name"
          id="name"
          name="name"
          error={errors?.name}
          {...register("name")}
        />
        <Select
          label="Choose group"
          id="group"
          name="group"
          prev={
            groups?.find((group) => group.id === captain.group)?.name ?? "-"
          }
          options={mapGroupsToOptions(groups) || []}
          error={errors?.group}
          {...register("group")}
        />
        <Select
          label="Link to user"
          id="user"
          name="user_id"
          prev={
            users.find((user) => user.value === captain?.user_id)?.text ?? "-"
          }
          options={users || []}
          error={errors?.user_id}
          {...register("user_id")}
        />
        <input type="submit" value={id ? "Edit" : "Create"} />
      </form>
    </Modal>
  );
}
